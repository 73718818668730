import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  GetProfileAction,
  GetProfileSuccessAction,
  LoginAction, LoginAzureAction,
  ProfileActions,
  SaveProfilesAvatarAction, UpdateLoadingStatusAction
} from '../actions/profile.actions';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {AuthService} from '../../services/auth/auth.service';
import {Router} from '@angular/router';
import {NotifierService} from 'angular-notifier';

const url = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent) ? `${window.location.origin}/dashboard/prospects/agent-dashboard-visitors` : `${window.location.origin}/dashboard`

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private auth: AuthService,
    private router: Router,
    private notifierService: NotifierService
  ) {
  }

  @Effect() logIn$ = this.actions$.pipe(
    ofType(ProfileActions.LoginAction),
    switchMap((action: LoginAction) => this.auth.login(action.payload)),
    map((value: {token?: string, refreshToken?: string, message?: string}) => {
      if (value.token) {
        localStorage.setItem('access_token', value.token);
        localStorage.setItem('refresh_token', value.refreshToken);
      }
    }),
    tap(() => window.location.replace(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent) ? `${window.location.origin}/dashboard/prospects/agent-dashboard-visitors` : `${window.location.origin}/dashboard`)),
    map(() => new GetProfileAction()),
    catchError((err, caught) => caught)
  );

  @Effect() logInAzure$ = this.actions$.pipe(
    ofType(ProfileActions.LoginAzureAction),
    switchMap(() => this.auth.loginAzure()),
    catchError((err, caught) => caught)
  );

  @Effect() getProfile = this.actions$.pipe(
    ofType(ProfileActions.GetProfileAction),
    switchMap(() => this.auth.getProfile()),
    map(profile => new GetProfileSuccessAction(profile)),
    catchError((err, caught) => caught)
  );

  @Effect() updateProfile = this.actions$.pipe(
    ofType(ProfileActions.UpdateProfileAction),
    switchMap(({payload}) => this.auth.saveProfile(payload)),
    tap((response: any) => this.notifierService.notify('success', response.message)),
    map(() => new GetProfileAction()),
    catchError((err, caught) => caught)
  );

  @Effect() saveProfilesAvatar = this.actions$.pipe(
    ofType(ProfileActions.SaveProfilesAvatar),
    switchMap((action: SaveProfilesAvatarAction) => this.auth.saveAvatar(action.payload)),
    tap((response: any) => this.notifierService.notify('success', response.message)),
    map(() => new GetProfileAction()),
    catchError((err, caught) => caught)
  );

}
