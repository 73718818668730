import {Action} from '@ngrx/store';
import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {
  AuditLogDeltaInterface,
  AuditLogRequestResponseInterface
} from '../../interfaces/audit-log.interface';

export enum AuditLogActions {
  GetAuditLogs = '[Audit Log] Get Audit Log',
  GetAuditLogsSuccess = '[Audit Log] Get Audit Log Success',
  UpdateAuditLogsSearchQuery = '[Audit Log] Update Audit Logs Search Query',

  GetActionTypes = '[Audit Log] Get Action Types',
  GetActionTypesSuccess = '[Audit Log] Get Action Types Success',
  GetEntity = '[Audit Log] Get Entity',
  GetEntitySuccess = '[Audit Log] Get Entity Success',
  GetUserTypes = '[Audit Log] Get User Types',
  GetUserTypesSuccess = '[Audit Log] Get User Types Success',
  GetLogDelta = '[Audit Log] Get Log Delta',

  DeleteDataFromStore = '[Audit Log] Delete Data From Store',
  DeleteLogDeltaFromStore = '[Audit Log] Delete Log Delta From Store'
}

export class GetAuditLogsAction implements Action {
  readonly type = AuditLogActions.GetAuditLogs;

  constructor(public payload: QueryParamsInterface) {}
}
export class GetAuditLogsSuccessAction implements Action {
  readonly type = AuditLogActions.GetAuditLogsSuccess;

  constructor(public payload: any) {}
}

export class UpdateAuditLogsSearchQueryAction implements Action {
  readonly type = AuditLogActions.UpdateAuditLogsSearchQuery;

  constructor(public payload: QueryParamsInterface) {}
}
export class GetActionTypesAction implements Action {
  readonly type = AuditLogActions.GetActionTypes;

  constructor() {}
}
export class GetActionTypesSuccessAction implements Action {
  readonly type = AuditLogActions.GetActionTypesSuccess;

  constructor(public payload: any) {}
}
export class GetEntityAction implements Action {
  readonly type = AuditLogActions.GetEntity;

  constructor() {}
}
export class GetEntitySuccessAction implements Action {
  readonly type = AuditLogActions.GetEntitySuccess;

  constructor(public payload: any) {}
}
export class GetUserTypesAction implements Action {
  readonly type = AuditLogActions.GetUserTypes;

  constructor() {}
}
export class GetUserTypesSuccessAction implements Action {
  readonly type = AuditLogActions.GetUserTypesSuccess;

  constructor(public payload: any) {}
}

export class GetLogDeltaAction implements Action {
  readonly type = AuditLogActions.GetLogDelta;

  constructor(public payload: AuditLogDeltaInterface) {}
}

export class DeleteDataFromStoreAction implements Action {
  readonly type = AuditLogActions.DeleteDataFromStore;

  constructor() {}
}
export class DeleteLogDeltaFromStoreAction implements Action {
  readonly type = AuditLogActions.DeleteLogDeltaFromStore;

  constructor() {}
}

export type AuditLogActionsActionsUnion =
  | GetAuditLogsAction
  | GetAuditLogsSuccessAction
  | UpdateAuditLogsSearchQueryAction
  | GetActionTypesAction
  | GetActionTypesSuccessAction
  | GetEntityAction
  | GetEntitySuccessAction
  | GetUserTypesAction
  | GetUserTypesSuccessAction
  | GetLogDeltaAction
  | DeleteDataFromStoreAction
  | DeleteLogDeltaFromStoreAction;
