<div class="confirmation-modal">
  <div class="content-wrapper" style="overflow: auto">
    <div class="automation-log-container">
      <div class="steps-container">
        <div>
          <span class="steps-element-title">"Action":</span>
          <span class="status-text success"> {{ delta?.action }}</span>
        </div>
        <div>
          <span class="steps-element-title">"Changes":</span>
          <ng-container
            *ngIf="
              delta.delta &&
              ['Updated', 'Update', 'Note-updated', 'Note-update', 'Created'].includes(
                delta.action
              )
            "
          >
            <ng-container
              *ngTemplateOutlet="
                displayNestedObject;
                context: {object: delta.delta, indent: 0}
              "
            ></ng-container>
          </ng-container>
          <ng-template
            #displayNestedObject
            let-object="object"
            let-indent="indent"
          >
            <div
              [ngStyle]="{'margin-left.px': 10 * (indent + 1)}"
              *ngFor="let key of getKeys(object)"
            >
              <span>"{{ key }}":</span>
              <ng-container *ngIf="isObject(object[key]); else primitive">
                <ng-container
                  *ngTemplateOutlet="
                    displayNestedObject;
                    context: {object: object[key], indent: indent + 1}
                  "
                ></ng-container>
              </ng-container>
              <ng-template #primitive>
                {{ object[key] }}
              </ng-template>
              <br />
            </div>
          </ng-template>
          <div
            *ngIf="
              !delta.delta ||
              !['Updated', 'Update', 'Note-updated', 'Note-update', 'Created'].includes(
                delta.action
              )
            "
          >
            <span style="margin-left: 10px">{{ delta.message }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-wrap">
      <button type="button" (click)="cancel()" class="btn btn-sm btn-green">
        <span>Confirm</span>
      </button>
    </div>
  </div>
</div>
