import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-ldap',
  templateUrl: './ldap.component.html',
  styleUrls: ['./ldap.component.sass']
})
export class LdapComponent implements OnInit {

  public mode: ProgressSpinnerMode = 'indeterminate';
  public spinner = true;

  constructor(
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private service: AuthService
  ) {
    /*router.events.filter(event => event instanceof NavigationEnd)
      .subscribe(event =>
      {
        this.currentRoute = event.url;
        console.log(event);
      });*/
  }

  async ngOnInit() {
    //this.router.navigate(['/dashboard']);
    //await this.LdapRedirect();
    const url = this.router.url.slice(1);
    if (window.location.search) {
      this.service.redirectUrl(url).subscribe(async (res: { token?: string, refreshToken?: string, message?: string }) => {
        if (res.token) {
          localStorage.setItem('access_token', res.token);
          localStorage.setItem('refresh_token', res.refreshToken);
          await this.router.navigate(['/dashboard']);
        } else {
          //await this.router.navigate([`/auth/signin?error=${res.message}`]);
          await this.router.navigate(
            ['/auth/signin'],
            {relativeTo: this.activatedRouter, queryParams: {error: res.message}}
          );
        }
      });
    }
  }

  async LdapRedirect() {
    const url = this.router.url.slice(1);
    await this.service.redirectUrl(url).subscribe(res => {
    });
    await this.router.navigate(['/dashboard']);
  }

}
