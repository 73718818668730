import {Action} from '@ngrx/store';
import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {
  AutomationsLogsRequestResponseInterface,
  AutomationStepsInterface
} from '../../interfaces/automations-logs.interface';

export enum SettingsAutomationsLogsActions {
  GetAutomationsLogs = '[Automations Logs] Get Automations Logs',
  GetAutomationsLogsSuccess = '[Automations Logs] Get Automations Logs Success',
  UpdateAutomationsLogsSearchQuery = '[Automations Logs] Update Automations Logs Search Query',
  DeleteAutomationsLogsFromStore = '[Automations Logs] Delete Automations Logs From Store',

  ResolveAutomationsLog = '[Automations Logs] Resolve Automations Log',

  GetAutomationSteps = '[Automations Logs] Get Automations Steps',
  DeleteAutomationStepsFromStore = '[Automations Logs] Delete Automations Steps From Store'
}

export class GetAutomationsLogsAction implements Action {
  readonly type = SettingsAutomationsLogsActions.GetAutomationsLogs;

  constructor(public payload: QueryParamsInterface) {}
}

export class GetAutomationsLogsSuccessAction implements Action {
  readonly type = SettingsAutomationsLogsActions.GetAutomationsLogsSuccess;

  constructor(public payload: AutomationsLogsRequestResponseInterface) {}
}

export class UpdateAutomationsLogsSearchQueryAction implements Action {
  readonly type =
    SettingsAutomationsLogsActions.UpdateAutomationsLogsSearchQuery;

  constructor(public payload: QueryParamsInterface) {}
}

export class DeleteAutomationsLogsFromStoreAction implements Action {
  readonly type = SettingsAutomationsLogsActions.DeleteAutomationsLogsFromStore;

  constructor() {}
}

export class ResolveAutomationsLogAction implements Action {
  readonly type = SettingsAutomationsLogsActions.ResolveAutomationsLog;

  constructor(public payload: number) {}
}

export class GetAutomationStepsAction implements Action {
  readonly type = SettingsAutomationsLogsActions.GetAutomationSteps;

  constructor(public payload: AutomationStepsInterface[]) {}
}

export class DeleteAutomationStepsFromStoreAction implements Action {
  readonly type = SettingsAutomationsLogsActions.DeleteAutomationStepsFromStore;

  constructor() {}
}

export type SettingsAutomationsLogsActionsUnion =
  | GetAutomationsLogsAction
  | GetAutomationsLogsSuccessAction
  | GetAutomationStepsAction
  | UpdateAutomationsLogsSearchQueryAction
  | DeleteAutomationsLogsFromStoreAction
  | DeleteAutomationStepsFromStoreAction;
