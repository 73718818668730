import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from '../services/auth/auth.guard';
import { NewPassComponent } from './forgot-password/new-pass/new-pass.component';
import { SignFirstComponent } from './sign-first/sign-first.component';
import { AppleAppSiteAssociationComponent } from './apple-app-site-association/apple-app-site-association.component';
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { LdapComponent } from './ldap/ldap.component';
const ɵ0 = () => import("./../dashboard/dashboard.module.ngfactory").then(m => m.DashboardModuleNgFactory);
const routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: 'public/callback/ldap-redirect',
        component: LdapComponent
    },
    {
        path: 'dashboard',
        loadChildren: ɵ0,
        canActivate: [AuthGuard]
    },
    {
        path: 'password-recovery',
        component: NewPassComponent
    },
    {
        path: 'reset-password',
        component: SignFirstComponent
    },
    {
        path: 'auth/ldap-signup',
        component: LoginComponent
    },
    {
        path: 'auth/signin',
        component: LoginComponent
    },
    {
        path: 'auth/forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'apple-app-site-association',
        component: AppleAppSiteAssociationComponent
    },
    {
        path: '**',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    }
];
export class LoginRoutingModule {
}
export { ɵ0 };
