import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modal/components/modal-wrapper/modal-wrapper.component.ngfactory";
import * as i3 from "./modal/components/modal-wrapper/modal-wrapper.component";
import * as i4 from "./modal/services/modal.service";
import * as i5 from "@angular/router";
import * as i6 from "../../node_modules/angular-notifier/angular-notifier.ngfactory";
import * as i7 from "angular-notifier";
import * as i8 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-wrapper", [], null, null, null, i2.View_ModalWrapperComponent_0, i2.RenderType_ModalWrapperComponent)), i1.ɵdid(1, 114688, null, 0, i3.ModalWrapperComponent, [i4.ModalService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "main-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "notifier-container", [["class", "notifier__container"]], null, null, null, i6.View_NotifierContainerComponent_0, i6.RenderType_NotifierContainerComponent)), i1.ɵdid(6, 180224, null, 0, i7.NotifierContainerComponent, [i1.ChangeDetectorRef, i7.ɵa, i7.NotifierService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i8.AppComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i8.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
