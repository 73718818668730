<div class="confirmation-modal">
  <div class="content-wrapper">
    <p>{{options?.message || defaultMessage}}</p>
    <div class="btn-wrap">
      <button type="button" (click)="cancel()" class="btn btn-sm btn-grey">
        <span>Cancel</span>
      </button>
      <button type="button" (click)="confirm()" class="btn btn-sm btn-green">
        <span>Confirm</span>
      </button>
    </div>
  </div>
</div>
