import {Action} from '@ngrx/store';
import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {
  TenantsItemAvailableDoorsRequestResponseInterface,
  TenantsItemAvailableUalsRequestResponseInterface
} from "../../../residents/interfaces/tenants.interface";

export enum ZonesActions {
  GetZones = '[Zones] Get Zones',
  GetZonesSuccess = '[Zones] Get Zones Success',
  UpdateZonesSearchQueryRaw = '[Zones] Update Search Query Raw',
  GetZone = '[Zone Item] Get Zone',
  GetZoneSuccess = '[Zone Item] Get Zone Success',
  SaveZone = '[Zone Item] Save Zone',
  DeleteZoneFromStore = '[Zone Item] Delete Zone From Store',
  CreateZone = '[Zone Item] Create Zone',
  DeleteZone = '[Zone Item] Delete Zone',

  GetZoneAvailableDoors = '[Zone Item] Get Zone Available Doors',
  GetZoneAvailableDoorsSuccess = '[Zone Item] Get Zone Available Doors Success',
  UpdateZoneAvailableDoorsSearchQuery = '[Zone Item] Update Zone Available Doors Search Query',
  DeleteFromStoreAvailableDoors = '[Zone Item] Delete From Store Available Doors',

  GetZoneAvailableDoorsAll = '[Zone Item] Get Zone Available Doors All',
  GetZoneAvailableDoorsAllSuccess = '[Zone Item] Get Zone Available Doors All Success',
  UpdateZoneAvailableDoorsAllSearchQuery = '[Zone Item] Update Zone Available Doors All Search Query',
  DeleteFromStoreAvailableDoorsAll = '[Zone Item] Delete From Store Available Doors All',

  GetZoneAvailableUals = '[Zone Item] Get Zone Available Uals',
  GetZoneAvailableUalsSuccess = '[Zone Item] Get Zone Available Uals Success',
  UpdateZoneAvailableUalsSearchQuery = '[Zone Item] Update Zone Available Uals Search Query',
  DeleteFromStoreAvailableUals = '[Zone Item] Delete From Store Available Uals',

  GetZoneAvailableUalsAll = '[Zone Item] Get Zone Available Uals All',
  GetZoneAvailableUalsAllSuccess = '[Zone Item] Get Zone Available Uals All Success',
  UpdateZoneAvailableUalsAllSearchQuery = '[Zone Item] Update Zone Available Uals All Search Query',
  DeleteFromStoreAvailableUalsAll = '[Zone Item] Delete From Store Available Uals All',
}

export class GetZonesAction implements Action {
  type = ZonesActions.GetZones;

  constructor(public payload: QueryParamsInterface) {
  }
}

export class GetZonesSuccessAction implements Action {
  type = ZonesActions.GetZonesSuccess;

  constructor(public payload: any) {
  }
}

export class UpdateZonesSearchQueryRawAction implements Action {
  type = ZonesActions.UpdateZonesSearchQueryRaw;

  constructor(public payload: QueryParamsInterface) {
  }
}

export class GetZoneAction implements Action {
  type = ZonesActions.GetZone;

  constructor(public payload: number) {
  }
}

export class SaveZoneAction implements Action {
  type = ZonesActions.SaveZone;

  constructor(public payload) {
  }
}

export class GetZoneSuccessAction implements Action {
  type = ZonesActions.GetZoneSuccess;

  constructor(public payload) {
  }
}

export class DeleteZoneFromStoreAction implements Action {
  type = ZonesActions.DeleteZoneFromStore;

  constructor(public payload?) {
  }
}

export class CreateZoneAction implements Action {
  type = ZonesActions.CreateZone;

  constructor(public payload) {
  }
}

export class DeleteZoneAction implements Action {
  type = ZonesActions.DeleteZone;

  constructor(public payload: number) {
  }
}

export class GetZoneAvailableDoorsAction implements Action {
  readonly type: string = ZonesActions.GetZoneAvailableDoors;

  constructor(public payload: any) {
  }
}

export class DeleteFromStoreZoneAvailableDoors implements Action {
  readonly type: string = ZonesActions.DeleteFromStoreAvailableDoors;

  constructor(public payload?) {
  }
}

export class GetZoneAvailableDoorsSuccessAction implements Action {
  readonly type: string = ZonesActions.GetZoneAvailableDoorsSuccess;

  constructor(public payload: TenantsItemAvailableDoorsRequestResponseInterface) {
  }
}

export class UpdateZoneAvailableDoorsSearchQueryAction implements Action {
  readonly type: string = ZonesActions.UpdateZoneAvailableDoorsSearchQuery;

  constructor(public payload: QueryParamsInterface) {
  }
}

export class GetZoneAvailableDoorsAllAction implements Action {
  readonly type: string = ZonesActions.GetZoneAvailableDoorsAll;

  constructor(public payload?: any) {
  }
}

export class DeleteFromStoreZoneAvailableDoorsAll implements Action {
  readonly type: string = ZonesActions.DeleteFromStoreAvailableDoorsAll;

  constructor(public payload?) {
  }
}

export class GetZoneAvailableDoorsAllSuccessAction implements Action {
  readonly type: string = ZonesActions.GetZoneAvailableDoorsAllSuccess;

  constructor(public payload: TenantsItemAvailableDoorsRequestResponseInterface) {
  }
}

export class UpdateZoneAvailableDoorsAllSearchQueryAction implements Action {
  readonly type: string = ZonesActions.UpdateZoneAvailableDoorsAllSearchQuery;

  constructor(public payload: QueryParamsInterface) {
  }
}

export class GetZoneAvailableUalsAction implements Action {
  readonly type: string = ZonesActions.GetZoneAvailableUals;

  constructor(public payload: any) {
  }
}

export class DeleteFromStoreZoneAvailableUals implements Action {
  readonly type: string = ZonesActions.DeleteFromStoreAvailableUals;

  constructor(public payload?) {
  }
}

export class GetZoneAvailableUalsSuccessAction implements Action {
  readonly type: string = ZonesActions.GetZoneAvailableUalsSuccess;

  constructor(public payload: TenantsItemAvailableUalsRequestResponseInterface) {
  }
}

export class UpdateZoneAvailableUalsSearchQueryAction implements Action {
  readonly type: string = ZonesActions.UpdateZoneAvailableUalsSearchQuery;

  constructor(public payload: QueryParamsInterface) {
  }
}

export class GetZoneAvailableUalsAllAction implements Action {
  readonly type: string = ZonesActions.GetZoneAvailableUalsAll;

  constructor(public payload?: any) {
  }
}

export class DeleteFromStoreZoneAvailableUalsAll implements Action {
  readonly type: string = ZonesActions.DeleteFromStoreAvailableUalsAll;

  constructor(public payload?) {
  }
}

export class GetZoneAvailableUalsAllSuccessAction implements Action {
  readonly type: string = ZonesActions.GetZoneAvailableUalsAllSuccess;

  constructor(public payload: TenantsItemAvailableUalsRequestResponseInterface) {
  }
}

export class UpdateZoneAvailableUalsAllSearchQueryAction implements Action {
  readonly type: string = ZonesActions.UpdateZoneAvailableUalsAllSearchQuery;

  constructor(public payload: QueryParamsInterface) {
  }
}

export type ZonesActionsUnion =
  GetZonesAction
  | GetZonesSuccessAction
  | UpdateZonesSearchQueryRawAction
  | GetZoneAction
  | GetZoneSuccessAction
  | SaveZoneAction
  | DeleteZoneFromStoreAction
  | CreateZoneAction
  | DeleteZoneAction
  | GetZoneAvailableDoorsAction
  | GetZoneAvailableDoorsAllAction
  | GetZoneAvailableDoorsAllSuccessAction
  | GetZoneAvailableDoorsSuccessAction
  | UpdateZoneAvailableDoorsAllSearchQueryAction
  | UpdateZoneAvailableDoorsSearchQueryAction
  | DeleteFromStoreZoneAvailableDoors
  | DeleteFromStoreZoneAvailableDoorsAll
  | GetZoneAvailableUalsAction
  | GetZoneAvailableUalsAllAction
  | GetZoneAvailableUalsAllSuccessAction
  | GetZoneAvailableUalsSuccessAction
  | UpdateZoneAvailableUalsAllSearchQueryAction
  | UpdateZoneAvailableUalsSearchQueryAction
  | DeleteFromStoreZoneAvailableUals
  | DeleteFromStoreZoneAvailableUalsAll
  ;

