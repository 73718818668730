import {ProfileActions, ProfileActionsUnion} from '../actions/profile.actions';

export interface ProfileState {
  profile: any;
  messagesBox: any;
  countMess: any;
  loading: boolean;
}

const InitialProfileState: ProfileState = {
  profile: null,
  loading: false,
  messagesBox: [],
  countMess: 0
};

export function profileReducer(state = InitialProfileState, action: ProfileActionsUnion) {
  switch (action.type) {
    case ProfileActions.UpdateMessagesActions: {
      return {
        ...state,
        messagesBox: action.payload,
        countMess: action.payload.length
      }
    }
    case ProfileActions.UpdateLoadingStatus: {
      return {
        ...state,
        loading: action.payload
      }
    }
    case ProfileActions.GetProfileSuccessAction: {
      return {
        ...state,
        profile: action.payload
      };
      break;
    }
    default:
      return state;
  }
}
