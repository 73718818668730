import {Action} from '@ngrx/store';
import {SaveTimeTableInterface, TimetableInterface, TimetableRequestResponseInterface} from '../../interfaces/timetable.interface';
import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';

export enum TimeTablesActions {
  GetTimatables = '[Cardholder Timetables] Get Timetables',
  UpdateTimetablesSearchQuery = '[Cardholder Timetables] Update Timetables Search Query',
  GetTimatablesSuccess = '[Cardholder Timetables] Get Timetables Success',
  GetTimatable = '[Cardholder Timetables] Get Timetable',
  GetTimatableSuccess = '[Cardholder Timetables] Get Timetable Success',
  SaveTimatable = '[Cardholder Timetables] Save Timetable'
}

export class GetTimatablesAction implements Action {
  public readonly type: string = TimeTablesActions.GetTimatables;
  // Todo: Why code here is making an error but works?
  // constructor(public payload?: QueryParamsInterface) {
  constructor(public payload?) {
  }
}

export class GetTimatablesSuccessAction implements Action {
  public readonly type: string = TimeTablesActions.GetTimatablesSuccess;

  constructor(public payload: TimetableRequestResponseInterface) {
  }
}

export class GetTimatableAction implements Action {
  public readonly type: string = TimeTablesActions.GetTimatable;

  constructor(public payload: number) {
  }
}

export class UpdateTimetablesSearchQueryAction implements Action {
  readonly type = TimeTablesActions.UpdateTimetablesSearchQuery;

  constructor(public payload: QueryParamsInterface) {
  }
}

export class GetTimatableSuccessAction implements Action {
  public readonly type: string = TimeTablesActions.GetTimatableSuccess;

  constructor(public payload: TimetableInterface) {
  }
}

export class SaveTimatableAction implements Action {
  public readonly type: string = TimeTablesActions.SaveTimatable;

  constructor(public payload: SaveTimeTableInterface) {
  }
}

export type TimeTablesActionsUnion =
  GetTimatablesAction
  | GetTimatablesSuccessAction
  | GetTimatableAction
  | GetTimatableSuccessAction
  | UpdateTimetablesSearchQueryAction
  | SaveTimatableAction;
