import { Injectable } from '@angular/core';
import{ApiService} from '../../../services/api.service';
import{QueryParamsInterface} from '../../../interfaces/query-params.interface';

const url = 'prospects/agentdashboard';
const apartments = 'apartments';
const visitors = 'visitors';
const body = {
  "channelSid":"CH9152e06d4bac4c92a102e7a2f6ebe707",
  "from":"prospective_tenant/1/prospect.four@example.com",
  "body":"Hi !!!!!!!!!"
};
const body2 = {
  "channelSid":"CHecfba374752745059a7d10c8e3142b6d",
  "from":"prospective_tenant/1/prospect.four@example.com",
  "body":"Hello !!!!!!!!!"
};

@Injectable({
  providedIn: 'root'
})
export class ProspectsAgentDashboardService {

  constructor(
    private api: ApiService
  ) {
  }
  public totalVisitors;

  public getApartments = (query: QueryParamsInterface = { page: 1, limit: 10}) => this.api.call('GET', `${url}/${apartments}`,null, query);
  public getVisitors = ({id, query}: any) =>  this.api.call('GET', `${url}/${visitors}/${id}`, null, query);
  public getApartment = (id: number) => this.api.call('GET', `${url}/${visitors}/${id}`);

  public getToken = () => this.api.call('POST', 'chat/token', {'deviceId': 'desktop', 'deviceType': 'Web'});
  public createChat = (userId, userIdentity, apartmentId) => this.api.call('POST', 'chat/create', {'userId' : userId, 'userIdentity': userIdentity, 'apartmentId': apartmentId});


  public getPushToken = (token) => this.api.call('POST', `push/register`, {'token': token})

  public deletePushToken = (token) => this.api.call('DELETE', `push/unregister`, {'token': token})

  public getStatistic = () => this.api.call('GET', `${url}/${apartments}/statistic`);

  public getCheckinChats = () => this.api.call('GET', `${url}/self-tour/`);
  public getActiveChats = () => this.api.call('GET', `${url}/self-tour/active`);

  public sendMeMess = () => this.api.call('POST', 'chat/message', body);
  public sendMeMess2 =() => this.api.call('POST', 'chat/message', body2);

  public disableChat = (sid, mute, ptKeyId) => this.api.call('POST', 'chat/update', {'conversationSid': sid, 'muted': mute, ptKeyId});
}
