import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AccessAutomationsService } from '../../services/access-automations.service';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { AccessAutomationsActions, AddNewOperandValueAction, ConnectAccessAutomationOperandValueAction, ConnectAccessAutomationOperandValueSuccessAction, CreateAccessAutomationAction, CreateExpressionAction, CreateExpressionSuccessAction, DeleteAccessAutomationAction, DeleteAccessAutomationFromTableAction, DeleteExpressionAction, DeleteExpressionSuccessAction, DisableAccessAutomationAction, DisconnectAccessAutomationOperandValueAction, DisconnectAccessAutomationOperandValueSuccessAction, EnableAccessAutomationAction, EnableAccessAutomationSuccessAction, EnableDisableAccessAutomationAction, GetAccessAutomationAction, GetAccessAutomationActionAction, GetAccessAutomationActionSuccessAction, GetAccessAutomationAllActionsAction, GetAccessAutomationAllActionsSuccessAction, GetAccessAutomationAllOperandsAction, GetAccessAutomationAllOperandsSuccessAction, GetAccessAutomationBuildingCommonAreaAction, GetAccessAutomationBuildingCommonAreaSuccessAction, GetAccessAutomationLeasingUalsAction, GetAccessAutomationLeasingUalsSuccessAction, GetAccessAutomationsAction, GetAccessAutomationsSuccessAction, GetAccessAutomationSuccessAction, GetAccessAutomationVacancyUalsAction, GetAccessAutomationVacancyUalsSuccessAction, RemoveOperandValueAction, SaveAccessAutomationAction, SecondEnableAccessAutomationAction, UpdateAccessAutomationsSearchQueryAction, UpdateExpressionOperatorAction, UpdateExpressionOperatorSuccessAction } from '../actions/access-automations.actions';
import { UpdateLoadingStatusAction } from '../../../../Store/actions/profile.actions';
import { AccessAutomationsRequestResponseInterface, AccessAutomationActionInterface, ElementInterface, AccessAutomationOperandsInterface, AccessAutomationInterface, AccessAutomationExpressionInterface, BuildingCommonAreaInterface, UalsInterface } from '../../interfaces/access-automations.interface';
import { dashboardSystemAccessAutomationsQuerySelectUrl, dashboardSystemBuildingDataSelectUrl } from '../../constants/constants';
import * as moment from 'moment';
import { ModalService } from '../../../../modal/services/modal.service';
import { of } from 'rxjs';
export class AccessAutomationsEffects {
    constructor(actions$, store, service, router, notifierService, modalService) {
        this.actions$ = actions$;
        this.store = store;
        this.service = service;
        this.router = router;
        this.notifierService = notifierService;
        this.modalService = modalService;
        this.updateAccessAutomationsSearchQuery = this.actions$.pipe(ofType(AccessAutomationsActions.UpdateAccessAutomationsSearchQuery), map((action) => new GetAccessAutomationsAction(action.payload)), catchError((err, caught) => caught));
        this.getAccessAutomations = this.actions$.pipe(ofType(AccessAutomationsActions.GetAccessAutomations), switchMap((action) => {
            this.store.dispatch(new UpdateLoadingStatusAction(true));
            return this.service.getAccessAutomations(action.payload).pipe(map((response) => {
                response.data = response.data.map((automation) => {
                    return Object.assign({}, automation, { updatedAgo: moment(automation.updatedAt).fromNow() });
                });
                return response;
            }));
        }), map((response) => {
            this.store.dispatch(new UpdateLoadingStatusAction(false));
            return new GetAccessAutomationsSuccessAction(response);
        }), catchError((err, caught) => caught));
        this.getAccessAutomation = this.actions$.pipe(ofType(AccessAutomationsActions.GetAccessAutomation), switchMap((action) => this.service.getAccessAutomation(action.payload)), map((response) => new GetAccessAutomationSuccessAction(response)), catchError((err, caught) => {
            return caught;
        }));
        this.saveAccessAutomation = this.actions$.pipe(ofType(AccessAutomationsActions.SaveAccessAutomation), switchMap((action) => this.service.updateAccessAutomation(action.payload.data, action.payload.id)), tap((response) => {
            this.modalService.closeModal();
            return this.notifierService.notify('success', response.message || `Automation '${response.name}' is updated`);
        }), concatMap(() => [
            this.store.select(...dashboardSystemAccessAutomationsQuerySelectUrl)
        ]), switchMap((queryParams) => queryParams), map((query) => new GetAccessAutomationsAction(query)), catchError((err, caught) => caught));
        this.createAccessAutomation = this.actions$.pipe(ofType(AccessAutomationsActions.CreateAccessAutomation), switchMap((action) => this.service.createAccessAutomation(action.payload)), tap((response) => {
            this.modalService.closeModal();
            return this.notifierService.notify('success', response.message || `Automation '${response.name}' is created`);
        }), concatMap(() => [
            this.store.select(...dashboardSystemAccessAutomationsQuerySelectUrl)
        ]), switchMap((queryParams) => queryParams), map((query) => new GetAccessAutomationsAction(query)), catchError((err, caught) => caught));
        this.deleteAccessAutomation = this.actions$.pipe(ofType(AccessAutomationsActions.DeleteAccessAutomation), switchMap((action) => this.service.deleteAccessAutomation(action.payload)), tap(({ message }) => this.notifierService.notify('success', message)), switchMap((queryParams) => queryParams), map((query) => new GetAccessAutomationsAction(query)), catchError((err, caught) => caught));
        this.deleteAccessAutomationFromTable = this.actions$.pipe(ofType(AccessAutomationsActions.DeleteAccessAutomationFromTable), switchMap((action) => this.service.deleteAccessAutomation(action.payload)), tap(() => this.notifierService.notify('success', `Automation is deleted`)), concatMap(() => [
            this.store.select(...dashboardSystemAccessAutomationsQuerySelectUrl)
        ]), switchMap((queryParams) => queryParams), map((query) => new GetAccessAutomationsAction(query)), catchError((err, caught) => caught));
        this.enableDisableAccessAutomation = this.actions$.pipe(ofType(AccessAutomationsActions.EnableDisableAccessAutomation), switchMap((action) => this.service.enableAccessAutomation(action.payload.id, action.payload.status)), tap((response) => this.notifierService.notify('success', response.message || `Automation '${response.name}' is updated`)), concatMap(() => [
            this.store.select(...dashboardSystemAccessAutomationsQuerySelectUrl)
        ]), switchMap((queryParams) => queryParams), map((query) => new GetAccessAutomationsAction(query)), catchError((err, caught) => caught));
        this.enableAccessAutomation = this.actions$.pipe(ofType(AccessAutomationsActions.EnableAccessAutomation), switchMap((action) => {
            return of([
                this.service.enableDisableAccessAutomation(action.payload.id, action.payload.status)
            ]);
        }), tap((obs) => {
            let building;
            this.store
                .select(...dashboardSystemBuildingDataSelectUrl)
                .subscribe((item) => {
                if (item) {
                    building = item;
                }
            });
            return obs[0].subscribe(() => {
                return this.store.dispatch(new SecondEnableAccessAutomationAction({
                    companyId: building.company || null,
                    rules: [
                        {
                            subject: 'building',
                            condition: 'IN',
                            values: [
                                {
                                    value: building && building.buildingId
                                        ? building.buildingId.toString()
                                        : null
                                }
                            ],
                            fields: [
                                {
                                    field: 'buildingId'
                                }
                            ]
                        }
                    ]
                }));
            });
        }), map(() => new EnableAccessAutomationSuccessAction()), catchError((err, caught) => caught));
        this.secondEnableAccessAutomation = this.actions$.pipe(ofType(AccessAutomationsActions.SecondEnableAccessAutomation), switchMap((action) => {
            return this.service.secondEnableAutomation(action.payload);
        }), tap((response) => this.notifierService.notify('success', response.message || `Automation has been updated`)), concatMap(() => [
            this.store.select(...dashboardSystemAccessAutomationsQuerySelectUrl)
        ]), switchMap((queryParams) => queryParams), map((query) => new GetAccessAutomationsAction(query)), catchError((err, caught) => caught));
        this.disableAccessAutomation = this.actions$.pipe(ofType(AccessAutomationsActions.DisableAccessAutomation), switchMap((action) => this.service.enableDisableAccessAutomation(action.payload.id, action.payload.status)), tap((response) => this.notifierService.notify('success', response.message || `Automation '${response.name}' is updated`)), concatMap(() => [
            this.store.select(...dashboardSystemAccessAutomationsQuerySelectUrl)
        ]), switchMap((queryParams) => queryParams), map((query) => new GetAccessAutomationsAction(query)), catchError((err, caught) => caught));
        this.getAccessAutomationAllActions = this.actions$.pipe(ofType(AccessAutomationsActions.GetAccessAutomationAllActions), switchMap((action) => this.service.getAllActions()), map((response) => new GetAccessAutomationAllActionsSuccessAction(response)), catchError((err, caught) => caught));
        this.getAccessAutomationAllOperands = this.actions$.pipe(ofType(AccessAutomationsActions.GetAccessAutomationAllOperands), switchMap((action) => this.service.getAllOperands(action.payload)), map((response) => new GetAccessAutomationAllOperandsSuccessAction(response)), catchError((err, caught) => caught));
        this.getAccessAutomationAction = this.actions$.pipe(ofType(AccessAutomationsActions.GetAccessAutomationAction), switchMap((action) => this.service.getAction(action.payload)), map((response) => new GetAccessAutomationActionSuccessAction(response)), catchError((err, caught) => caught));
        this.connectAccessAutomationOperandValue = this.actions$.pipe(ofType(AccessAutomationsActions.ConnectAccessAutomationOperandValue), concatMap((action) => {
            return of([
                action.payload.data.id === 0
                    ? null
                    : this.service.expressionsValueConnect(action.payload.expressionId, action.payload.valueId),
                action.payload.expressionId,
                action.payload.data
            ]);
        }), tap((obs) => {
            if (obs[2].id === 0) {
                return this.store.dispatch(new AddNewOperandValueAction({
                    operandValue: obs[2],
                    expressionId: obs[1]
                }));
            }
            else {
                return obs[0].subscribe(() => {
                    return this.store.dispatch(new AddNewOperandValueAction({
                        operandValue: obs[2],
                        expressionId: obs[1]
                    }));
                });
            }
        }), map(() => {
            return new ConnectAccessAutomationOperandValueSuccessAction();
        }), catchError((err, caught) => {
            return caught;
        }));
        this.disconnectAccessAutomationOperandValue = this.actions$.pipe(ofType(AccessAutomationsActions.DisconnectAccessAutomationOperandValue), concatMap((action) => {
            return of([
                this.service.expressionsValueDisconnect(action.payload.expressionId, action.payload.valueId),
                action.payload.expressionId,
                action.payload.operandId,
                action.payload.data
            ]);
        }), tap((obs) => {
            return obs[0].subscribe(() => {
                return this.store.dispatch(new RemoveOperandValueAction({
                    operandValue: obs[3],
                    expressionId: obs[1],
                    operandId: obs[2]
                }));
            });
        }), map(() => new DisconnectAccessAutomationOperandValueSuccessAction()), catchError((err, caught) => {
            return caught;
        }));
        this.updateExpressionOperator = this.actions$.pipe(ofType(AccessAutomationsActions.UpdateExpressionOperator), switchMap((action) => this.service.updateExpressions(action.payload.expressionId, action.payload.data)), map((response) => {
            return new UpdateExpressionOperatorSuccessAction(response);
        }), catchError((err, caught) => caught));
        this.createExpression = this.actions$.pipe(ofType(AccessAutomationsActions.CreateExpression), switchMap((action) => {
            return this.service.createExpression(action.payload.data);
        }), map((response) => {
            return new CreateExpressionSuccessAction(response);
        }), catchError((err, caught) => {
            return caught;
        }));
        this.deleteExpression = this.actions$.pipe(ofType(AccessAutomationsActions.DeleteExpression), concatMap((action) => {
            return of([
                this.service.deleteExpression(action.payload.id),
                action.payload.automationId,
                action.payload.operandId
            ]);
        }), tap((obs) => {
            return obs[0].subscribe(() => {
                return this.store.dispatch(new CreateExpressionAction({
                    data: {
                        operator: 'AND',
                        operatorLeft: 'IN',
                        automationId: obs[1],
                        order: 3,
                        operandId: obs[2] === 3 ? 4 : 3
                    },
                    automationId: obs[1],
                    operandId: obs[2]
                }));
            });
        }), map((obs) => {
            return new DeleteExpressionSuccessAction(obs[2]);
        }), catchError((err, caught) => {
            return caught;
        }));
        this.getAccessAutomationBuildingCommonArea = this.actions$.pipe(ofType(AccessAutomationsActions.GetAccessAutomationBuildingCommonArea), switchMap((action) => this.service.getCommonAreaAccessDoors({
            buildingId: action.payload.buildingId,
            query: null
        })), map((response) => new GetAccessAutomationBuildingCommonAreaSuccessAction(response)), catchError((err, caught) => caught));
        this.GetAccessAutomationLeasingUals = this.actions$.pipe(ofType(AccessAutomationsActions.GetAccessAutomationLeasingUals), concatMap((action) => this.service.getCommonAreaAccessUals({
            buildingId: action.payload.buildingId,
            query: { search: 'leasing' }
        })), map((response) => new GetAccessAutomationLeasingUalsSuccessAction(response)), catchError((err, caught) => caught));
        this.GetAccessAutomationVacancyUals = this.actions$.pipe(ofType(AccessAutomationsActions.GetAccessAutomationVacancyUals), concatMap((action) => this.service.getCommonAreaAccessUals({
            buildingId: action.payload.buildingId,
            query: { search: 'vacancy' }
        })), map((response) => new GetAccessAutomationVacancyUalsSuccessAction(response)), catchError((err, caught) => caught));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "updateAccessAutomationsSearchQuery", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "getAccessAutomations", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "getAccessAutomation", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "saveAccessAutomation", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "createAccessAutomation", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "deleteAccessAutomation", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "deleteAccessAutomationFromTable", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "enableDisableAccessAutomation", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "enableAccessAutomation", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "secondEnableAccessAutomation", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "disableAccessAutomation", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "getAccessAutomationAllActions", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "getAccessAutomationAllOperands", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "getAccessAutomationAction", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "connectAccessAutomationOperandValue", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "disconnectAccessAutomationOperandValue", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "updateExpressionOperator", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "createExpression", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "deleteExpression", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "getAccessAutomationBuildingCommonArea", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "GetAccessAutomationLeasingUals", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AccessAutomationsEffects.prototype, "GetAccessAutomationVacancyUals", void 0);
