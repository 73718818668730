import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth/auth.service';
import { Router } from "@angular/router";


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.sass']
})
export class ForgotPasswordComponent implements OnInit {

  public form: FormGroup;
  public success: boolean = false;

  constructor(
    private auth: AuthService,
    private router: Router,
  ) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      //password: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {

  }
  public sendEmail() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.auth.forgotPassword(this.form.controls.email.value).subscribe(item => {
        //console.log(item);
        if (item) {
          this.success = true;
        }
      });
    }
  }

  public changeFormContent = () => {
    this.router.navigate(['auth/signin'])
  }
}

