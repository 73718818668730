import {Action} from '@ngrx/store';
import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {RolesRequestResponseInterface} from '../../interfaces/role.interface';

export enum RolesActions {
  GetRoles = '[Roles] Get Roles',
  GetRolesSuccess = '[Roles] Get Roles Success',
  UpdateRolesSearchQueryRaw = '[Roles] Update Search Query Raw',
  GetRole = '[Role Item] Get Role',
  GetRoleSuccess = '[Role Item] Get Role Success',
  SaveRole = '[Role Item] Save Role',
  DeleteRoleFromStore = '[Role Item] Delete Role From Store',
  CreateRole = '[Role Item] Create Role',
  DeleteRole = '[Role Item] Delete Role',
  GetAllRoles = '[Roles] Get All Roles',
  GetAllRolesSuccess = '[Roles] Get All Roles Success',
  GetAllNeighborhoods = '[Roles] Get All Neighborhoods',
  GetAllNeighborhoodsSuccess = '[Roles] Get All Neighborhoods Success',

  GetCompanyInfo = '[Roles] Get Company Info',
  GetCompanyInfoSuccess = '[Roles] Get Company Info Success',
  SetCompanyLoading = '[Roles] Set Company Loading',

  GetCompanyInfoForAdmin= '[Roles] Get Company Info For Admin',
  GetCompanyInfoForAdminSuccess = '[Roles] Get Company Info For Admin Success',
}

export class GetRolesAction implements Action {
  type = RolesActions.GetRoles;

  constructor(public payload: QueryParamsInterface) {
  }
}

export class GetRolesSuccessAction implements Action {
  type = RolesActions.GetRolesSuccess;

  constructor(public payload: RolesRequestResponseInterface) {
  }
}

export class UpdateRolesSearchQueryRawAction implements Action {
  type = RolesActions.UpdateRolesSearchQueryRaw;

  constructor(public payload: QueryParamsInterface) {
  }
}

export class GetRoleAction implements Action {
  type = RolesActions.GetRole;

  constructor(public payload: number) {
  }
}

export class SaveRoleAction implements Action {
  type = RolesActions.SaveRole;

  constructor(public payload) {
  }
}

export class GetRoleSuccessAction implements Action {
  type = RolesActions.GetRoleSuccess;

  constructor(public payload) {
  }
}

export class DeleteRoleFromStoreAction implements Action {
  type = RolesActions.DeleteRoleFromStore;

  constructor(public payload?) {
  }
}

export class CreateRoleAction implements Action {
  type = RolesActions.CreateRole;

  constructor(public payload) {
  }
}

export class DeleteRoleAction implements Action {
  type = RolesActions.DeleteRole;

  constructor(public payload: number) {
  }
}

export class GetAllRolesAction implements Action {
  type = RolesActions.GetAllRoles;

  constructor(public payload?) {
  }
}

export class GetAllRolesSuccessAction implements Action {
  type = RolesActions.GetAllRolesSuccess;

  constructor(public payload: RolesRequestResponseInterface) {
  }
}

export class GetAllNeighborhoodsAction implements Action {
  public readonly type: string = RolesActions.GetAllNeighborhoods;

  constructor(public payload?: any) {
  }
}

export class GetAllNeighborhoodsSuccessAction implements Action{
  public readonly type: string = RolesActions.GetAllNeighborhoodsSuccess;

  constructor(public payload?: any) {
  }
}

export class GetCompanyInfoAction implements Action {
  public readonly type: string = RolesActions.GetCompanyInfo;

  constructor(public payload: any) {
  }
}
export class GetCompanyInfoSuccessAction implements Action {
  public readonly type: string = RolesActions.GetCompanyInfoSuccess;

  constructor(public payload: any) {
  }
}
export class GetCompanyInfoForAdminAction implements Action {
  public readonly type: string = RolesActions.GetCompanyInfoForAdmin;

  constructor(public payload: any) {
  }
}
export class GetCompanyInfoForAdminSuccessAction implements Action {
  public readonly type: string = RolesActions.GetCompanyInfoForAdminSuccess;

  constructor(public payload: any) {
  }
}
export class SetCompanyLoadingAction implements Action {
  public readonly type: string = RolesActions.SetCompanyLoading;

  constructor(public payload: boolean) {
  }
}

export type RolesActionsUnion =
  GetRolesAction
  | GetRolesSuccessAction
  | UpdateRolesSearchQueryRawAction
  | GetRoleAction
  | GetRoleSuccessAction
  | SaveRoleAction
  | DeleteRoleFromStoreAction
  | CreateRoleAction
  | DeleteRoleAction
  | GetAllRolesAction
  | GetAllRolesSuccessAction
  | GetAllNeighborhoodsAction
  | GetAllNeighborhoodsSuccessAction
  | GetCompanyInfoAction
  | GetCompanyInfoSuccessAction
  | GetCompanyInfoForAdminAction
  | GetCompanyInfoForAdminSuccessAction
  | SetCompanyLoadingAction;
