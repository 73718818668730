import * as i0 from "./automation-report-modal.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./automation-report-modal.component";
import * as i4 from "@ngrx/store";
var styles_AutomationsLogsStepsModalComponent = [i0.styles];
var RenderType_AutomationsLogsStepsModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AutomationsLogsStepsModalComponent, data: {} });
export { RenderType_AutomationsLogsStepsModalComponent as RenderType_AutomationsLogsStepsModalComponent };
function View_AutomationsLogsStepsModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "steps-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "steps-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Step ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "steps-element-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\"Action\":"])), (_l()(), i1.ɵted(6, null, [" \"", "\" "])), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "steps-element-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\"Status\":"])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "span", [["class", "status-text"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(12, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(13, { success: 0, skip: 1, warn: 2 }), (_l()(), i1.ɵted(14, null, [" \"", "\""]))], function (_ck, _v) { var currVal_2 = "status-text"; var currVal_3 = _ck(_v, 13, 0, (((_v.context.$implicit == null) ? null : _v.context.$implicit.result) === "success"), (((_v.context.$implicit == null) ? null : _v.context.$implicit.result) === "skip"), (((_v.context.$implicit == null) ? null : _v.context.$implicit.result) === "warn")); _ck(_v, 12, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = (_v.context.index + 1); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.msg); _ck(_v, 6, 0, currVal_1); var currVal_4 = _v.context.$implicit.result; _ck(_v, 14, 0, currVal_4); }); }
export function View_AutomationsLogsStepsModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "confirmation-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "automation-log-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AutomationsLogsStepsModalComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "btn-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["class", "btn btn-sm btn-green"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirm"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.steps; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_AutomationsLogsStepsModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-automation-report-modal", [], null, null, null, View_AutomationsLogsStepsModalComponent_0, RenderType_AutomationsLogsStepsModalComponent)), i1.ɵdid(1, 245760, null, 0, i3.AutomationsLogsStepsModalComponent, [i4.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AutomationsLogsStepsModalComponentNgFactory = i1.ɵccf("app-automation-report-modal", i3.AutomationsLogsStepsModalComponent, View_AutomationsLogsStepsModalComponent_Host_0, {}, {}, []);
export { AutomationsLogsStepsModalComponentNgFactory as AutomationsLogsStepsModalComponentNgFactory };
