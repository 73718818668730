import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth/auth.service';
import {Store} from '@ngrx/store';
import {LoginAction} from '../../Store/actions/profile.actions';
import {Router, ActivatedRoute} from '@angular/router';
import {filter, pairwise} from 'rxjs/operators';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  public form: FormGroup;
  public forgotPassForm: FormGroup;
  public success: boolean = false;
  public isForgotPass: boolean = false;
  public formContent: boolean = false;
  public ldap: boolean = false;

  constructor(
    private store: Store<any>,
    private router: Router,
    private auth: AuthService,
    private activatedRouter: ActivatedRoute,
    private notifierService: NotifierService,
  ) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
      remember: new FormControl(false)
    });
    this.forgotPassForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    })
  }

  ngOnInit() {
    if (this.activatedRouter.snapshot.queryParamMap.get('error')) {
      this.notifierService.notify('error', this.activatedRouter.snapshot.queryParamMap.get('error'));
      this.router.navigate(
        ['.'],
        { relativeTo: this.activatedRouter, queryParams: {} }
      );
    }
    if (this.router.url.includes('/auth/ldap-signup')) {
      this.ldap = true;
    }
    //this.router.navigate(['/dashboard']);
  }

  public onSubmit = () => {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.store.dispatch(new LoginAction(this.form.getRawValue()));
    }
  };

  public changePage = () => {
    this.isForgotPass = !this.isForgotPass;
  }

  public sendEmail() {
    this.auth.forgotPassword(this.form.controls.email.value).subscribe(item => {
      //console.log(item);
      if (item) {
        this.success = true;
      }
    });
  }

  public loginAzure() {
    this.auth.loginAzure().subscribe((res: any) => {
      if (res && res.ldapUrl) {
        window.open(res.ldapUrl, '_self')
      }
    })
  }

  public changeFormContent() {
    this.formContent = !this.formContent;
  }

  public changeFo
}
