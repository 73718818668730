import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private api: ApiService) {}

  public login = (authData) => this.api.call('POST', `auth/signin`, authData);

  public refreshToken = (data) => this.api.call('POST', 'auth/refresh', data);

  public loginAzure = () => this.api.call('GET', `ldap/get-login-url`);

  public redirectUrl = (url) => this.api.callShortUrl('GET', url);

  public logOut = () => this.api.call('POST', `auth/signout`);

  public getProfile = () => this.api.call('GET', 'profile');

  // TODO: put logic with profile in new service
  public saveProfile = (user) => this.api.call('PUT', 'profile', user);

  public saveAvatar = (data: FormData) =>
    this.api.fileCall('PUT', 'profile/avatar', data);

  public resetPassword = (oldPassword, newPassword) =>
    this.api.call('PUT', 'profile/password', {
      password: newPassword,
      oldPassword: oldPassword
    });

  public forgotPassword = (email) =>
    this.api.call('POST', 'passwordrecovery', {email: email});

  public passRecovery = (id) =>
    this.api.call('GET', `passwordrecovery/is-recovery-requested?id=${id}`);

  public passChange = (email, id, password) =>
    this.api.call('POST', 'passwordrecovery/change', {
      email: email,
      id: id,
      password: password
    });

  public exportList = (type, query, isMicro = false) =>
    this.api.call2('GET', type, null, query, isMicro);

  public upload = (body) =>
    this.api.fileCall('POST', 'tenants/upload-manual', body);
}
