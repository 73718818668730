import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {
  BuildingsActions,
  BuildingsActionsUnion,
  DeleteBuildingCommonAreaAccessDoorsFromStoreAction
} from '../actions/buildings.actions';
import {
  BuildingInterface,
  BuildingsRequestResponseInterface
} from '../../interfaces/buildings.interface';

export interface BuildingsState {
  buildings: BuildingsRequestResponseInterface;
  building: BuildingInterface;
  buildingsSearchQuery: QueryParamsInterface;
  buildingId: number;
  buildingCommonAreaAccessDoors: any;
  buildingCommonAreaAccessUals: any;
  vacantApartmentAutomationLeasingUal: any;
  vacantApartmentAutomationOperationsUal: any;
  modalInfo: {isOpen: boolean; message: string};

  saveBuilding: boolean;
}

const initialState: BuildingsState = {
  buildings: {buildings: [], total: 0},
  building: null,
  buildingsSearchQuery: null,
  buildingId: null,
  buildingCommonAreaAccessDoors: null,
  buildingCommonAreaAccessUals: null,
  vacantApartmentAutomationLeasingUal: null,
  vacantApartmentAutomationOperationsUal: null,
  modalInfo: {isOpen: false, message: null},

  saveBuilding: false
};

export function buildingsReducer(
  state: BuildingsState = initialState,
  action: BuildingsActionsUnion
) {
  switch (action.type) {
    case BuildingsActions.SetBuildingId: {
      return {
        ...state,
        buildingId: action.payload
      };
    }
    case BuildingsActions.GetBuildingsSuccess: {
      return {
        ...state,
        buildings: action.payload
      };
    }
    case BuildingsActions.UpdateBuildingsSearchQuery: {
      return {
        ...state,
        buildingsSearchQuery: {
          ...state.buildingsSearchQuery,
          ...action.payload
        }
      };
    }
    case BuildingsActions.GetBuildingSuccess: {
      return {
        ...state,
        building: action.payload
      };
    }
    case BuildingsActions.DeleteBuildingFromStore: {
      return {
        ...state,
        building: null
      };
    }
    case BuildingsActions.GetBuildingCommonAreaAccessDoorsSuccess: {
      return {
        ...state,
        buildingCommonAreaAccessDoors: action.payload
      };
    }
    case BuildingsActions.UpdateBuildingCommonAreaAccessDoorsSearchQuery: {
      return {
        ...state,
        buildingCommonAreaAccessDoors: {
          ...state.buildingCommonAreaAccessDoors,
          ...action.payload
        }
      };
    }
    case BuildingsActions.DeleteBuildingCommonAreaAccessDoorsFromStore: {
      return {
        ...state,
        buildingCommonAreaAccessDoors: null
      };
    }
    case BuildingsActions.GetBuildingCommonAreaAccessUalsSuccess: {
      return {
        ...state,
        buildingCommonAreaAccessUals: action.payload
      };
    }
    case BuildingsActions.UpdateBuildingCommonAreaAccessUalsSearchQuery: {
      return {
        ...state,
        buildingCommonAreaAccessUals: {
          ...state.buildingCommonAreaAccessUals,
          ...action.payload
        }
      };
    }
    case BuildingsActions.DeleteBuildingCommonAreaAccessUalsFromStore: {
      return {
        ...state,
        buildingCommonAreaAccessUals: null
      };
    }
    case BuildingsActions.GetBuildingVacantApartmentAutomationLeasingUalsSuccess: {
      return {
        ...state,
        vacantApartmentAutomationLeasingUal: action.payload
      };
    }
    case BuildingsActions.UpdateBuildingVacantApartmentAutomationLeasingUalsSearchQuery: {
      return {
        ...state,
        vacantApartmentAutomationLeasingUal: {
          ...state.vacantApartmentAutomationLeasingUal,
          ...action.payload
        }
      };
    }
    case BuildingsActions.DeleteBuildingVacantApartmentAutomationLeasingUalsFromStore: {
      return {
        ...state,
        vacantApartmentAutomationLeasingUal: null
      };
    }
    case BuildingsActions.GetBuildingVacantApartmentAutomationOperationsUalsSuccess: {
      return {
        ...state,
        vacantApartmentAutomationOperationsUal: action.payload
      };
    }
    case BuildingsActions.UpdateBuildingVacantApartmentAutomationOperationsUalsSearchQuery: {
      return {
        ...state,
        vacantApartmentAutomationOperationsUal: {
          ...state.vacantApartmentAutomationOperationsUal,
          ...action.payload
        }
      };
    }
    case BuildingsActions.DeleteBuildingVacantApartmentAutomationOperationsUalsFromStore: {
      return {
        ...state,
        vacantApartmentAutomationOperationsUal: null
      };
    }
    case BuildingsActions.OpenInfoModal: {
      return {
        ...state,
        modalInfo: {
          isOpen: action.payload.isOpen,
          message: action.payload.message
        }
      };
    }
    case BuildingsActions.SaveBuildingSuccess: {
      return {
        ...state,
        saveBuilding: true
      };
    }
    default: {
      return state;
    }
  }
}
