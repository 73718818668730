<div class="automation-modal">
  <div class="content-wrap">
    <div class="table-spinner" *ngIf="spinner">
      <mat-progress-spinner
        class="green"
        [mode]="mode"
        diameter="40"
      ></mat-progress-spinner>
    </div>
    <form [formGroup]="automationForm">
      <div
        [ngClass]="{
          'disable-edit': !user?.permissions?.system?.buildings?.write
        }"
        *ngIf="!spinner"
        class="automation-container"
      >
        <h1 class="automation-title">
          {{ automationId ? 'Edit automation' : 'Add new automation' }}
        </h1>
        <div class="name-container">
          <div
            class="field-box textarea-inner automation-name-field"
            [ngClass]="{
              'field-error':
                automationForm.controls.name.invalid &&
                automationForm.controls.name.touched
            }"
          >
            <p class="field-title mL0 normalWeight">Name</p>
            <textarea
              formControlName="name"
              placeholder="Name"
              rows="3"
            ></textarea>
            <p
              *ngIf="automationForm.controls.name.errors?.required"
              class="field-state"
            >
              Name is required!
            </p>
            <p
              *ngIf="
                !automationForm.controls.name.errors?.required &&
                automationForm.controls.name.errors?.minlength
              "
              class="field-state"
            >
              Name is too short!
            </p>
            <p
              *ngIf="
                !automationForm.controls.name.errors?.required &&
                automationForm.controls.name.errors?.maxlength
              "
              class="field-state"
            >
              Name is too long!
            </p>
          </div>
          <div
            class="field-box textarea-inner automation-description-field"
            [ngClass]="{
              'field-error':
                automationForm.controls.description?.invalid &&
                automationForm.controls.description?.touched
            }"
          >
            <p class="field-title mL0 normalWeight">Description</p>
            <textarea
              formControlName="description"
              placeholder="Description"
              rows="3"
            ></textarea>
            <p
              *ngIf="automationForm.controls.description?.errors?.minlength"
              class="field-state"
            >
              Description is too short!
            </p>
            <p
              *ngIf="automationForm.controls.description?.errors?.maxlength"
              class="field-state"
            >
              Description is too long!
            </p>
          </div>
        </div>
        <div formArrayName="expressions" class="automation-item">
          <div class="automation-text">
            <div style="margin-right: 30px">
              <span>If resident <b>status</b> is</span>

              <span
                class="automation-link mL"
                (click)="changeSelectWindowStatus('isResidentsStatus')"
                [ngClass]="{
                  'with-element': residentsStatus?.value?.length,
                  checked: isResidentsStatus,
                  'default-cursor': checkOpenedModal()
                }"
                >{{
                  residentsStatus?.value?.length
                    ? formattingOperandValuesToString(residentsStatus?.value)
                    : 'some'
                }}</span
              >

              <div
                *ngIf="isResidentsStatus"
                id="residents-list"
                class="automation-select-container"
              >
                <div class="automation-select-list">
                  <ul>
                    <li *ngFor="let item of residentsStatusList">
                      <div class="field-box-input">
                        <input
                          id="residents-{{ item.id }}"
                          type="checkbox"
                          class="checkbox-green checkbox-md"
                          [checked]="checkOperandValues(residentsStatus, item)"
                          (change)="
                            changeCheckbox($event, item, 'residentsStatus')
                          "
                        />
                        <label
                          style="
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                          "
                          for="residents-{{ item.id }}"
                        >
                          <span class="check-txt">{{ item.value }}</span>
                          <span class="check mr-0"></span>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div class="field-box-input">
                        <input
                          id="residents-0"
                          type="checkbox"
                          class="checkbox-green checkbox-md"
                          [checked]="
                            checkOperandValues(residentsStatus, {
                              id: 0,
                              value: 'N/A',
                              operandId: 0
                            })
                          "
                          (change)="
                            changeCheckbox(
                              $event,
                              {id: 0, value: 'N/A', operandId: 0},
                              'residentsStatus'
                            )
                          "
                        />
                        <label
                          style="
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                          "
                          for="residents-0"
                          [ngClass]="{
                            'check-disabled': checkStatusNA('apartmentStatus')
                          }"
                        >
                          <span class="check-txt">N/A</span>
                          <span
                            class="check mr-0"
                            [ngClass]="{
                              'check-disabled': checkStatusNA('apartmentStatus')
                            }"
                          ></span>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="automation-select-button">
                  <div class="btn-wrap">
                    <button
                      type="button"
                      class="btn btn-sm btn-green"
                      [ngClass]="{disabled: !residentsStatus?.value?.length}"
                      (click)="applyChanges('isResidentsStatus')"
                    >
                      <span>APPLY</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="firstOperation.value">
              <span
                (click)="changeSelectWindowStatus('isFirstOperationUnderWord')"
                class="automation-operation with-element"
                [ngClass]="{checked: isFirstOperationUnderWord, 'default-cursor': checkOpenedModal()}"
                >{{ firstOperation.value }}</span
              >
              <div
                *ngIf="isFirstOperationUnderWord"
                class="automation-select-container"
              >
                <div class="automation-select-list">
                  <div class="radio-wrap">
                    <div class="radio-cel">
                      <label for="firstOperationUnderWordOr" class="container">
                        <input
                          type="radio"
                          id="firstOperationUnderWordOr"
                          value="OR"
                          [checked]="firstOperation?.value === 'OR'"
                          (change)="changeRadio($event, 'firstOperation')"
                        />
                        <span>OR</span>
                        <span class="checkmark green"></span>
                      </label>
                    </div>
                    <div class="radio-cel">
                      <label for="firstOperationUnderWordAnd" class="container">
                        <input
                          type="radio"
                          id="firstOperationUnderWordAnd"
                          value="AND"
                          [checked]="firstOperation?.value === 'AND'"
                          (change)="changeRadio($event, 'firstOperation')"
                        />
                        <span>AND</span>
                        <span class="checkmark green"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="automation-select-button">
                  <div class="btn-wrap">
                    <button
                      type="button"
                      class="btn btn-sm btn-green"
                      [ngClass]="{disabled: !firstOperation?.value}"
                      (click)="applyChanges('isFirstOperationUnderWord')"
                    >
                      <span>APPLY</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div style="margin-right: 30px" *ngIf="firstOperation.value">
              <span><b>Apartment</b> status is</span>
              <span
                class="automation-link mL"
                (click)="changeSelectWindowStatus('isApartmentStatus')"
                [ngClass]="{
                  'with-element': apartmentStatus?.value?.length,
                  checked: isApartmentStatus,
                  'default-cursor': checkOpenedModal()
                }"
                >{{
                  apartmentStatus?.value?.length
                    ? formattingOperandValuesToString(apartmentStatus?.value)
                    : 'some'
                }}</span
              >
              <div
                *ngIf="isApartmentStatus"
                class="automation-select-container"
              >
                <div class="automation-select-list">
                  <ul>
                    <li *ngFor="let item of apartmentsStatusList">
                      <div class="field-box-input">
                        <input
                          id="{{ item.value }}"
                          type="checkbox"
                          class="checkbox-green checkbox-md"
                          [checked]="checkOperandValues(apartmentStatus, item)"
                          (change)="
                            changeCheckbox($event, item, 'apartmentStatus')
                          "
                        />
                        <label
                          style="
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                          "
                          for="{{ item.value }}"
                        >
                          <span class="check-txt">{{ item.value }}</span>
                          <span class="check mr-0"></span>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div class="field-box-input">
                        <input
                          id="apartments-0"
                          type="checkbox"
                          class="checkbox-green checkbox-md"
                          [checked]="
                            checkOperandValues(apartmentStatus, {
                              id: 0,
                              value: 'N/A',
                              operandId: 0
                            })
                          "
                          (change)="
                            changeCheckbox(
                              $event,
                              {id: 0, value: 'N/A', operandId: 0},
                              'apartmentStatus'
                            )
                          "
                        />
                        <label
                          style="
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                          "
                          for="apartments-0"
                          [ngClass]="{
                            'check-disabled': checkStatusNA('residentsStatus')
                          }"
                        >
                          <span class="check-txt">N/A</span>
                          <span
                            class="check mr-0"
                            [ngClass]="{
                              'check-disabled': checkStatusNA('residentsStatus')
                            }"
                          ></span>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="automation-select-button">
                  <div class="btn-wrap">
                    <button
                      type="button"
                      class="btn btn-sm btn-green"
                      [ngClass]="{disabled: !apartmentStatus?.value?.length}"
                      (click)="applyChanges('isApartmentStatus')"
                    >
                      <span>APPLY</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="secondOperation.value">
              <span
                class="automation-operation with-element"
                (click)="changeSelectWindowStatus('isSecondOperationUnderWord')"
                [ngClass]="{checked: isSecondOperationUnderWord, 'default-cursor': checkOpenedModal()}"
                >{{ secondOperation.value }}</span
              >
              <div
                *ngIf="isSecondOperationUnderWord"
                class="automation-select-container"
              >
                <div class="automation-select-list">
                  <div class="radio-wrap">
                    <div class="radio-cel">
                      <label for="secondOperationUnderWordOr" class="container">
                        <input
                          type="radio"
                          id="secondOperationUnderWordOr"
                          value="OR"
                          [checked]="secondOperation?.value === 'OR'"
                          (change)="changeRadio($event, 'secondOperation')"
                        />
                        <span>OR</span>
                        <span class="checkmark green"></span>
                      </label>
                    </div>
                    <div class="radio-cel">
                      <label
                        for="secondOperationUnderWordAnd"
                        class="container"
                      >
                        <input
                          type="radio"
                          id="secondOperationUnderWordAnd"
                          value="AND"
                          [checked]="secondOperation?.value === 'AND'"
                          (change)="changeRadio($event, 'secondOperation')"
                        />
                        <span>AND</span>
                        <span class="checkmark green"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="automation-select-button">
                  <div class="btn-wrap">
                    <button
                      type="button"
                      class="btn btn-sm btn-green"
                      [ngClass]="{disabled: !secondOperation?.value}"
                      (click)="applyChanges('isSecondOperationUnderWord')"
                    >
                      <span>APPLY</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="secondOperation.value">
              <span>
                <b
                  class="automation-link with-element"
                  [ngClass]="{checked: isSelectedMove, 'default-cursor': checkOpenedModal()}"
                  (click)="changeSelectWindowStatus('isSelectedMove')"
                  *ngIf="selectedMove?.value === 'moveIn'"
                  >Move-in date</b
                >
                <b
                  class="automation-link with-element"
                  [ngClass]="{checked: isSelectedMove, 'default-cursor': checkOpenedModal()}"
                  (click)="changeSelectWindowStatus('isSelectedMove')"
                  *ngIf="selectedMove?.value === 'moveOut'"
                  >Move-out date</b
                >
                is</span
              >

              <div *ngIf="isSelectedMove" class="automation-select-container">
                <div class="automation-select-list">
                  <div class="radio-wrap">
                    <div class="radio-cel">
                      <label for="selectMoveIt" class="container">
                        <input
                          type="radio"
                          id="selectMoveIt"
                          value="moveIn"
                          [checked]="selectedMove?.value === 'moveIn'"
                          (change)="changeRadioMove($event, 'selectedMove')"
                        />
                        <span>Move-in date</span>
                        <span class="checkmark green"></span>
                      </label>
                    </div>
                    <div class="radio-cel">
                      <label for="selectMoveOut" class="container">
                        <input
                          type="radio"
                          id="selectMoveOut"
                          value="moveOut"
                          [checked]="selectedMove?.value === 'moveOut'"
                          (change)="changeRadioMove($event, 'selectedMove')"
                        />
                        <span>Move-out date</span>
                        <span class="checkmark green"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="automation-select-button">
                  <div class="btn-wrap">
                    <button
                      type="button"
                      class="btn btn-sm btn-green"
                      [ngClass]="{disabled: !selectedMove?.value}"
                      (click)="applyChanges('isSelectedMove')"
                    >
                      <span>APPLY</span>
                    </button>
                  </div>
                </div>
              </div>

              <span
                *ngIf="selectedMove?.value === 'moveIn'"
                class="automation-link mL"
                (click)="changeSelectWindowStatus('isMoveInDate')"
                [ngClass]="{
                  'with-element': moveInDate?.value?.length,
                  checked: isMoveInDate,
                  'default-cursor': checkOpenedModal()
                }"
                >{{
                  moveInDate?.value?.length
                    ? formattingOperandValuesToString(moveInDate?.value)
                    : 'sometime'
                }}</span
              >
              <span
                *ngIf="selectedMove?.value === 'moveOut'"
                class="automation-link mL"
                (click)="changeSelectWindowStatus('isMoveOutDate')"
                [ngClass]="{
                  'with-element': moveOutDate?.value?.length,
                  checked: isMoveOutDate,
                  'default-cursor': checkOpenedModal()
                }"
                >{{
                  moveOutDate?.value?.length
                    ? formattingOperandValuesToString(moveOutDate?.value)
                    : 'sometime'
                }}</span
              >
              <div *ngIf="isMoveInDate" class="automation-select-container">
                <div class="automation-select-list">
                  <ul>
                    <li *ngFor="let item of moveInDateStatusList">
                      <div class="field-box-input">
                        <input
                          id="{{ item.value }}"
                          type="checkbox"
                          class="checkbox-green checkbox-md"
                          [checked]="checkOperandValues(moveInDate, item)"
                          (change)="changeCheckbox($event, item, 'moveInDate')"
                        />
                        <label
                          style="
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                          "
                          for="{{ item.value }}"
                        >
                          <span class="check-txt">{{ item.value }}</span>
                          <span class="check mr-0"></span>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="automation-select-button">
                  <div class="btn-wrap">
                    <button
                      type="button"
                      class="btn btn-sm btn-green"
                      [ngClass]="{disabled: !moveInDate?.value?.length}"
                      (click)="applyChanges('isMoveInDate')"
                    >
                      <span>APPLY</span>
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="isMoveOutDate" class="automation-select-container">
                <div class="automation-select-list">
                  <ul>
                    <li *ngFor="let item of moveOutDateStatusList">
                      <div class="field-box-input">
                        <input
                          id="{{ item.value }}"
                          type="checkbox"
                          class="checkbox-green checkbox-md"
                          [checked]="checkOperandValues(moveOutDate, item)"
                          (change)="changeCheckbox($event, item, 'moveOutDate')"
                        />
                        <label
                          style="
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                          "
                          for="{{ item.value }}"
                        >
                          <span class="check-txt">{{ item.value }}</span>
                          <span class="check mr-0"></span>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="automation-select-button">
                  <div class="btn-wrap">
                    <button
                      type="button"
                      class="btn btn-sm btn-green"
                      [ngClass]="{disabled: !moveOutDate?.value?.length}"
                      (click)="applyChanges('isMoveOutDate')"
                    >
                      <span>APPLY</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="automation-action-buttons">
            <i
              (click)="addOperation()"
              class="walkIn-i walkIn-i-plus clr-green"
              [ngClass]="{
              'default-cursor': checkOpenedModal(),
                disabled:
                  moveInDate?.value?.length || moveOutDate?.value?.length || !residentsStatus?.value?.length
              }"
            ></i>
            <i
              [ngClass]="{'default-cursor': checkOpenedModal(), disabled: !firstOperation.value}"
              class="walkIn-i walkIn-i-new_trash"
              (click)="deleteAutomationElement()"
            ></i>
          </div>
        </div>

        <img
          src="assets/images/icons/new_arrow.svg"
          alt=""
          style="width: 17px; margin-bottom: 41px"
        />

        <div class="commands-container">
          <div>
            <div
              *ngFor="let item of newActions.controls; let i = index"
              class="automation-item"
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: baseline;
                  width: 100%;
                "
              >
                <div class="automation-text">
                  <div>
                    <span style="position: relative">
                      <span class="steps-title">Step #{{ i + 1 }}</span>
                      Then
                    </span>

                    <span
                      class="automation-link mL mR"
                      (click)="openActionDropdown(i)"
                      [ngClass]="{
                        'with-element': item?.value?.action?.name,
                        checked: item?.value?.checked,
                        'default-cursor': checkOpenedModal(),
                        defaultDisabled: automationForm.controls.author.value === 'global-system'
                      }"
                      >{{
                        item?.value?.action?.name
                          ? item?.value?.action?.name
                          : 'do this'
                      }}</span
                    >

                    <div
                      *ngIf="item?.value?.checked"
                      class="automation-select-container"
                    >
                      <div class="automation-select-list">
                        <div class="radio-wrap">
                          <div
                            *ngFor="let item of getActionsList(i)"
                            class="radio-cel"
                          >
                            <label for="action-{{ item.id }}" class="container">
                              <input
                                type="radio"
                                id="action-{{ item.id }}"
                                value="{{ item }}"
                                [checked]="
                                  newActions?.value[i]?.action?.name ===
                                  item.name
                                "
                                (change)="setAction(item, i)"
                              />
                              <span>{{ item.name }}</span>
                              <span class="checkmark green"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="automation-select-button">
                        <div class="btn-wrap">
                          <button
                            type="button"
                            class="btn btn-sm btn-green"
                            (click)="applyAction(i)"
                          >
                            <span>APPLY</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style="display: flex"
                    *ngIf="
                      item?.value?.action?.alias !== 'add_to_system' &&
                      item?.value?.action?.alias !== 'disable_resident'
                    "
                  >
                    <span>to</span>

                    <div
                      class="automation-link mL"
                      [ngClass]="{
                        'with-element': item?.value?.commands?.length,
                        checked: item?.value?.checkedCommands,
                        disabled: !item?.value?.action?.name || automationForm.controls.author.value === 'global-system',
                        'default-cursor': checkOpenedModal()
                      }"
                    >
                      <span
                        *ngIf="!item?.value?.commands?.length"
                        (click)="openCommandsDropdown(i)"
                        >someone</span
                      >
                      <div
                        style="display: flex; flex-direction: column"
                        *ngIf="item?.value?.commands?.length"
                        (click)="openCommandsDropdown(i)"
                      >
                        <span
                          *ngFor="
                            let el of item?.value?.commands;
                            let elIndex = index
                          "
                          >{{ el.name }}</span
                        >
                      </div>

                      <div
                        *ngIf="item?.value?.checkedCommands"
                        class="automation-select-container"
                      >
                        <div class="automation-select-list">
                          <ul>
                            <li
                              *ngFor="
                                let commandElement of getCurrentCommandsList(
                                  item?.value?.action?.alias,
                                  i
                                )
                              "
                            >
                              <div class="field-box-input">
                                <input
                                  id="action-to-{{
                                    commandElement?.element?.id
                                  }}"
                                  type="checkbox"
                                  class="checkbox-green checkbox-md"
                                  [checked]="
                                    checkActionTo(item, commandElement)
                                  "
                                  (change)="
                                    changeCheckboxActionTo(
                                      $event,
                                      commandElement,
                                      i
                                    )
                                  "
                                />
                                <label
                                  style="
                                    width: 100%;
                                    display: flex;
                                    justify-content: space-between;
                                  "
                                  for="action-to-{{
                                    commandElement?.element?.id
                                  }}"
                                >
                                  <span class="check-txt">{{
                                    commandElement?.element?.name
                                  }}</span>
                                  <span class="check mr-0"></span>
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="automation-select-button">
                          <div class="btn-wrap">
                            <button
                              type="button"
                              class="btn btn-sm btn-green"
                              (click)="applyCommand(i)"
                            >
                              <span>APPLY</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="automation-action-buttons center">
                  <div class="sorted-arrows">
                    <svg
                      [ngClass]="{disabled: i === newActions.value.length - 1 || automationForm.controls.author.value === 'global-system', 'default-cursor': checkOpenedModal()}"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      (click)="moveDown(i)"
                    >
                      <path
                        d="M19 20H5C4.73478 20 4.48043 20.1054 4.29289 20.2929C4.10536 20.4804 4 20.7348 4 21C4 21.2652 4.10536 21.5196 4.29289 21.7071C4.48043 21.8946 4.73478 22 5 22H19C19.2652 22 19.5196 21.8946 19.7071 21.7071C19.8946 21.5196 20 21.2652 20 21C20 20.7348 19.8946 20.4804 19.7071 20.2929C19.5196 20.1054 19.2652 20 19 20ZM11.29 17.71C11.3851 17.801 11.4972 17.8724 11.62 17.92C11.7397 17.9729 11.8691 18.0002 12 18.0002C12.1309 18.0002 12.2603 17.9729 12.38 17.92C12.5028 17.8724 12.6149 17.801 12.71 17.71L16.71 13.71C16.8983 13.5217 17.0041 13.2663 17.0041 13C17.0041 12.7337 16.8983 12.4783 16.71 12.29C16.5217 12.1017 16.2663 11.9959 16 11.9959C15.7337 11.9959 15.4783 12.1017 15.29 12.29L13 14.59V3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2C11.7348 2 11.4804 2.10536 11.2929 2.29289C11.1054 2.48043 11 2.73478 11 3V14.59L8.71 12.29C8.61676 12.1968 8.50607 12.1228 8.38425 12.0723C8.26243 12.0219 8.13186 11.9959 8 11.9959C7.86814 11.9959 7.73757 12.0219 7.61575 12.0723C7.49393 12.1228 7.38324 12.1968 7.29 12.29C7.19676 12.3832 7.1228 12.4939 7.07234 12.6158C7.02188 12.7376 6.99591 12.8681 6.99591 13C6.99591 13.1319 7.02188 13.2624 7.07234 13.3842C7.1228 13.5061 7.19676 13.6168 7.29 13.71L11.29 17.71Z"
                        fill="black"
                      />
                    </svg>
                    <svg
                      [ngClass]="{disabled: i === 0 || automationForm.controls.author.value === 'global-system', 'default-cursor': checkOpenedModal()}"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      (click)="moveUp(i)"
                    >
                      <path
                        d="M5 4L19 4C19.2652 4 19.5196 3.89464 19.7071 3.70711C19.8946 3.51957 20 3.26522 20 3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2L5 2C4.73478 2 4.48043 2.10535 4.29289 2.29289C4.10536 2.48043 4 2.73478 4 3C4 3.26522 4.10536 3.51957 4.29289 3.70711C4.48043 3.89464 4.73478 4 5 4ZM12.71 6.29C12.6149 6.19896 12.5028 6.12759 12.38 6.08C12.2603 6.02709 12.1309 5.99977 12 5.99977C11.8691 5.99977 11.7397 6.02709 11.62 6.08C11.4973 6.12759 11.3851 6.19896 11.29 6.29L7.29 10.29C7.1017 10.4783 6.99591 10.7337 6.99591 11C6.99591 11.2663 7.1017 11.5217 7.29 11.71C7.47831 11.8983 7.7337 12.0041 8 12.0041C8.2663 12.0041 8.5217 11.8983 8.71 11.71L11 9.41L11 21C11 21.2652 11.1054 21.5196 11.2929 21.7071C11.4804 21.8946 11.7348 22 12 22C12.2652 22 12.5196 21.8946 12.7071 21.7071C12.8946 21.5196 13 21.2652 13 21L13 9.41L15.29 11.71C15.3832 11.8032 15.4939 11.8772 15.6158 11.9277C15.7376 11.9781 15.8681 12.0041 16 12.0041C16.1319 12.0041 16.2624 11.9781 16.3843 11.9277C16.5061 11.8772 16.6168 11.8032 16.71 11.71C16.8032 11.6168 16.8772 11.5061 16.9277 11.3842C16.9781 11.2624 17.0041 11.1319 17.0041 11C17.0041 10.8681 16.9781 10.7376 16.9277 10.6158C16.8772 10.4939 16.8032 10.3832 16.71 10.29L12.71 6.29Z"
                        fill="black"
                      />
                    </svg>
                  </div>

                  <i
                    (click)="addNewActionsGroups()"
                    class="walkIn-i walkIn-i-plus clr-green"
                    style="margin-right: 19px; font-size: 18px"
                    [ngClass]="{
                      'default-cursor': checkOpenedModal(),
                      disabled:
                        !getActionsList()?.length ||
                        !newActions?.value[newActions.value.length - 1]?.action
                          ?.name ||
                        !newActions?.value[newActions.value.length - 1]
                          ?.commands?.length ||
                          automationForm.controls.author.value === 'global-system'
                    }"
                  ></i>
                  <i
                    class="walkIn-i walkIn-i-new_trash"
                    (click)="deleteAction(i)"
                    [ngClass]="{'default-cursor': checkOpenedModal(),
                      disabled: (this.newActions.value.length <= 1) || automationForm.controls.author.value === 'global-system'
                    }"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div>
      <div class="btn-wrap-top-line">
        <button (click)="cancel()" [ngClass]="{'default-cursor': checkOpenedModal()}" type="button" class="btn btn-sm btn-white">
          <span>CANCEL</span>
        </button>
        <button
          type="button"
          (click)="submitForm()"
          [ngClass]="{'default-cursor': checkOpenedModal()}"
          class="btn btn-sm btn-green"
          [disabled]="!checkDisableSaveButton()"
        >
          <span>{{ automationId ? 'SAVE' : 'CREATE' }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
