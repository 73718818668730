import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { GetProfileAction, GetProfileSuccessAction, LoginAction, ProfileActions, SaveProfilesAvatarAction } from '../actions/profile.actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
const url = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent) ? `${window.location.origin}/dashboard/prospects/agent-dashboard-visitors` : `${window.location.origin}/dashboard`;
export class ProfileEffects {
    constructor(actions$, auth, router, notifierService) {
        this.actions$ = actions$;
        this.auth = auth;
        this.router = router;
        this.notifierService = notifierService;
        this.logIn$ = this.actions$.pipe(ofType(ProfileActions.LoginAction), switchMap((action) => this.auth.login(action.payload)), map((value) => {
            if (value.token) {
                localStorage.setItem('access_token', value.token);
                localStorage.setItem('refresh_token', value.refreshToken);
            }
        }), tap(() => window.location.replace(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent) ? `${window.location.origin}/dashboard/prospects/agent-dashboard-visitors` : `${window.location.origin}/dashboard`)), map(() => new GetProfileAction()), catchError((err, caught) => caught));
        this.logInAzure$ = this.actions$.pipe(ofType(ProfileActions.LoginAzureAction), switchMap(() => this.auth.loginAzure()), catchError((err, caught) => caught));
        this.getProfile = this.actions$.pipe(ofType(ProfileActions.GetProfileAction), switchMap(() => this.auth.getProfile()), map(profile => new GetProfileSuccessAction(profile)), catchError((err, caught) => caught));
        this.updateProfile = this.actions$.pipe(ofType(ProfileActions.UpdateProfileAction), switchMap(({ payload }) => this.auth.saveProfile(payload)), tap((response) => this.notifierService.notify('success', response.message)), map(() => new GetProfileAction()), catchError((err, caught) => caught));
        this.saveProfilesAvatar = this.actions$.pipe(ofType(ProfileActions.SaveProfilesAvatar), switchMap((action) => this.auth.saveAvatar(action.payload)), tap((response) => this.notifierService.notify('success', response.message)), map(() => new GetProfileAction()), catchError((err, caught) => caught));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ProfileEffects.prototype, "logIn$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ProfileEffects.prototype, "logInAzure$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ProfileEffects.prototype, "getProfile", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ProfileEffects.prototype, "updateProfile", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ProfileEffects.prototype, "saveProfilesAvatar", void 0);
