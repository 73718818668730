import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {AutomationsLogsStepsModalOptionsInterface} from '../../interfaces/modal.interface';
import {ModalService} from '../../services/modal.service';
import {AuditLogDeltaInterface} from '../../../dashboard/system/interfaces/audit-log.interface';
import {Store} from '@ngrx/store';
import {AuditLogState} from '../../../dashboard/system/Store/reducers/audit-log.reducer';
import {DeleteLogDeltaFromStoreAction} from '../../../dashboard/system/Store/actions/audit-log.actions';
import {dashboardSystemAuditLogsDeltaDataSelectUrl} from '../../../dashboard/system/constants/constants';

@Component({
  selector: 'app-audit-log-delta-modal',
  templateUrl: './audit-log-delta-modal.component.html',
  styleUrls: ['./audit-log-delta-modal.component.sass']
})
export class AuditLogDeltaModalComponent implements OnInit, OnDestroy {
  public events: Subject<any> = new Subject<any>();
  public options: AutomationsLogsStepsModalOptionsInterface;
  public modalService: ModalService;
  public delta: AuditLogDeltaInterface;

  constructor(private store: Store<AuditLogState>) {}

  ngOnInit() {
    this.store
      .select(...dashboardSystemAuditLogsDeltaDataSelectUrl)
      .subscribe((delta: AuditLogDeltaInterface) => {
        if (delta) {
          this.delta = delta;
        }
      });
  }

  ngOnDestroy(): void {
    this.events = null;
    this.store.dispatch(new DeleteLogDeltaFromStoreAction());
  }

  public cancel = () => {
    this.events.next(false);
    this.modalService.closeModal();
    this.store.dispatch(new DeleteLogDeltaFromStoreAction());
  };

  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  isObject(value: any): boolean {
    return typeof value === 'object' && value !== null;
  }
}
