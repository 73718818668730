<div class="auth">
  <div class="r-block">
    <div class="logo">
      <a><img src="assets/images/icons/new_logo_login.svg" alt=""/></a>
    </div>
  </div>
  <div class="l-block">
    <div class="content">

      <form [formGroup]="form" *ngIf="!success">
        <div class="form-group bg-white">
          <div (click)="changeFormContent()" class="back"><i class="walkIn-i walkIn-i-arrow"></i>Back</div>
          <h1>Forgot password</h1>
          <div class="field-box with-icon"
               [ngClass]="{
              'field-error': form.controls['email'].invalid && form.controls['email'].touched,
              'field-success': form.controls['email'].valid && form.controls['email'].touched
            }"
          >
            <p class="field-title">Email</p>
            <input type="text" placeholder="Email" formControlName="email">
            <i class="walkIn-i top10 walkIn-i-user clr-green"></i>
            <p *ngIf="form.controls['email'].invalid && form.controls['email'].touched" class="field-state">
              Email is required!</p>
          </div>
         <!-- <div class="field-box with-icon"
               [ngClass]="{
              'field-error': form.controls['password'].invalid && form.controls['password'].touched,
              'field-success': form.controls['password'].valid && form.controls['password'].touched
            }"
          >
            <input type="password" placeholder="Password" formControlName="password">
            <i class="walkIn-i walkIn-i-lock clr-yellow"></i>
            <p *ngIf="form.controls['password'].invalid && form.controls['password'].touched" class="field-state">
              Password is required!</p>
          </div>-->

        </div>
        <div class="form-group bg-transparent">
          <button type="button" class="btn btn-green" (click)="sendEmail()">
            <span>Send email</span>
          </button>
        </div>
      </form>
      <div class="form-group bg-white" style="display: flex; justify-content: center; align-items: center" *ngIf="success">
        <h2 style="text-align: center">Thank you!
          An email has been sent with further instructions. If you haven't received it, please check your spam folder.
        </h2>
      </div>
    </div>
  </div>

</div>
