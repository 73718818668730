import { Component, OnInit } from '@angular/core';
import {Subject} from "rxjs";
import {ModalOptionsInterface} from "../../interfaces/modal.interface";
import {ModalService} from "../../services/modal.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-doors-modal',
  templateUrl: './doors-modal.component.html',
  styleUrls: ['./doors-modal.component.sass']
})
export class DoorsModalComponent implements OnInit {
  public events: Subject<any> = new Subject<any>();
  public defaultMessage: string = 'Apartment door already assigned. Add another door?';
  public options: ModalOptionsInterface;
  public modalService: ModalService;

  constructor(private router: Router) {
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.events = null;
  }

  public cancel = () => {
    this.events.next(false);
    this.modalService.closeModal();
  };

  public confirm = () => {
    this.events.next(true);
    this.modalService.closeModal();
  };
}
