import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {AuthService} from '../../services/auth/auth.service';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {NotifierService} from 'angular-notifier';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private apiWhitelist = [
    'auth/signin',
    'ldap/get-login-url',
    'passwordrecovery'
  ];
  constructor(
    private authService: ApiService,
    private router: Router,
    private notifierService: NotifierService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<Object>> {
    let authReq = req;
    const token = localStorage.getItem('access_token');
    if (token != null) {
      authReq = this.addTokenHeader(req, token);
    }

    return next.handle(authReq).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          this.apiWhitelist.every((i) => !authReq.url.includes(i)) &&
          error.status === 401
        ) {
          return this.handle401Error(authReq, next);
        }

        return throwError(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token = localStorage.getItem('refresh_token');

      if (token && token !== 'undefined') {
        return this.authService.refreshToken().pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;

            localStorage.setItem('access_token', token.token);
            localStorage.setItem('refresh_token', token.refreshToken);
            this.refreshTokenSubject.next(token.token);

            return next.handle(this.addTokenHeader(request, token.token));
          }),
          catchError((err) => {
            if (err.status === 401) {
              this.isRefreshing = false;
              localStorage.removeItem('access_token');
              localStorage.removeItem('refresh_token');
              this.notifierService.notify('error', 'Sign in first');
              this.router.navigate(['auth/signin']);
            }
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            return throwError(err);
          })
        );
      } else {
        this.notifierService.notify('error', 'Sign in first');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        this.router.navigate(['auth/signin']);
      }
    }
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token)
    });
  }
}

export const authInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
];
