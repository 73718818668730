import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';
import {LoginModule} from './login/login.module';

import {StoreModule} from '@ngrx/store';
import {reducers, metaReducers} from './Store/reducers';
import {EffectsModule} from '@ngrx/effects';

import {NotifierModule} from 'angular-notifier';

import {AppComponent} from './app.component';
import {ApiService} from './services/api.service';
import {AuthService} from './services/auth/auth.service';
import {ProfileEffects} from './Store/effects/profile.effects';
import {ModalModule} from './modal/modal.module';
import {MessagingService} from './worker/messaging.service';
import {AsyncPipe} from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {AuthInterceptor, authInterceptorProviders} from './utills/Interceptor/auth.interceptor';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NotifierModule,
    ModalModule,
    LoginModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot([
      ProfileEffects
    ]),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    ApiService,
    AuthService,
    MessagingService,
    AsyncPipe,
    authInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
