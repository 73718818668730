<div class="confirmation-modal">
  <div class="content-wrapper" [ngClass]="{'apartment-modal': message}">
    <p>{{options?.message || message || defaultMessage}}</p>
    <div class="btn-wrap" [ngClass]="{'one-button': options?.isInfo}">
      <button type="button" (click)="cancel()" class="btn btn-sm btn-grey">
        <span>{{options?.isInfo ? 'Back' : 'Cancel'}}</span>
      </button>
      <button *ngIf="!message && !options?.isInfo" type="button" (click)="confirm()" class="btn btn-sm btn-green">
        <span>Confirm</span>
      </button>
    </div>
  </div>
</div>
