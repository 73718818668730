import {Action} from '@ngrx/store';
import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {
  BuildingInterface,
  BuildingsRequestResponseInterface,
  SaveBuildingInterface
} from '../../interfaces/buildings.interface';

export enum BuildingsActions {
  GetBuildings = '[Buildings] Get Buildings',
  GetBuildingsSuccess = '[Buildings] Get Buildings Success',
  UpdateBuildingsSearchQuery = '[Buildings] Update Buildings Search Query',
  GetBuildingId = '[Building Item] Get Building Id',
  SetBuildingId = '[Building Item] Set Building Id',
  GetBuilding = '[Buildings Item] Get Building',
  GetBuildingSuccess = '[Buildings Item] Get Building Success',
  CreateBuilding = '[Building Item] Create Building',
  DeleteBuildingFromStore = '[Building Item] Delete Building Form Store',
  SaveBuilding = '[Building Item] Save Building',
  SaveBuildingSuccess = '[Building Item] Save Building Success',
  SaveBuildingWithEnablingAutomations = '[Building Item] Save Building With Enabling Automations',
  EnableAutomations = '[Building Item] Enable Automations',
  DeleteBuilding = '[Building Item] Delete Building',
  DeleteBuildingFromTable = '[Building Item] Delete Building From Table',
  EnableBuilding = '[Building Item] Enable Building',
  DisableBuilding = '[Building Item] Disable Building',
  OpenInfoModal = '[Building Item] Open Info Modal',
  GetBuildingCommonAreaAccessDoors = '[Building Item] Get Building Common Area Access Doors',
  GetBuildingCommonAreaAccessDoorsSuccess = '[Building Item] Get Building Common Area Access Doors Success',
  UpdateBuildingCommonAreaAccess = '[Building Item] Update Building Common Area Access',
  UpdateBuildingCommonAreaAccessDoorsSearchQuery = '[Building Item] Update Building Common Area Access Doors Search Query',
  DeleteBuildingCommonAreaAccessDoorsFromStore = '[Building Item] Delete Building Common Area Access Doors From Store',
  GetBuildingCommonAreaAccessUals = '[Building Item] Get Building Common Area Access Uals',
  GetBuildingCommonAreaAccessUalsSuccess = '[Building Item] Get Building Common Area Access Uals Success',
  UpdateBuildingCommonAreaAccessUals = '[Building Item] Update Building Common Area Access Uals',
  UpdateBuildingCommonAreaAccessUalsSearchQuery = '[Building Item] Update Building Common Area Access Uals Search Query',
  DeleteBuildingCommonAreaAccessUalsFromStore = '[Building Item] Delete Building Common Area Access Uals From Store',

  GetBuildingVacantApartmentAutomationLeasingUals = '[Building Item] Get Building Vacant Apartment Automation Leasing Uals',
  UpdateBuildingVacantApartmentAutomationLeasingUals = '[Building Item] Update Building Vacant Apartment Automation Leasing Uals',
  UpdateBuildingVacantApartmentAutomationLeasingUalsSearchQuery = '[Building Item] Update Building Vacant Apartment Automation Leasing Uals Search Query',
  GetBuildingVacantApartmentAutomationLeasingUalsSuccess = '[Building Item] Get Building Vacant Apartment Automation Leasing Uals Success',
  DeleteBuildingVacantApartmentAutomationLeasingUalsFromStore = '[Building Item] Delete Building Vacant Apartment Automation Leasing Uals From Store',
  GetBuildingVacantApartmentAutomationOperationsUals = '[Building Item] Get Building Vacant Apartment Automation Operations Uals',
  UpdateBuildingVacantApartmentAutomationOperationsUals = '[Building Item] Update Building Vacant Apartment Automation Operations Uals',
  UpdateBuildingVacantApartmentAutomationOperationsUalsSearchQuery = '[Building Item] Update Building Vacant Apartment Automation Operations Uals Search Query',
  GetBuildingVacantApartmentAutomationOperationsUalsSuccess = '[Building Item] Get Building Vacant Apartment Automation Operations Ual Success',
  DeleteBuildingVacantApartmentAutomationOperationsUalsFromStore = '[Building Item] Delete Building Vacant Apartment Automation Operations Uals From Store',
  UpdateBuildingVacantApartmentAutomation = '[Building Item] Update Building Vacant Apartment Automation'
}

export class GetBuildingsAction implements Action {
  readonly type = BuildingsActions.GetBuildings;

  constructor(public payload: QueryParamsInterface) {}
}

export class GetBuildingsSuccessAction implements Action {
  readonly type = BuildingsActions.GetBuildingsSuccess;

  constructor(public payload: BuildingsRequestResponseInterface) {}
}

export class UpdateBuildingsSearchQuery implements Action {
  readonly type = BuildingsActions.UpdateBuildingsSearchQuery;

  constructor(public payload: QueryParamsInterface) {}
}

export class GetBuildingIdAction implements Action {
  readonly type = BuildingsActions.GetBuildingId;

  constructor(public payload?) {}
}
export class SetBuildingIdAction implements Action {
  readonly type = BuildingsActions.SetBuildingId;

  constructor(public payload: number) {}
}
export class GetBuildingAction implements Action {
  readonly type = BuildingsActions.GetBuilding;

  constructor(public payload: number) {}
}

export class GetBuildingSuccessAction implements Action {
  readonly type = BuildingsActions.GetBuildingSuccess;

  constructor(public payload: BuildingInterface) {}
}

export class CreateBuildingAction implements Action {
  readonly type = BuildingsActions.CreateBuilding;

  constructor(public payload: BuildingInterface) {}
}

export class DeleteBuildingFormStoreAction implements Action {
  readonly type = BuildingsActions.DeleteBuildingFromStore;

  constructor(public payload?) {}
}

export class SaveBuildingAction implements Action {
  readonly type = BuildingsActions.SaveBuilding;

  constructor(public payload: SaveBuildingInterface) {}
}

export class SaveBuildingSuccessAction implements Action {
  readonly type = BuildingsActions.SaveBuildingSuccess;

  constructor(public payload: boolean) {}
}

export class SaveBuildingWithEnablingAutomationsAction implements Action {
  readonly type = BuildingsActions.SaveBuildingWithEnablingAutomations;

  constructor(public payload: SaveBuildingInterface) {}
}
export class EnableAutomationsAction implements Action {
  readonly type = BuildingsActions.EnableAutomations;

  constructor(public payload?: any) {}
}

export class DeleteBuildingAction implements Action {
  readonly type = BuildingsActions.DeleteBuilding;

  constructor(public payload: number) {}
}
export class DeleteBuildingFromTableAction implements Action {
  readonly type = BuildingsActions.DeleteBuildingFromTable;

  constructor(public payload: number) {}
}
export class EnableBuildingAction implements Action {
  readonly type = BuildingsActions.EnableBuilding;

  constructor(public payload: number) {}
}
export class DisableBuildingAction implements Action {
  readonly type = BuildingsActions.DisableBuilding;

  constructor(public payload: number) {}
}
export class OpenInfoModalAction implements Action {
  readonly type = BuildingsActions.OpenInfoModal;

  constructor(public payload: {message: string; isOpen: boolean}) {}
}

export class GetBuildingCommonAreaAccessDoorsAction implements Action {
  readonly type = BuildingsActions.GetBuildingCommonAreaAccessDoors;

  constructor(public payload: any) {}
}
export class GetBuildingCommonAreaAccessDoorsSuccessAction implements Action {
  readonly type = BuildingsActions.GetBuildingCommonAreaAccessDoorsSuccess;

  constructor(public payload: any) {}
}
export class UpdateBuildingCommonAreaAccessAction implements Action {
  readonly type = BuildingsActions.UpdateBuildingCommonAreaAccess;

  constructor(public payload: {id: number; body: any}) {}
}
export class UpdateBuildingCommonAreaAccessDoorsSearchQueryAction
  implements Action {
  readonly type =
    BuildingsActions.UpdateBuildingCommonAreaAccessDoorsSearchQuery;

  constructor(public payload?: QueryParamsInterface) {}
}
export class DeleteBuildingCommonAreaAccessDoorsFromStoreAction
  implements Action {
  readonly type = BuildingsActions.DeleteBuildingCommonAreaAccessDoorsFromStore;

  constructor(public payload?: any) {}
}
export class GetBuildingCommonAreaAccessUalsAction implements Action {
  readonly type = BuildingsActions.GetBuildingCommonAreaAccessUals;

  constructor(public payload: any) {}
}
export class GetBuildingCommonAreaAccessUalsSuccessAction implements Action {
  readonly type = BuildingsActions.GetBuildingCommonAreaAccessUalsSuccess;

  constructor(public payload: any) {}
}
export class UpdateBuildingCommonAreaAccessUalsAction implements Action {
  readonly type = BuildingsActions.UpdateBuildingCommonAreaAccessUals;

  constructor(public payload: {id: number; body: any}) {}
}
export class UpdateBuildingCommonAreaAccessUalsSearchQueryAction
  implements Action {
  readonly type =
    BuildingsActions.UpdateBuildingCommonAreaAccessUalsSearchQuery;

  constructor(public payload?: QueryParamsInterface) {}
}
export class DeleteBuildingCommonAreaAccessUalsFromStoreAction
  implements Action {
  readonly type = BuildingsActions.DeleteBuildingCommonAreaAccessUalsFromStore;

  constructor(public payload?: any) {}
}
export class GetBuildingVacantApartmentAutomationLeasingUalsAction
  implements Action {
  readonly type =
    BuildingsActions.GetBuildingVacantApartmentAutomationLeasingUals;

  constructor(public payload: any) {}
}
export class UpdateBuildingVacantApartmentAutomationLeasingUalsAction
  implements Action {
  readonly type =
    BuildingsActions.UpdateBuildingVacantApartmentAutomationLeasingUals;

  constructor(public payload: number) {}
}
export class UpdateBuildingVacantApartmentAutomationLeasingUalsSearchQueryAction
  implements Action {
  readonly type =
    BuildingsActions.UpdateBuildingVacantApartmentAutomationLeasingUalsSearchQuery;

  constructor(public payload?: QueryParamsInterface) {}
}
export class GetBuildingVacantApartmentAutomationLeasingUalsSuccessAction
  implements Action {
  readonly type =
    BuildingsActions.GetBuildingVacantApartmentAutomationLeasingUalsSuccess;

  constructor(public payload: any) {}
}

export class GetBuildingVacantApartmentAutomationOperationsUalsAction
  implements Action {
  readonly type =
    BuildingsActions.GetBuildingVacantApartmentAutomationOperationsUals;

  constructor(public payload: any) {}
}
export class UpdateBuildingVacantApartmentAutomationOperationsUalsAction
  implements Action {
  readonly type =
    BuildingsActions.UpdateBuildingVacantApartmentAutomationOperationsUals;

  constructor(public payload: number) {}
}
export class UpdateBuildingVacantApartmentAutomationOperationsUalsSearchQueryAction
  implements Action {
  readonly type =
    BuildingsActions.UpdateBuildingVacantApartmentAutomationOperationsUalsSearchQuery;

  constructor(public payload?: QueryParamsInterface) {}
}
export class GetBuildingVacantApartmentAutomationOperationsUalsSuccessAction
  implements Action {
  readonly type =
    BuildingsActions.GetBuildingVacantApartmentAutomationOperationsUalsSuccess;

  constructor(public payload: any) {}
}
export class UpdateBuildingVacantApartmentAutomationAction implements Action {
  readonly type = BuildingsActions.UpdateBuildingVacantApartmentAutomation;

  constructor(public payload: {id: number; body: any}) {}
}
export class DeleteBuildingVacantApartmentAutomationLeasingUalsFromStoreAction
  implements Action {
  readonly type =
    BuildingsActions.DeleteBuildingVacantApartmentAutomationLeasingUalsFromStore;

  constructor(public payload?: any) {}
}

export class DeleteBuildingVacantApartmentAutomationOperationsUalsFromStoreAction
  implements Action {
  readonly type =
    BuildingsActions.DeleteBuildingVacantApartmentAutomationOperationsUalsFromStore;

  constructor(public payload?: any) {}
}

export type BuildingsActionsUnion =
  | GetBuildingsAction
  | GetBuildingsSuccessAction
  | UpdateBuildingsSearchQuery
  | GetBuildingIdAction
  | SetBuildingIdAction
  | GetBuildingAction
  | GetBuildingSuccessAction
  | CreateBuildingAction
  | DeleteBuildingFormStoreAction
  | SaveBuildingAction
  | SaveBuildingSuccessAction
  | SaveBuildingWithEnablingAutomationsAction
  | EnableAutomationsAction
  | DeleteBuildingAction
  | DeleteBuildingFromTableAction
  | EnableBuildingAction
  | DisableBuildingAction
  | OpenInfoModalAction
  | GetBuildingCommonAreaAccessDoorsAction
  | GetBuildingCommonAreaAccessDoorsSuccessAction
  | UpdateBuildingCommonAreaAccessAction
  | UpdateBuildingCommonAreaAccessDoorsSearchQueryAction
  | DeleteBuildingCommonAreaAccessDoorsFromStoreAction
  | GetBuildingCommonAreaAccessUalsAction
  | GetBuildingCommonAreaAccessUalsSuccessAction
  | UpdateBuildingCommonAreaAccessUalsAction
  | UpdateBuildingCommonAreaAccessUalsSearchQueryAction
  | DeleteBuildingCommonAreaAccessUalsFromStoreAction
  | GetBuildingVacantApartmentAutomationLeasingUalsAction
  | UpdateBuildingVacantApartmentAutomationLeasingUalsAction
  | UpdateBuildingVacantApartmentAutomationLeasingUalsSearchQueryAction
  | GetBuildingVacantApartmentAutomationLeasingUalsSuccessAction
  | GetBuildingVacantApartmentAutomationOperationsUalsAction
  | UpdateBuildingVacantApartmentAutomationOperationsUalsAction
  | UpdateBuildingVacantApartmentAutomationOperationsUalsSearchQueryAction
  | GetBuildingVacantApartmentAutomationOperationsUalsSuccessAction
  | UpdateBuildingVacantApartmentAutomationAction
  | DeleteBuildingVacantApartmentAutomationOperationsUalsFromStoreAction
  | DeleteBuildingVacantApartmentAutomationLeasingUalsFromStoreAction;
