import { ApiService } from '../api.service';
import * as i0 from "@angular/core";
import * as i1 from "../api.service";
export class AuthService {
    constructor(api) {
        this.api = api;
        this.login = (authData) => this.api.call('POST', `auth/signin`, authData);
        this.refreshToken = (data) => this.api.call('POST', 'auth/refresh', data);
        this.loginAzure = () => this.api.call('GET', `ldap/get-login-url`);
        this.redirectUrl = (url) => this.api.callShortUrl('GET', url);
        this.logOut = () => this.api.call('POST', `auth/signout`);
        this.getProfile = () => this.api.call('GET', 'profile');
        this.saveProfile = (user) => this.api.call('PUT', 'profile', user);
        this.saveAvatar = (data) => this.api.fileCall('PUT', 'profile/avatar', data);
        this.resetPassword = (oldPassword, newPassword) => this.api.call('PUT', 'profile/password', {
            password: newPassword,
            oldPassword: oldPassword
        });
        this.forgotPassword = (email) => this.api.call('POST', 'passwordrecovery', { email: email });
        this.passRecovery = (id) => this.api.call('GET', `passwordrecovery/is-recovery-requested?id=${id}`);
        this.passChange = (email, id, password) => this.api.call('POST', 'passwordrecovery/change', {
            email: email,
            id: id,
            password: password
        });
        this.exportList = (type, query, isMicro = false) => this.api.call2('GET', type, null, query, isMicro);
        this.upload = (body) => this.api.fileCall('POST', 'tenants/upload-manual', body);
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.ApiService)); }, token: AuthService, providedIn: "root" });
