import {Action} from '@ngrx/store';
import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';

export enum BuildingsGroupActions {
  GetBuildingsGroup = '[Buildings Group] Get Buildings Group',
  GetBuildingsGroupSuccess = '[Buildings Group] Get Buildings Group Success',
  UpdateBuildingsGroupSearchQuery = '[Buildings Group] Update Buildings Group Search Query',
  GetBuildingGroup = '[Buildings Group Item] Get Building Group',
  GetBuildingGroupSuccess = '[Buildings Group Item] Get Building Group Success',
  CreateBuildingGroup = '[Building Group Item] Create Building Group',
  DeleteBuildingGroupFromStore = '[Building Group Item] Delete Building Group Form Store',
  SaveBuildingGroup = '[Building Group Item] Save Building Group',
  DeleteBuildingGroup = '[Building Group Item] Delete Building Group',
  DeleteBuildingGroupFromTable = '[Building Group Item] Delete Building Group From Table',
  DisableBuildingGroup = '[Building Group Item] Disable Building Group',
  EnableBuildingGroup = '[Building Group Item] Enable Building Group',
  OpenInfoModal = '[Building Group Item] Open Info Modal'
}

export class GetBuildingsGroupAction implements Action {
  readonly type = BuildingsGroupActions.GetBuildingsGroup;

  constructor(public payload: QueryParamsInterface) {
  }
}

export class GetBuildingsGroupSuccessAction implements Action {
  readonly type = BuildingsGroupActions.GetBuildingsGroupSuccess;

  constructor(public payload: any) {
  }
}

export class UpdateBuildingsGroupSearchQuery implements Action {
  readonly type = BuildingsGroupActions.UpdateBuildingsGroupSearchQuery;

  constructor(public payload: QueryParamsInterface) {
  }
}

export class GetBuildingGroupAction implements Action {
  readonly type = BuildingsGroupActions.GetBuildingGroup;

  constructor(public payload: number) {
  }
}

export class GetBuildingGroupSuccessAction implements Action {
  readonly type = BuildingsGroupActions.GetBuildingGroupSuccess;

  constructor(public payload: any) {
  }
}

export class CreateBuildingGroupAction implements Action {
  readonly type = BuildingsGroupActions.CreateBuildingGroup;

  constructor(public payload: any) {
  }
}

export class DeleteBuildingGroupFormStoreAction implements Action {
  readonly type = BuildingsGroupActions.DeleteBuildingGroupFromStore;

  constructor(public payload?) {
  }
}

export class SaveBuildingGroupAction implements Action {
  readonly type = BuildingsGroupActions.SaveBuildingGroup;

  constructor(public payload: any) {
  }
}

export class DeleteBuildingGroupAction implements Action {
  readonly type = BuildingsGroupActions.DeleteBuildingGroup;

  constructor(public payload: number) {
  }
}

export class DeleteBuildingGroupFromTableAction implements Action {
  readonly type = BuildingsGroupActions.DeleteBuildingGroupFromTable;

  constructor(public payload: number) {
  }
}
export class DisableBuildingGroupAction implements Action {
  readonly type = BuildingsGroupActions.DisableBuildingGroup;

  constructor(public payload: number) {
  }
}
export class EnableBuildingGroupAction implements Action {
  readonly type = BuildingsGroupActions.EnableBuildingGroup;

  constructor(public payload: number) {
  }
}
export class OpenInfoModalAction implements Action {
  readonly type = BuildingsGroupActions.OpenInfoModal;

  constructor(public payload: {message: string, isOpen: boolean}) {
  }
}


export type BuildingsGroupActionsUnion =
  GetBuildingsGroupAction
  | GetBuildingsGroupSuccessAction
  | UpdateBuildingsGroupSearchQuery
  | GetBuildingGroupAction
  | GetBuildingGroupSuccessAction
  | CreateBuildingGroupAction
  | DeleteBuildingGroupFormStoreAction
  | SaveBuildingGroupAction
  | DeleteBuildingGroupAction
  | DeleteBuildingGroupFromTableAction
  | DisableBuildingGroupAction
  | EnableBuildingGroupAction
  | OpenInfoModalAction;
