import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {CompanyActions, CompanyActionsUnion} from '../actions/company.actions';
import {
  CompaniesRequestResponseInterface,
  CompanyInterface,
  StatusesRequestResponseInterface
} from '../../interfaces/company.interface';

export interface CompanyState {
  companies: CompaniesRequestResponseInterface;
  companiesSearchQuery: QueryParamsInterface;
  company: CompanyInterface;
  timezones: any;
  companyStatuses: StatusesRequestResponseInterface;
  success: boolean;
  companyApps: any;
}

const initialState: CompanyState = {
  company: null,
  companies: {companies: [], total: 0},
  companiesSearchQuery: null,
  timezones: [],
  companyStatuses: null,
  success: false,
  companyApps: []
};

export function companyReducer(
  state: CompanyState = initialState,
  action: CompanyActionsUnion
) {
  switch (action.type) {
    case CompanyActions.GetCompaniesSuccess: {
      return {
        ...state,
        companies: action.payload
      };
    }
    case CompanyActions.UpdateCompaniesSearchQueryRaw: {
      return {
        ...state,
        companiesSearchQuery: action.payload
      };
    }
    case CompanyActions.GetCompanySuccess: {
      return {
        ...state,
        company: action.payload
      };
    }
    case CompanyActions.DeleteCompanyFromStore: {
      return {
        ...state,
        company: null
      };
    }
    case CompanyActions.GetTimezonesSuccess: {
      return {
        ...state,
        timezones: action.payload
      };
    }
    case CompanyActions.GetCompanyStatusesSuccess: {
      return {
        ...state,
        companyStatuses: action.payload
      };
    }
    case CompanyActions.CreateCompanyStatusesSuccess: {
      return {
        ...state,
        success: action.payload
      };
    }
    case CompanyActions.DeleteFromStoreCompanyStatuses: {
      return {
        ...state,
        companyStatuses: null
      };
    }
    case CompanyActions.GetCompanyAppsSuccess: {
      return {
        ...state,
        companyApps: action.payload
      };
    }
    case CompanyActions.DeleteFromStoreCompanyApp: {
      return {
        ...state,
        companyApps: []
      };
    }
    default: {
      return state;
    }
  }
}
