<div class="auth">
  <div class="r-block">
    <strong class="logo">
      <a><i class="walkIn-i walkIn-i-logo"></i></a>
    </strong>
  </div>
  <div class="l-block">
    <div class="content">

      <form [formGroup]="form">
        <div class="form-group bg-white">
          <h1>New password</h1>
          <div class="field-box with-icon"
               [ngClass]="{
              'field-error': form.controls['newPass'].invalid && form.controls['newPass'].touched,
              'field-success': form.controls['newPass'].valid && form.controls['newPass'].touched
            }"
          >
            <p class="field-title">New password</p>
            <input type="password" placeholder="New password" formControlName="newPass">
            <i class="walkIn-i top10 walkIn-i-lock clr-yellow"></i>
            <p *ngIf="form.controls['newPass'].invalid && form.controls['newPass'].touched" class="field-state">
              Password is required!</p>
          </div>
          <div class="field-box with-icon"
               [ngClass]="{
              'field-error': form.controls['confirmPass'].invalid && form.controls['confirmPass'].touched,
              'field-success': form.controls['confirmPass'].valid && form.controls['confirmPass'].touched
            }"
          >
            <p class="field-title">Confirm password</p>
            <input type="password" placeholder="Confirm password" formControlName="confirmPass">
            <i class="walkIn-i top10 walkIn-i-lock clr-yellow"></i>
            <p *ngIf="form.controls['confirmPass'].invalid && form.controls['confirmPass'].touched" class="field-state">
              Password is required!</p>
          </div>

        </div>
        <div class="form-group bg-transparent">
          <button type="submit" class="btn btn-yellow" (click)="changePass()">
            <span>Change password</span>
          </button>
        </div>
      </form>
    </div>
  </div>

</div>
