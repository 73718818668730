import {Action} from '@ngrx/store';
import {ReadKeyRequestBody} from '../../interfaces/system-keys.interface';
import {CompanyQueryInterface} from "../../../residents/interfaces/tenants.interface";

export enum SystemKeysActions {
  GetEncoders = '[System Keys Read] GetEncoders',
  GetEncodersSuccess = '[System Keys Read] GetEncoders Success',
  ReadKey = '[System Keys Read] Read Key',
  ReadKeySuccess = '[System Keys Read] Read Key Success',
  DeleteKey = '[System Keys Delete] Delete Key',
}


export class GetEncodersAction implements Action {
  readonly type = SystemKeysActions.GetEncoders;

  constructor(public payload: CompanyQueryInterface) {
  }
}

export class GetEncodersSuccessAction implements Action {
  readonly type = SystemKeysActions.GetEncodersSuccess;

  constructor(public payload?) {
  }
}

export class ReadKeyAction implements Action {
  readonly type = SystemKeysActions.ReadKey;

  constructor(public payload: ReadKeyRequestBody) {
  }
}

export class ReadKeySuccessAction implements Action {
  readonly type = SystemKeysActions.ReadKeySuccess;

  constructor(public payload?) {
  }
}

export class DeleteKeyAction implements Action {
  readonly type = SystemKeysActions.DeleteKey;

  constructor(public payload: ReadKeyRequestBody) {
  }
}

export type SystemKeysActionsUnion = GetEncodersAction
  | GetEncodersSuccessAction
  | ReadKeyAction
  | ReadKeySuccessAction
  | DeleteKeyAction;
