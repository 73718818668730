import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth/auth.service';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-new-pass',
  templateUrl: './new-pass.component.html',
  styleUrls: ['./new-pass.component.sass']
})
export class NewPassComponent implements OnInit {

  public form: FormGroup;
  public url;
  public id;
  public item;
  public email;

  constructor(
    private router: Router,
    private auth: AuthService,
    private notifierService: NotifierService
  ) {
    this.form = new FormGroup({
      newPass: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPass: new FormControl('', [Validators.required, Validators.minLength(6)])
    });
  }

  ngOnInit() {
    this.url = this.router.parseUrl(this.router.url);
    if(this.url.queryParams && this.url.queryParams.id) {
      this.id = this.url.queryParams.id;
      this.auth.passRecovery(this.id).subscribe(item => {
        this.item = item;
        this.email = this.item.email;
      });
    }
  }

  changePass() {
    if (this.form.controls.newPass.value
      && this.form.controls.confirmPass.value
      && this.form.controls.newPass.value === this.form.controls.confirmPass.value) {
      this.auth.passChange(this.email, this.id, this.form.controls.newPass.value).subscribe(() => {
        this.notifierService.notify('success', 'Your password has been changed');
        window.location.replace(`${window.location.origin}/auth/signin`);
      });
    } else {
      this.notifierService.notify('error', 'New password not verified');
      this.form.reset();
      this.form.markAllAsTouched();
    }
  }
}

