<div class="auth">
  <div class="r-block">
    <strong class="logo">
      <a><img src="assets/images/icons/new_logo_login.svg" alt=""/></a>
    </strong>
  </div>
  <div class="l-block">
    <div class="content">
      <div style="padding: 0" class="form-group bg-white">
        <div class="ldap-spinner" *ngIf="spinner">
          <mat-progress-spinner class="green"
                                [mode]="mode"
                                diameter="60"
          ></mat-progress-spinner>
        </div>
      </div>
    </div>
  </div>
</div>
