import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UpdateDeleteFobStatusAction, UpdateSaveTenantResponseAction } from '../dashboard/residents/Store/actions/tenants.actions';
import { UpdateLoadingStatusAction } from '../Store/actions/profile.actions';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "angular-notifier";
import * as i3 from "@angular/router";
import * as i4 from "@ngrx/store";
const apiUrl = environment.apiUrl;
const apiUrlShort = environment.shortUrl;
const apiUrlAutomations = environment.automationsApiUrl;
const apiWhitelist = ['auth/signin', 'ldap/get-login-url', 'passwordrecovery'];
export class ApiService {
    constructor(http, notifierService, router, store, profileStore) {
        this.http = http;
        this.notifierService = notifierService;
        this.router = router;
        this.store = store;
        this.profileStore = profileStore;
        this.filterQueryParams = (query, isEncode) => {
            const newQuery = Object.assign({}, query);
            let params = new HttpParams();
            for (const key in newQuery) {
                if (Object.is(newQuery[key], NaN) || newQuery[key] === null) {
                    delete newQuery[key];
                }
                else if (typeof newQuery[key] === 'string' &&
                    newQuery[key].length === 0) {
                    delete newQuery[key];
                }
            }
            return newQuery;
        };
        this.call = (method, url, body, queryParams) => {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json'
            });
            queryParams = this.filterQueryParams(queryParams);
            return this.http
                .request(method, apiUrl + url, {
                body,
                headers,
                params: queryParams
            })
                .pipe(catchError((err) => {
                err.error.error.forEach((item) => {
                    this.notifierService.notify('error', item.message);
                    if (item.message === 'Sign in first') {
                        this.router.navigate(['auth/signin']);
                    }
                    if (item.message === 'Invalid token' ||
                        item.message === 'No token provided') {
                        this.router.navigate(['auth/signin']);
                    }
                });
                this.store.dispatch(new UpdateSaveTenantResponseAction(true));
                this.store.dispatch(new UpdateDeleteFobStatusAction(false));
                this.profileStore.dispatch(new UpdateLoadingStatusAction(false));
                return throwError(err);
            }));
        };
        this.callAutomation = (method, url, body, queryParams) => {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json'
            });
            queryParams = this.filterQueryParams(queryParams);
            return this.http
                .request(method, apiUrlAutomations + url, {
                body,
                headers,
                params: queryParams
            })
                .pipe(catchError((err) => {
                if (err && err.error && err.error.error && err.error.error.length) {
                    err.error.error.forEach((item) => {
                        this.notifierService.notify('error', item.message);
                        if (item.message === 'Sign in first') {
                            this.router.navigate(['auth/signin']);
                        }
                        if (item.message === 'Invalid token' ||
                            item.message === 'No token provided') {
                            this.router.navigate(['auth/signin']);
                        }
                    });
                }
                else if (err && err.error && err.error.message) {
                    this.notifierService.notify('error', err.error.message);
                }
                this.store.dispatch(new UpdateSaveTenantResponseAction(true));
                this.store.dispatch(new UpdateDeleteFobStatusAction(false));
                this.profileStore.dispatch(new UpdateLoadingStatusAction(false));
                return throwError(err);
            }));
        };
        this.callShortUrl = (method, url, body, queryParams) => {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json'
            });
            queryParams = this.filterQueryParams(queryParams);
            return this.http
                .request(method, apiUrlShort + url, {
                body,
                headers,
                params: queryParams
            })
                .pipe(catchError((err) => {
                err.error.error.forEach((item) => {
                    this.notifierService.notify('error', item.message);
                    if (item.message === 'Sign in first') {
                        this.router.navigate(['auth/signin']);
                    }
                    if (item.message === 'Invalid token' ||
                        item.message === 'No token provided') {
                        this.router.navigate(['auth/signin']);
                    }
                });
                return throwError(err);
            }));
        };
        this.callShortUrlWithHeaders = (method, url, body, queryParams) => {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json'
            });
            queryParams = this.filterQueryParams(queryParams, true);
            console.log(queryParams);
            return this.http
                .request(method, 'https://dev-api.walkdotin.com/' + url, {
                body,
                headers,
                params: queryParams,
                observe: 'response'
            })
                .pipe(catchError((err) => {
                err.error.error.forEach((item) => {
                    this.notifierService.notify('error', item.message);
                    if (item.message === 'Sign in first') {
                        this.router.navigate(['auth/signin']);
                    }
                    if (item.message === 'Invalid token' ||
                        item.message === 'No token provided') {
                        this.router.navigate(['auth/signin']);
                    }
                });
                return throwError(err);
            }));
        };
        this.call2 = (method, url, body, queryParams, isMicro = false) => {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json'
            });
            queryParams = this.filterQueryParams(queryParams);
            return this.http
                .request(method, `${apiUrl}${url}`, {
                body,
                headers,
                params: queryParams,
                responseType: 'blob'
            })
                .pipe(catchError((err) => {
                err.error.error.forEach((item) => {
                    this.notifierService.notify('error', item.message);
                    if (item.message === 'Sign in first') {
                        this.router.navigate(['auth/signin']);
                    }
                    if (item.message === 'Invalid token' ||
                        item.message === 'No token provided') {
                        this.router.navigate(['auth/signin']);
                    }
                });
                return throwError(err);
            }));
        };
        this.fileCall = (method, url, body) => {
            return this.http
                .request(method, apiUrl + url, {
                body
            })
                .pipe(catchError((err) => {
                err.error.error.forEach((item) => {
                    this.notifierService.notify('error', item.message);
                    if (item.message === 'Invalid token' ||
                        item.message === 'No token provided') {
                        this.router.navigate(['auth/signin']);
                    }
                });
                return throwError(err);
            }));
        };
        this.addCompanyToQueryParams = (query) => {
            let resultQuery = query;
            this.profileStore.select('profile').subscribe(({ profile }) => {
                if (profile.company > 0) {
                    resultQuery = Object.assign({ company: profile.company }, resultQuery);
                }
            });
            return resultQuery;
        };
        this.refreshToken = () => {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json'
            });
            return this.http.request('POST', apiUrl + 'auth/refresh', {
                headers,
                body: { refreshToken: localStorage.getItem('refresh_token') }
            });
        };
    }
}
ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.NotifierService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.Store), i0.ɵɵinject(i4.Store)); }, token: ApiService, providedIn: "root" });
