import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {
  DoorsActions,
  DoorsActionsUnion,
  GetDoorsIglooAction
} from '../actions/doors.actions';
import {
  DoorInterface,
  DoorsRequestResponseInterface,
  IglooDoorsRequestResponseInterface
} from '../../interfaces/doorInterface';

export interface DoorsState {
  doors: DoorsRequestResponseInterface;
  door: DoorInterface;
  doorsSearchQuery: QueryParamsInterface;
  doorsIgloo: IglooDoorsRequestResponseInterface;
  doorIgloo: DoorInterface;
  doorsIglooSearchQuery: QueryParamsInterface;
}

const initialState: DoorsState = {
  doors: {doors: [], total: 0},
  door: null,
  doorsSearchQuery: null,
  doorsIgloo: {iglooLocks: [], total: 0},
  doorIgloo: null,
  doorsIglooSearchQuery: null
};

export function doorsReducer(
  state: DoorsState = initialState,
  action: DoorsActionsUnion
) {
  switch (action.type) {
    case DoorsActions.GetDoorsSuccess: {
      return {
        ...state,
        doors: action.payload
      };
    }
    case DoorsActions.UpdateDoorsSearchQuery: {
      return {
        ...state,
        doorsSearchQuery: {
          ...state.doorsSearchQuery,
          ...action.payload
        }
      };
    }
    case DoorsActions.GetDoorSuccess: {
      return {
        ...state,
        door: action.payload
      };
    }
    case DoorsActions.DeleteDoorFromStore: {
      return {
        ...state,
        door: null
      };
    }
    // IGLOO
    case DoorsActions.GetDoorsIglooSuccess: {
      return {
        ...state,
        doorsIgloo: action.payload
      };
    }
    case DoorsActions.UpdateDoorsIglooSearchQuery: {
      return {
        ...state,
        doorsIglooSearchQuery: {
          ...state.doorsIglooSearchQuery,
          ...action.payload
        }
      };
    }
    case DoorsActions.GetDoorIglooSuccess: {
      return {
        ...state,
        doorIgloo: action.payload
      };
    }
    case DoorsActions.DeleteDoorIglooFromStore: {
      return {
        ...state,
        doorIgloo: null
      };
    }
    default: {
      return state;
    }
  }
}
