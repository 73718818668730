import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {AutomationsLogsStepsModalOptionsInterface} from '../../interfaces/modal.interface';
import {ModalService} from '../../services/modal.service';
import {Store} from '@ngrx/store';
import {dashboardSystemAutomationLogsStepsDataSelectUrl} from '../../../dashboard/system/constants/constants';
import {AutomationStepsInterface} from '../../../dashboard/system/interfaces/automations-logs.interface';
import {AutomationsLogsState} from '../../../dashboard/system/Store/reducers/automations-logs.reducer';
import {DeleteAutomationStepsFromStoreAction} from '../../../dashboard/system/Store/actions/automations-logs.actions';

@Component({
  selector: 'app-automation-report-modal',
  templateUrl: './automation-report-modal.component.html',
  styleUrls: ['./automation-report-modal.component.sass']
})
export class AutomationsLogsStepsModalComponent implements OnInit, OnDestroy {
  public events: Subject<any> = new Subject<any>();
  public options: AutomationsLogsStepsModalOptionsInterface;
  public modalService: ModalService;
  public steps: AutomationStepsInterface[];

  constructor(private store: Store<AutomationsLogsState>) {}

  ngOnInit() {
    this.store
      .select(...dashboardSystemAutomationLogsStepsDataSelectUrl)
      .subscribe((steps: AutomationStepsInterface[]) => {
        if (steps.length) {
          this.steps = steps;
        }
      });
  }

  ngOnDestroy(): void {
    this.events = null;
  }

  public cancel = () => {
    this.events.next(false);
    this.modalService.closeModal();
    this.store.dispatch(new DeleteAutomationStepsFromStoreAction());
  };

  public confirm = () => {
    this.events.next(true);
    this.modalService.closeModal();
  };
}
