import {Action} from '@ngrx/store';
import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {
  DoorInterface,
  DoorsRequestResponseInterface,
  IglooDoorsRequestResponseInterface,
  SaveDoorInterface
} from "../../interfaces/doorInterface";

export enum DoorsActions {
  GetDoors = '[Doors] Get Doors',
  GetDoorsSuccess = '[Doors] Get Doors Success',
  UpdateDoorsSearchQuery = '[Doors] Update Doors Search Query',
  GetDoor = '[Doors Item] Get Door',
  GetDoorSuccess = '[Doors Item] Get Door Success',
  CreateDoor = '[Door Item] Create Door',
  DeleteDoorFromStore = '[Door Item] Delete Door Form Store',
  SaveDoor = '[Door Item] Save Door',
  DeleteDoor = '[Door Item] Delete Door',
  DeleteAccessDoor = '[Door Item] Delete Access Door',

  GetDoorsIgloo = '[Doors Igloo] Get Doors Igloo',
  GetDoorsIglooSuccess = '[Doors Igloo] Get Doors Igloo Success',
  UpdateDoorsIglooSearchQuery = '[Doors Igloo] Update Doors Igloo Search Query',
  GetDoorIgloo = '[Doors Igloo Item] Get Door Igloo',
  GetDoorIglooSuccess = '[Doors Igloo Item] Get Door Igloo Success',
  UpdateDoorIglooSearchQuery = '[Doors Igloo Item] Update Door Igloo Search Query',
  CreateDoorIgloo = '[Door Igloo Item] Create Door Igloo',
  DeleteDoorIglooFromStore = '[Door Igloo Item] Delete Door Igloo Form Store',
  SaveDoorIgloo = '[Door Igloo Item] Save Door Igloo',
  DeleteDoorIgloo = '[Door Igloo Item] Delete Door Igloo',
  DeleteAccessUserDoorIgloo = '[Door Igloo Item] Delete Access User Door Igloo',
  DeleteAccessDoorIgloo = '[Door Igloo Item] Delete Access Door Igloo',
}

export class GetDoorsAction implements Action {
  readonly type = DoorsActions.GetDoors;

  constructor(public payload: QueryParamsInterface) {
  }
}

export class GetDoorsSuccessAction implements Action {
  readonly type = DoorsActions.GetDoorsSuccess;

  constructor(public payload: DoorsRequestResponseInterface) {
  }
}

export class UpdateDoorsSearchQuery implements Action {
  readonly type = DoorsActions.UpdateDoorsSearchQuery;

  constructor(public payload: QueryParamsInterface) {
  }
}

export class GetDoorAction implements Action {
  readonly type = DoorsActions.GetDoor;

  constructor(public payload: number) {
  }
}

export class GetDoorSuccessAction implements Action {
  readonly type = DoorsActions.GetDoorSuccess;

  constructor(public payload: DoorInterface) {
  }
}

export class CreateDoorAction implements Action {
  readonly type = DoorsActions.CreateDoor;

  constructor(public payload: DoorInterface) {
  }
}

export class DeleteDoorFormStoreAction implements Action {
  readonly type = DoorsActions.DeleteDoorFromStore;

  constructor(public payload?) {
  }
}

export class SaveDoorAction implements Action {
  readonly type = DoorsActions.SaveDoor;

  constructor(public payload: SaveDoorInterface) {
  }
}

export class DeleteDoorAction implements Action {
  readonly type = DoorsActions.DeleteDoor;

  constructor(public payload: number) {
  }
}

export class DeleteAccessDoorAction implements Action {
  readonly type = DoorsActions.DeleteAccessDoor;

  constructor(public payload: any) {
  }
}



// IGLOO DOORS
export class GetDoorsIglooAction implements Action {
  readonly type = DoorsActions.GetDoorsIgloo;

  constructor(public payload: QueryParamsInterface) {
  }
}

export class GetDoorsIglooSuccessAction implements Action {
  readonly type = DoorsActions.GetDoorsIglooSuccess;

  constructor(public payload: IglooDoorsRequestResponseInterface) {
  }
}

export class UpdateDoorsIglooSearchQuery implements Action {
  readonly type = DoorsActions.UpdateDoorsIglooSearchQuery;

  constructor(public payload: QueryParamsInterface) {
  }
}

export class GetDoorIglooAction implements Action {
  readonly type = DoorsActions.GetDoorIgloo;

  constructor(public payload: number) {
  }
}

export class GetDoorIglooSuccessAction implements Action {
  readonly type = DoorsActions.GetDoorIglooSuccess;

  constructor(public payload: DoorInterface) {
  }
}

export class CreateDoorIglooAction implements Action {
  readonly type = DoorsActions.CreateDoorIgloo;

  constructor(public payload: DoorInterface) {
  }
}

export class DeleteDoorIglooFormStoreAction implements Action {
  readonly type = DoorsActions.DeleteDoorIglooFromStore;

  constructor(public payload?) {
  }
}

export class SaveDoorIglooAction implements Action {
  readonly type = DoorsActions.SaveDoorIgloo;

  constructor(public payload: SaveDoorInterface) {
  }
}

export class DeleteDoorIglooAction implements Action {
  readonly type = DoorsActions.DeleteDoorIgloo;

  constructor(public payload: number) {
  }
}

export class DeleteAccessUserDoorIglooAction implements Action {
  readonly type = DoorsActions.DeleteAccessUserDoorIgloo;

  constructor(public payload: any) {
  }
}

export class DeleteAccessDoorIglooAction implements Action {
  readonly type = DoorsActions.DeleteAccessDoorIgloo;

  constructor(public payload: any) {
  }
}

export type DoorsActionsUnion =
  GetDoorsAction
  | GetDoorsSuccessAction
  | UpdateDoorsSearchQuery
  | GetDoorAction
  | GetDoorSuccessAction
  | CreateDoorAction
  | DeleteDoorFormStoreAction
  | SaveDoorAction
  | DeleteDoorAction
  | DeleteAccessDoorAction
  | GetDoorsIglooAction
  | GetDoorsIglooSuccessAction
  | UpdateDoorsIglooSearchQuery
  | GetDoorIglooAction
  | GetDoorIglooSuccessAction
  | CreateDoorIglooAction
  | DeleteDoorIglooFormStoreAction
  | SaveDoorIglooAction
  | DeleteAccessUserDoorIglooAction
  | DeleteAccessDoorIglooAction
  | DeleteDoorIglooAction;
