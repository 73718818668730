import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginRoutingModule} from './login-routing.module';
import {LoginComponent} from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NewPassComponent } from './forgot-password/new-pass/new-pass.component';
import {SignFirstComponent} from './sign-first/sign-first.component';
import { AppleAppSiteAssociationComponent } from './apple-app-site-association/apple-app-site-association.component';
import { LdapComponent } from './ldap/ldap.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    NewPassComponent,
    SignFirstComponent,
    AppleAppSiteAssociationComponent,
    LdapComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoginRoutingModule,
    MatProgressSpinnerModule
  ],
  providers: []
})
export class LoginModule {
}
