import {TimetableInterface, TimetableRequestResponseInterface} from '../../interfaces/timetable.interface';
import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {TimeTablesActions, TimeTablesActionsUnion} from '../actions/timeTables.actions';
import {TenantsActions} from '../../../residents/Store/actions/tenants.actions';

export interface TimeTablesState {
  timetables: TimetableRequestResponseInterface;
  timetable: TimetableInterface;
  timetablesSearchQuery: QueryParamsInterface;
}

const initialState: TimeTablesState = {
  timetable: null,
  timetables: null,
  timetablesSearchQuery: null
};

export function timetablesReducer(state: TimeTablesState = initialState, action: TimeTablesActionsUnion) {
  switch (action.type) {
    case TimeTablesActions.GetTimatablesSuccess: {
      return {
        ...state,
        timetables: action.payload
      };
    }
    case TimeTablesActions.GetTimatableSuccess: {
      return {
        ...state,
        timetable: action.payload
      };
    }
    case TimeTablesActions.UpdateTimetablesSearchQuery: {
      return {
        ...state,
        timetablesSearchQuery: {
          ...state.timetablesSearchQuery,
          ...action.payload
        }
      };
    }
    default: {
      return state;
    }
  }
}
