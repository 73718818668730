import {
  AccessAutomationsTableInterface,
  AccessAutomationsRequestResponseInterface,
  AccessAutomationOperandValueInterface,
  AccessAutomationActionInterface,
  ElementInterface,
  AccessAutomationCommandsInterface,
  AccessAutomationOperandsInterface,
  AccessAutomationInterface, BuildingCommonAreaInterface, UalsInterface
} from "../../interfaces/access-automations.interface";
import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {
  AccessAutomationsActions,
  AccessAutomationsActionsUnion,
  UpdateExpressionOperatorSuccessAction
} from '../actions/access-automations.actions';
import {accessAutomationOperand as operandConst} from '../../constants/constants';

export interface AccessAutomationsState {
  accessAutomations: AccessAutomationsRequestResponseInterface;
  accessAutomationsSearchQuery: QueryParamsInterface;
  accessAutomation: AccessAutomationInterface;
  accessAutomationLoading: boolean;

  accessAutomationResidentsOperandValues: AccessAutomationOperandValueInterface[];
  accessAutomationApartmentsOperandValues: AccessAutomationOperandValueInterface[];
  accessAutomationMoveInOperandValues: AccessAutomationOperandValueInterface[];
  accessAutomationMoveOutOperandValues: AccessAutomationOperandValueInterface[];

  connectOperandValueLoading: boolean;
  disconnectOperandValueLoading: boolean;

  enableStatus: boolean;

  allOperands: AccessAutomationOperandsInterface[];

  allActions: ElementInterface[];
  currentAction: AccessAutomationActionInterface;
  availableCommandsList: AccessAutomationCommandsInterface[];

  buildingCommonArea: BuildingCommonAreaInterface,
  leasingUals: UalsInterface,
  vacancyUals: UalsInterface
}

const initialState: AccessAutomationsState = {
  accessAutomations: {
    data: [],
    total: 0
  },
  accessAutomationsSearchQuery: null,
  accessAutomation: null,
  accessAutomationLoading: false,
  accessAutomationResidentsOperandValues: [],
  accessAutomationApartmentsOperandValues: [],
  accessAutomationMoveInOperandValues: [],
  accessAutomationMoveOutOperandValues: [],

  connectOperandValueLoading: false,
  disconnectOperandValueLoading: false,
  enableStatus: false,

  allOperands: [],

  allActions: [],
  currentAction: null,
  availableCommandsList: [],

  buildingCommonArea: null,
  leasingUals: null,
  vacancyUals: null
};

export function accessAutomationsReducer(
  state: AccessAutomationsState = initialState,
  action: AccessAutomationsActionsUnion
) {
  switch (action.type) {
    case AccessAutomationsActions.GetAccessAutomationsSuccess: {
      return {
        ...state,
        accessAutomations: action.payload
      };
    }
    case AccessAutomationsActions.UpdateAccessAutomationsSearchQuery: {
      return {
        ...state,
        accessAutomationsSearchQuery: {
          ...state.accessAutomationsSearchQuery,
          ...action.payload
        }
      };
    }
    case AccessAutomationsActions.GetAccessAutomation: {
      return {
        ...state,
        accessAutomationLoading: true
      };
    }
    case AccessAutomationsActions.GetAccessAutomationSuccess: {
      return {
        ...state,
        accessAutomation: action.payload,
        accessAutomationLoading: false
      };
    }
    case AccessAutomationsActions.GetAccessAutomationResidentsOperandValuesSuccess: {
      return {
        ...state,
        accessAutomationResidentsOperandValues: action.payload
      };
    }
    case AccessAutomationsActions.GetAccessAutomationApartmentsOperandValuesSuccess: {
      return {
        ...state,
        accessAutomationApartmentsOperandValues: action.payload
      };
    }
    case AccessAutomationsActions.GetAccessAutomationMoveInOperandValuesSuccess: {
      return {
        ...state,
        accessAutomationMoveInOperandValues: action.payload
      };
    }
    case AccessAutomationsActions.GetAccessAutomationMoveOutOperandValuesSuccess: {
      return {
        ...state,
        accessAutomationMoveOutOperandValues: action.payload
      };
    }

    case AccessAutomationsActions.GetAccessAutomationAllActionsSuccess: {
      return {
        ...state,
        allActions: action.payload
      };
    }
    case AccessAutomationsActions.GetAccessAutomationActionSuccess: {
      return {
        ...state,
        currentAction: action.payload,
        availableCommandsList: action.payload.commands
      };
    }
    case AccessAutomationsActions.GetAccessAutomationAllOperandsSuccess: {
      return {
        ...state,
        allOperands: action.payload
      };
    }
    case AccessAutomationsActions.DeleteAccessAutomationFromStore: {
      return {
        ...state,
        accessAutomation: null,
        accessAutomationResidentsOperandValues: [],
        accessAutomationApartmentsOperandValues: [],
        accessAutomationMoveInOperandValues: [],
        accessAutomationMoveOutOperandValues: [],

        allOperands: [],
        allActions: [],
        currentAction: null,
        availableCommandsList: []
      };
    }
    case AccessAutomationsActions.ConnectAccessAutomationOperandValue: {
      return {
        ...state,
        connectOperandValueLoading: true
      };
    }
    case AccessAutomationsActions.ConnectAccessAutomationOperandValueSuccess: {
      return {
        ...state,
        connectOperandValueLoading: false
      };
    }
    case AccessAutomationsActions.AddNewOperandValue: {
      const currentExpression = state.accessAutomation.expressions.find(
        (el) => el.id === action.payload.expressionId
      );

      if (action.payload.operandValue.id === 0) {
        currentExpression.operandValues.push(action.payload.operandValue);
      } else {
        currentExpression.operandValues.push(action.payload.operandValue);
        currentExpression.operandValues = currentExpression.operandValues.filter(
          (el) => el.id !== 0
        );
      }
      const clone = state.accessAutomation;
      return {
        ...state,
        accessAutomation: {
          ...clone
        }
      };
    }
    case AccessAutomationsActions.RemoveOperandValue: {
      const currentExpression = state.accessAutomation.expressions.find(
        (el) => el.id === action.payload.expressionId
      );
      currentExpression.operandValues = currentExpression.operandValues.filter(
        (el) => el.id !== action.payload.operandValue.id && el.id !== 0
      );
      if (
        (!currentExpression.operandValues.length &&
          (action.payload.operandId === operandConst['RESIDENTS_STATUS'] ||
        action.payload.operandId === operandConst['APARTMENTS_STATUS'])
      )) {
        currentExpression.operandValues.push({
          id: 0,
          operandId: 0,
          value: 'N/A'
        });
      }
      // state.accessAutomation.expressions = state.accessAutomation.expressions.filter(el => el.operandValues.length);
      const clone = state.accessAutomation;
      return {
        ...state,
        accessAutomation: {
          ...clone
        }
      };
    }
    case AccessAutomationsActions.DisconnectAccessAutomationOperandValue: {
      return {
        ...state,
        disconnectOperandValueLoading: true
      };
    }
    case AccessAutomationsActions.DisconnectAccessAutomationOperandValueSuccess: {
      return {
        ...state,
        disconnectOperandValueLoading: false
      };
    }
    case AccessAutomationsActions.UpdateExpressionOperatorSuccess: {
      const index = state.accessAutomation.expressions.findIndex(
        (el) => el.id === action.payload.id
      );
      state.accessAutomation.expressions[index].operator =
        action.payload.operator;
      const clone = state.accessAutomation;

      return {
        ...state,
        accessAutomation: clone
      };
    }
    case AccessAutomationsActions.DeleteExpressionSuccess: {
      state.accessAutomation.expressions = state.accessAutomation.expressions.filter(
        (el) => el.operandId !== action.payload
      );
      const clone = state.accessAutomation;
      return {
        ...state,
        accessAutomation: {...clone}
      };
    }
    case AccessAutomationsActions.CreateExpressionSuccess: {
      state.accessAutomation.expressions.push({
        ...action.payload,
        operandValues: []
      });
      const clone = state.accessAutomation;
      return {
        ...state,
        accessAutomation: {...clone}
      };
    }
    case AccessAutomationsActions.GetAccessAutomationBuildingCommonAreaSuccess: {
      return {
        ...state,
        buildingCommonArea: action.payload
      }
    }
    case AccessAutomationsActions.GetAccessAutomationLeasingUalsSuccess: {
      return {
        ...state,
        leasingUals: action.payload
      }
    }
    case AccessAutomationsActions.GetAccessAutomationVacancyUalsSuccess: {
      return {
        ...state,
        vacancyUals: action.payload
      }
    }
    case AccessAutomationsActions.EnableAccessAutomationSuccess: {
      return {
        ...state,
        enableStatus: true
      }
    }
    default: {
      return state;
    }
  }
}
