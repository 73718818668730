import {Action} from '@ngrx/store';
import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {SaveUserInterface, UserInterface, UserRequestResponseInterface} from '../../interfaces/user.interface';

export enum UsersActions {
  GetUsers = '[Users] Get Users',
  GetUsersSuccess = '[Users] Get Users Success',
  UpdateUsersSearchQueryRaw = '[Users] Update Search Query Raw',
  GetUser = '[User] Get User',
  GetUserSuccess = '[User] Get User Success',
  DeleteUser = '[User] Delete User',
  DisableUser = '[User] Disable User',
  EnableUser = '[User] Enable User',
  DeleteUserFromStore = '[User] Delete User From Store',
  SaveUser = '[User] Save User',
  CreateUser = '[User] Create User',
  GetCompany = '[User] Get Company',
  GetCompanySuccess = '[User] Get Company Success'
}

export class GetCompanyAction implements Action {
  public type = UsersActions.GetCompany;

  constructor(public payload) {
  }
}

export class GetCompanySuccessAction implements Action {
  public type = UsersActions.GetCompanySuccess;

  constructor(public payload) {
  }
}


export class GetUsersAction implements Action {
  public type = UsersActions.GetUsers;

  constructor(public payload) {
  }
}

export class GetUsersSuccessAction implements Action {
  public type = UsersActions.GetUsersSuccess;

  constructor(public payload: UserRequestResponseInterface) {
  }
}

export class UpdateUsersSearchQueryRawAction {
  type = UsersActions.UpdateUsersSearchQueryRaw;

  constructor(public payload: QueryParamsInterface) {
  }
}

export class GetUserAction {
  type = UsersActions.GetUser;

  constructor(public payload: number) {
  }
}

export class GetUserSuccessAction {
  type = UsersActions.GetUserSuccess;

  constructor(public payload: UserInterface) {
  }
}

export class DeleteUserAction {
  type = UsersActions.DeleteUser;

  constructor(public payload: number) {
  }
}

export class DeleteUserFromStoreAction {
  type = UsersActions.DeleteUserFromStore;

  constructor(public payload?) {
  }
}

export class DisableUserAction {
  type = UsersActions.DisableUser;

  constructor(public payload: number) {
  }
}

export class EnableUserAction {
  type = UsersActions.EnableUser;

  constructor(public payload: number) {
  }
}

export class SaveUserAction {
  type = UsersActions.SaveUser;

  constructor(public payload: SaveUserInterface) {
  }
}

export class CreateUserAction {
  type = UsersActions.CreateUser;

  constructor(public payload: any) {
  }
}

export type UsersActionsUnion =
  GetUsersAction
  | GetUsersSuccessAction
  | UpdateUsersSearchQueryRawAction
  | GetUserAction
  | GetUserSuccessAction
  | DeleteUserFromStoreAction
  | DisableUserAction
  | EnableUserAction
  | SaveUserAction
  | CreateUserAction
  | GetCompanyAction
  | GetCompanySuccessAction;
