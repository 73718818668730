<div class="auth" *ngIf="!isForgotPass">
  <div class="r-block">
    <div class="logo">
      <a><img src="assets/images/icons/new_logo_login.svg" alt=""/></a>
    </div>
  </div>
  <div class="l-block">
    <div *ngIf="formContent" class="content">

      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group bg-white">
          <div (click)="changeFormContent()" class="back"><i class="walkIn-i walkIn-i-arrow"></i>Back</div>
          <div class="field-box with-icon"
               [ngClass]="{
              'field-error': form.controls['email'].invalid && form.controls['email'].touched,
              'field-success': form.controls['email'].valid && form.controls['email'].touched
            }"
          >
            <p class="field-title">Email</p>
            <input type="text" placeholder="Email" formControlName="email">
            <i class="walkIn-i top10 walkIn-i-user clr-green"></i>
            <p *ngIf="form.controls['email'].invalid && form.controls['email'].touched" class="field-state">
              Email is required!</p>
          </div>
          <div class="field-box with-icon"
               [ngClass]="{
              'field-error': form.controls['password'].invalid && form.controls['password'].touched,
              'field-success': form.controls['password'].valid && form.controls['password'].touched
            }"
          >
            <p class="field-title">Password</p>
            <input type="password" placeholder="Password" formControlName="password">
            <i class="walkIn-i top10 walkIn-i-lock clr-green"></i>
            <p *ngIf="form.controls['password'].invalid && form.controls['password'].touched" class="field-state">
              Password is required!</p>
          </div>
          <div class="sign-in-box">
            <div class="field-box">
              <input id="remember" type="checkbox" class="checkbox-lg checkbox-green" formControlName="remember">
              <label for="remember">
                <span class="check"></span>
                <span class="check-txt">Remember me</span>
              </label>
            </div>
            <div routerLink="/auth/forgot-password">
              <span class="forgot-pass">Forgot password?</span>
              <!--<a (click)="changePage()">
                <span class="forgot-pass">Forgot password?</span>
              </a>-->

            </div>
          </div>

        </div>
        <div class="form-group bg-transparent button-group">
          <button type="submit" class="btn btn-green">
            <span>Sign in</span>
          </button>
        </div>

      </form>
    </div>
    <div *ngIf="!formContent" class="content">
      <div class="form-group bg-white bigPd">
        <div class="form-group bg-transparent button-group">
          <button type="button" (click)="loginAzure()" class="btn btn-green">
            <span>Sign In</span>
          </button>
          <button style="margin-top: 20px" type="button" (click)="changeFormContent()" class="btn btn-grey">
            <span>Local Sign In</span>
          </button>
        </div>
      </div>
      <div class="form-group bg-transparent button-group local">
      </div>
    </div>
  </div>

</div>

<div class="auth" *ngIf="isForgotPass">
  <div class="r-block">
    <strong class="logo">
      <a><i class="walkIn-i walkIn-i-logo"></i></a>
    </strong>
  </div>
  <div class="l-block">
    <div class="content">

      <form [formGroup]="forgotPassForm" *ngIf="!success">
        <div class="form-group bg-white">
          <span (click)="changeFormContent()" class="back"><i class="walkIn-i walkIn-i-arrow"></i>Back</span>
          <h1>Forgot password</h1>
          <div class="field-box with-icon"
               [ngClass]="{
              'field-error': forgotPassForm.controls['email'].invalid && forgotPassForm.controls['email'].touched,
              'field-success': forgotPassForm.controls['email'].valid && forgotPassForm.controls['email'].touched
            }"
          >
            <p class="field-title">Email</p>
            <input type="text" placeholder="Email" formControlName="email">
            <i class="walkIn-i top10 walkIn-i-user clr-yellow"></i>
            <p *ngIf="forgotPassForm.controls['email'].invalid && forgotPassForm.controls['email'].touched" class="field-state">
              Email is required!</p>
          </div>
          <!-- <div class="field-box with-icon"
                [ngClass]="{
               'field-error': form.controls['password'].invalid && form.controls['password'].touched,
               'field-success': form.controls['password'].valid && form.controls['password'].touched
             }"
           >
             <input type="password" placeholder="Password" formControlName="password">
             <i class="walkIn-i walkIn-i-lock clr-yellow"></i>
             <p *ngIf="form.controls['password'].invalid && form.controls['password'].touched" class="field-state">
               Password is required!</p>
           </div>-->

        </div>
        <div class="form-group bg-transparent">
          <button type="button" class="btn btn-yellow" (click)="sendEmail()">
            <span>Send email</span>
          </button>
        </div>
      </form>
      <div class="form-group bg-white" style="display: flex; justify-content: center; align-items: center" *ngIf="success">
        <h2 style="text-align: center">Thank you!
          An email has been sent with further instructions. If you haven't received it, please check your spam folder.
        </h2>
      </div>
    </div>
  </div>

</div>

<!--<div class="auth" *ngIf="!isForgotPass">
  <div class="r-block">
    <strong class="logo">
      <a><i class="walkIn-i walkIn-i-logo"></i></a>
    </strong>
  </div>
  <div class="l-block">
    <div *ngIf="!ldap" class="content">

      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group bg-white">
          <h1>Sign in</h1>
          <div class="field-box with-icon"
               [ngClass]="{
              'field-error': form.controls['email'].invalid && form.controls['email'].touched,
              'field-success': form.controls['email'].valid && form.controls['email'].touched
            }"
          >
            <input type="text" placeholder="Email" formControlName="email">
            <i class="walkIn-i walkIn-i-user clr-yellow"></i>
            <p *ngIf="form.controls['email'].invalid && form.controls['email'].touched" class="field-state">
              Email is required!</p>
          </div>
          <div class="field-box with-icon"
               [ngClass]="{
              'field-error': form.controls['password'].invalid && form.controls['password'].touched,
              'field-success': form.controls['password'].valid && form.controls['password'].touched
            }"
          >
            <input type="password" placeholder="Password" formControlName="password">
            <i class="walkIn-i walkIn-i-lock clr-yellow"></i>
            <p *ngIf="form.controls['password'].invalid && form.controls['password'].touched" class="field-state">
              Password is required!</p>
          </div>
          <div class="sign-in-box">
            <div class="field-box">
              <input id="remember" type="checkbox" class="checkbox-lg checkbox-auth" formControlName="remember">
              <label for="remember">
                <span class="check"></span>
                <span class="check-txt">Remember me</span>
              </label>
            </div>
            <div routerLink="/auth/forgot-password">
              <span class="forgot-pass">Forgot password?</span>
              &lt;!&ndash;<a (click)="changePage()">
                <span class="forgot-pass">Forgot password?</span>
              </a>&ndash;&gt;

            </div>
          </div>

        </div>
        <div class="form-group bg-transparent button-group">
          <button type="submit" class="btn btn-yellow">
            <span>Sign in</span>
          </button>
          &lt;!&ndash;<button type="button" (click)="loginAzure()" class="btn btn-yellow">
            <span>Login with Azure</span>
          </button>&ndash;&gt;
        </div>

      </form>
    </div>

    <div *ngIf="ldap" class="content">

      <form>
        <div class="form-group bg-white">

        </div>
        <div class="form-group bg-transparent button-group">
          &lt;!&ndash;<button type="submit" class="btn btn-yellow">
            <span>Sign in</span>
          </button>&ndash;&gt;
          <button type="button" (click)="loginAzure()" class="btn btn-yellow">
            <span>Login with Azure</span>
          </button>
        </div>

      </form>
    </div>
  </div>

</div>

<div class="auth" *ngIf="isForgotPass">
  <div class="r-block">
    <strong class="logo">
      <a><i class="walkIn-i walkIn-i-logo"></i></a>
    </strong>
  </div>
  <div class="l-block">
    <div class="content">

      <form [formGroup]="forgotPassForm" *ngIf="!success">
        <div class="form-group bg-white">
          <h1>Forgot password</h1>
          <div class="field-box with-icon"
               [ngClass]="{
              'field-error': forgotPassForm.controls['email'].invalid && forgotPassForm.controls['email'].touched,
              'field-success': forgotPassForm.controls['email'].valid && forgotPassForm.controls['email'].touched
            }"
          >
            <input type="text" placeholder="Email" formControlName="email">
            <i class="walkIn-i walkIn-i-user clr-yellow"></i>
            <p *ngIf="forgotPassForm.controls['email'].invalid && forgotPassForm.controls['email'].touched" class="field-state">
              Email is required!</p>
          </div>
          &lt;!&ndash; <div class="field-box with-icon"
                [ngClass]="{
               'field-error': form.controls['password'].invalid && form.controls['password'].touched,
               'field-success': form.controls['password'].valid && form.controls['password'].touched
             }"
           >
             <input type="password" placeholder="Password" formControlName="password">
             <i class="walkIn-i walkIn-i-lock clr-yellow"></i>
             <p *ngIf="form.controls['password'].invalid && form.controls['password'].touched" class="field-state">
               Password is required!</p>
           </div>&ndash;&gt;

        </div>
        <div class="form-group bg-transparent">
          <button type="button" class="btn btn-yellow" (click)="sendEmail()">
            <span>Send email</span>
          </button>
        </div>
      </form>
      <div class="form-group bg-white" style="display: flex; justify-content: center; align-items: center" *ngIf="success">
        <h2 style="text-align: center">Thank you!
          An email has been sent with further instructions. If you haven't received it, please check your spam folder.
        </h2>
      </div>
    </div>
  </div>

</div>-->
