import { ApiService } from '../../../services/api.service';
import { AccessAutomationExpressionInterface, SaveAccessAutomationDataInterface } from '../interfaces/access-automations.interface';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/api.service";
export class AccessAutomationsService {
    constructor(api) {
        this.api = api;
        this.getAccessAutomations = (query) => this.api.callAutomation('GET', 'automations', null, query);
        this.getAccessAutomation = (id) => this.api.callAutomation('GET', `automations/${id}`);
        this.createAccessAutomation = (accessAutomation) => this.api.callAutomation('POST', 'automations', accessAutomation);
        this.updateAccessAutomation = (accessAutomation, id) => this.api.callAutomation('PATCH', `automations/${id}`, accessAutomation);
        this.deleteAccessAutomation = (id) => this.api.callAutomation('DELETE', `automations/${id}`);
        this.enableAccessAutomation = (id, data) => this.api.callAutomation('PUT', `automations/${id}/status`, {
            status: data
        });
        this.enableDisableAccessAutomation = (id, data) => this.api.callAutomation('PUT', `automations/${id}/status`, {
            status: data
        });
        this.secondEnableAutomation = (data) => this.api.callAutomation('POST', 'automations/default ', data);
        this.disableAccessAutomation = (id) => this.api.callAutomation('PUT', `automations/${id}/disable`);
        this.getOperandValues = (operandId, companyId) => this.api.callAutomation('GET', 'operand_values', null, {
            operandId,
            companyId
        });
        this.getAllOperands = (companyId) => this.api.callAutomation('GET', 'operands', null, {
            companyId
        });
        this.getAllActions = () => this.api.callAutomation('GET', 'actions');
        this.getAction = (id) => this.api.callAutomation('GET', `actions/${id}`);
        this.expressionsValueConnect = (expressionId, valueId) => this.api.callAutomation('POST', `expressions/${expressionId}/value/${valueId}/connect`);
        this.expressionsValueDisconnect = (expressionId, valueId) => this.api.callAutomation('DELETE', `expressions/${expressionId}/value/${valueId}/disconnect`);
        this.updateExpressions = (expressionId, body) => this.api.callAutomation('PATCH', `expressions/${expressionId}`, body);
        this.deleteExpression = (id) => this.api.callAutomation('DELETE', `expressions/${id}`);
        this.createExpression = (body) => this.api.callAutomation('POST', 'expressions', body);
        this.getCommonAreaAccessDoors = ({ buildingId, query }) => this.api.call('GET', `building/${buildingId}/common-area-access/doors`, null, query);
        this.getCommonAreaAccessUals = ({ buildingId, query }) => this.api.call('GET', `building/${buildingId}/common-area-access/uals`, null, query);
    }
}
AccessAutomationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccessAutomationsService_Factory() { return new AccessAutomationsService(i0.ɵɵinject(i1.ApiService)); }, token: AccessAutomationsService, providedIn: "root" });
