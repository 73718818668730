import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';
import {NotifierService} from 'angular-notifier';
import {Store} from '@ngrx/store';
import {ProfileInterface} from '../../dashboard/interfaces/profile.interface';

@Component({
  selector: 'app-sign-first',
  templateUrl: './sign-first.component.html',
  styleUrls: ['./sign-first.component.sass']
})
export class SignFirstComponent implements OnInit {

  public form: FormGroup;
  public url;
  public id;
  public item;
  public email;
  public user: ProfileInterface;


  constructor(
    private store: Store<any>,
    private router: Router,
    private auth: AuthService,
    private notifierService: NotifierService,
  ) {
    this.form = new FormGroup({
      oldPass: new FormControl('', [Validators.required]),
      newPass: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPass: new FormControl('', [Validators.required, Validators.minLength(6)])
    });
  }


  ngOnInit() {
    this.url = this.router.parseUrl(this.router.url);
    if(this.url.queryParams && this.url.queryParams.id) {
      this.id = this.url.queryParams.id;
      this.auth.passRecovery(this.id).subscribe(item => {
        this.item = item;
        this.email = this.item.email;
      });
    }
    history.pushState(null, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
  }

  changePass() {
    if (this.form.controls.oldPass.value
      && this.form.controls.newPass.value
      && this.form.controls.confirmPass.value) {
      if (this.form.controls.oldPass.value === this.form.controls.newPass.value) {
        this.notifierService.notify('error', 'The new password must differ from your previous password');
        this.form.reset();
        this.form.markAllAsTouched();
      } else if (this.form.controls.newPass.value === this.form.controls.confirmPass.value) {
        this.auth.resetPassword(this.form.controls.oldPass.value, this.form.controls.newPass.value)
          .subscribe(() => {
            this.notifierService.notify('success', 'Your password has been changed');
            this.store.select('profile').subscribe(({profile}: any) => {
              this.user = profile;
              this.email = this.user.email;
            })
            this.router.navigate(['/dashboard']);
          });
      } else {
        this.notifierService.notify('error', 'New password not verified')
        this.form.reset();
      }
    } else {
      this.form.markAllAsTouched();
      this.form.reset();
    }
  }
}

