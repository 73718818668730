import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {
  ZoneInterface,
  ZonesRequestResponseInterface
} from '../../interfaces/zones.interface';
import {ZonesActions, ZonesActionsUnion} from '../actions/zones.actions';
import {
  TenantsItemAvailableDoorsRequestResponseInterface,
  TenantsItemAvailableUalsRequestResponseInterface
} from '../../../residents/interfaces/tenants.interface';

export interface ZonesState {
  zones: ZonesRequestResponseInterface;
  zonesSearchQuery: QueryParamsInterface;
  zone: ZoneInterface;
  zoneAvailableDoors: TenantsItemAvailableDoorsRequestResponseInterface;
  zoneAvailableDoorsSearchQuery: QueryParamsInterface;
  zoneAvailableDoorsAll: TenantsItemAvailableDoorsRequestResponseInterface;
  zoneAvailableDoorsAllSearchQuery: QueryParamsInterface;
  zoneAvailableUals: TenantsItemAvailableUalsRequestResponseInterface;
  zoneAvailableUalsSearchQuery: QueryParamsInterface;
  zoneAvailableUalsAll: TenantsItemAvailableUalsRequestResponseInterface;
  zoneAvailableUalsAllSearchQuery: QueryParamsInterface;
}

const initialState: ZonesState = {
  zone: null,
  zones: {zones: [], total: 0},
  zonesSearchQuery: null,
  zoneAvailableDoors: null,
  zoneAvailableDoorsSearchQuery: null,
  zoneAvailableDoorsAll: null,
  zoneAvailableDoorsAllSearchQuery: null,
  zoneAvailableUals: null,
  zoneAvailableUalsSearchQuery: null,
  zoneAvailableUalsAll: null,
  zoneAvailableUalsAllSearchQuery: null
};

export function zonesReducer(
  state: ZonesState = initialState,
  action: ZonesActionsUnion
) {
  switch (action.type) {
    case ZonesActions.GetZonesSuccess: {
      return {
        ...state,
        zones: action.payload
      };
    }
    case ZonesActions.UpdateZonesSearchQueryRaw: {
      return {
        ...state,
        zonesSearchQuery: action.payload
      };
    }
    case ZonesActions.GetZoneSuccess: {
      return {
        ...state,
        zone: action.payload
      };
    }
    case ZonesActions.DeleteZoneFromStore: {
      return {
        ...state,
        zone: null
      };
    }

    case ZonesActions.GetZoneAvailableDoorsSuccess: {
      return {
        ...state,
        zoneAvailableDoors: action.payload
      };
    }
    case ZonesActions.UpdateZoneAvailableDoorsSearchQuery: {
      return {
        ...state,
        zoneAvailableDoorsSearchQuery: {
          ...state.zoneAvailableDoorsSearchQuery,
          ...action.payload
        }
      };
    }

    case ZonesActions.DeleteFromStoreAvailableDoors: {
      return {
        ...state,
        zoneAvailableDoors: null
      };
    }

    case ZonesActions.GetZoneAvailableDoorsAllSuccess: {
      return {
        ...state,
        zoneAvailableDoorsAll: action.payload
      };
    }
    case ZonesActions.UpdateZoneAvailableDoorsAllSearchQuery: {
      return {
        ...state,
        zoneAvailableDoorsAllSearchQuery: {
          ...state.zoneAvailableDoorsAllSearchQuery,
          ...action.payload
        }
      };
    }

    case ZonesActions.DeleteFromStoreAvailableDoorsAll: {
      return {
        ...state,
        zoneAvailableDoorsAll: null
      };
    }

    case ZonesActions.GetZoneAvailableUalsSuccess: {
      return {
        ...state,
        zoneAvailableUals: action.payload
      };
    }
    case ZonesActions.UpdateZoneAvailableUalsSearchQuery: {
      return {
        ...state,
        zoneAvailableUalsSearchQuery: {
          ...state.zoneAvailableUalsSearchQuery,
          ...action.payload
        }
      };
    }

    case ZonesActions.DeleteFromStoreAvailableUals: {
      return {
        ...state,
        zoneAvailableUals: null
      };
    }

    case ZonesActions.GetZoneAvailableUalsAllSuccess: {
      return {
        ...state,
        zoneAvailableUalsAll: action.payload
      };
    }
    case ZonesActions.UpdateZoneAvailableUalsAllSearchQuery: {
      return {
        ...state,
        zoneAvailableUalsAllSearchQuery: {
          ...state.zoneAvailableUalsAllSearchQuery,
          ...action.payload
        }
      };
    }

    case ZonesActions.DeleteFromStoreAvailableUalsAll: {
      return {
        ...state,
        zoneAvailableUalsAll: null
      };
    }
    default: {
      return state;
    }
  }
}
