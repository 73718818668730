import * as i0 from "./apple-app-site-association.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./apple-app-site-association.component";
import * as i3 from "@angular/common/http";
var styles_AppleAppSiteAssociationComponent = [i0.styles];
var RenderType_AppleAppSiteAssociationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppleAppSiteAssociationComponent, data: {} });
export { RenderType_AppleAppSiteAssociationComponent as RenderType_AppleAppSiteAssociationComponent };
export function View_AppleAppSiteAssociationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pre", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["  ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); }); }
export function View_AppleAppSiteAssociationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-apple-app-site-association", [], null, null, null, View_AppleAppSiteAssociationComponent_0, RenderType_AppleAppSiteAssociationComponent)), i1.ɵdid(1, 114688, null, 0, i2.AppleAppSiteAssociationComponent, [i3.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppleAppSiteAssociationComponentNgFactory = i1.ɵccf("app-apple-app-site-association", i2.AppleAppSiteAssociationComponent, View_AppleAppSiteAssociationComponent_Host_0, {}, {}, []);
export { AppleAppSiteAssociationComponentNgFactory as AppleAppSiteAssociationComponentNgFactory };
