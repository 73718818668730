import { AccessAutomationsActions } from '../actions/access-automations.actions';
import { accessAutomationOperand as operandConst } from '../../constants/constants';
const ɵ0 = [];
const initialState = {
    accessAutomations: {
        data: ɵ0,
        total: 0
    },
    accessAutomationsSearchQuery: null,
    accessAutomation: null,
    accessAutomationLoading: false,
    accessAutomationResidentsOperandValues: [],
    accessAutomationApartmentsOperandValues: [],
    accessAutomationMoveInOperandValues: [],
    accessAutomationMoveOutOperandValues: [],
    connectOperandValueLoading: false,
    disconnectOperandValueLoading: false,
    enableStatus: false,
    allOperands: [],
    allActions: [],
    currentAction: null,
    availableCommandsList: [],
    buildingCommonArea: null,
    leasingUals: null,
    vacancyUals: null
};
export function accessAutomationsReducer(state = initialState, action) {
    switch (action.type) {
        case AccessAutomationsActions.GetAccessAutomationsSuccess: {
            return Object.assign({}, state, { accessAutomations: action.payload });
        }
        case AccessAutomationsActions.UpdateAccessAutomationsSearchQuery: {
            return Object.assign({}, state, { accessAutomationsSearchQuery: Object.assign({}, state.accessAutomationsSearchQuery, action.payload) });
        }
        case AccessAutomationsActions.GetAccessAutomation: {
            return Object.assign({}, state, { accessAutomationLoading: true });
        }
        case AccessAutomationsActions.GetAccessAutomationSuccess: {
            return Object.assign({}, state, { accessAutomation: action.payload, accessAutomationLoading: false });
        }
        case AccessAutomationsActions.GetAccessAutomationResidentsOperandValuesSuccess: {
            return Object.assign({}, state, { accessAutomationResidentsOperandValues: action.payload });
        }
        case AccessAutomationsActions.GetAccessAutomationApartmentsOperandValuesSuccess: {
            return Object.assign({}, state, { accessAutomationApartmentsOperandValues: action.payload });
        }
        case AccessAutomationsActions.GetAccessAutomationMoveInOperandValuesSuccess: {
            return Object.assign({}, state, { accessAutomationMoveInOperandValues: action.payload });
        }
        case AccessAutomationsActions.GetAccessAutomationMoveOutOperandValuesSuccess: {
            return Object.assign({}, state, { accessAutomationMoveOutOperandValues: action.payload });
        }
        case AccessAutomationsActions.GetAccessAutomationAllActionsSuccess: {
            return Object.assign({}, state, { allActions: action.payload });
        }
        case AccessAutomationsActions.GetAccessAutomationActionSuccess: {
            return Object.assign({}, state, { currentAction: action.payload, availableCommandsList: action.payload.commands });
        }
        case AccessAutomationsActions.GetAccessAutomationAllOperandsSuccess: {
            return Object.assign({}, state, { allOperands: action.payload });
        }
        case AccessAutomationsActions.DeleteAccessAutomationFromStore: {
            return Object.assign({}, state, { accessAutomation: null, accessAutomationResidentsOperandValues: [], accessAutomationApartmentsOperandValues: [], accessAutomationMoveInOperandValues: [], accessAutomationMoveOutOperandValues: [], allOperands: [], allActions: [], currentAction: null, availableCommandsList: [] });
        }
        case AccessAutomationsActions.ConnectAccessAutomationOperandValue: {
            return Object.assign({}, state, { connectOperandValueLoading: true });
        }
        case AccessAutomationsActions.ConnectAccessAutomationOperandValueSuccess: {
            return Object.assign({}, state, { connectOperandValueLoading: false });
        }
        case AccessAutomationsActions.AddNewOperandValue: {
            const currentExpression = state.accessAutomation.expressions.find((el) => el.id === action.payload.expressionId);
            if (action.payload.operandValue.id === 0) {
                currentExpression.operandValues.push(action.payload.operandValue);
            }
            else {
                currentExpression.operandValues.push(action.payload.operandValue);
                currentExpression.operandValues = currentExpression.operandValues.filter((el) => el.id !== 0);
            }
            const clone = state.accessAutomation;
            return Object.assign({}, state, { accessAutomation: Object.assign({}, clone) });
        }
        case AccessAutomationsActions.RemoveOperandValue: {
            const currentExpression = state.accessAutomation.expressions.find((el) => el.id === action.payload.expressionId);
            currentExpression.operandValues = currentExpression.operandValues.filter((el) => el.id !== action.payload.operandValue.id && el.id !== 0);
            if ((!currentExpression.operandValues.length &&
                (action.payload.operandId === operandConst['RESIDENTS_STATUS'] ||
                    action.payload.operandId === operandConst['APARTMENTS_STATUS']))) {
                currentExpression.operandValues.push({
                    id: 0,
                    operandId: 0,
                    value: 'N/A'
                });
            }
            const clone = state.accessAutomation;
            return Object.assign({}, state, { accessAutomation: Object.assign({}, clone) });
        }
        case AccessAutomationsActions.DisconnectAccessAutomationOperandValue: {
            return Object.assign({}, state, { disconnectOperandValueLoading: true });
        }
        case AccessAutomationsActions.DisconnectAccessAutomationOperandValueSuccess: {
            return Object.assign({}, state, { disconnectOperandValueLoading: false });
        }
        case AccessAutomationsActions.UpdateExpressionOperatorSuccess: {
            const index = state.accessAutomation.expressions.findIndex((el) => el.id === action.payload.id);
            state.accessAutomation.expressions[index].operator =
                action.payload.operator;
            const clone = state.accessAutomation;
            return Object.assign({}, state, { accessAutomation: clone });
        }
        case AccessAutomationsActions.DeleteExpressionSuccess: {
            state.accessAutomation.expressions = state.accessAutomation.expressions.filter((el) => el.operandId !== action.payload);
            const clone = state.accessAutomation;
            return Object.assign({}, state, { accessAutomation: Object.assign({}, clone) });
        }
        case AccessAutomationsActions.CreateExpressionSuccess: {
            state.accessAutomation.expressions.push(Object.assign({}, action.payload, { operandValues: [] }));
            const clone = state.accessAutomation;
            return Object.assign({}, state, { accessAutomation: Object.assign({}, clone) });
        }
        case AccessAutomationsActions.GetAccessAutomationBuildingCommonAreaSuccess: {
            return Object.assign({}, state, { buildingCommonArea: action.payload });
        }
        case AccessAutomationsActions.GetAccessAutomationLeasingUalsSuccess: {
            return Object.assign({}, state, { leasingUals: action.payload });
        }
        case AccessAutomationsActions.GetAccessAutomationVacancyUalsSuccess: {
            return Object.assign({}, state, { vacancyUals: action.payload });
        }
        case AccessAutomationsActions.EnableAccessAutomationSuccess: {
            return Object.assign({}, state, { enableStatus: true });
        }
        default: {
            return state;
        }
    }
}
export { ɵ0 };
