import {Action} from '@ngrx/store';
import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {
  CompanyQueryInterface,
  GenerateKeyActionPayloadInterface,
  SaveTenantInterface,
  TaggedTenantAuditTrailsRequestResponseInterface,
  TaggedTenantsRequestResponseInterface,
  TaggedVisitorLogsRequestResponseInterface,
  TenantItemInterface,
  TenantNoteInterface,
  TenantsItemActivityLogsRequestResponseInterface,
  TenantsItemAvailableDoorsRequestResponseInterface,
  TenantsItemAvailableUalsRequestResponseInterface,
  TenantsItemAvailableZonesRequestResponseInterface,
  TenantsItemVisitorsListRequestResponseInterface,
  TenantsRequestResponseInterface
} from '../../interfaces/tenants.interface';

export enum TenantsActions {
  GetTenants = '[Residents Tenants] Get Tenants',
  GetTenantsSuccess = '[Residents Tenants] Get Tenants Success',
  UpdateTenantsSearchQuery = '[Residents Tenants] Update Tenants Search Query',
  GetTenant = '[Residents Tenants Item] Get Tenant',
  GetTenantSuccess = '[Residents Tenants Item] Get Tenant Success',
  SaveTenant = '[Residents Tenants Item] Save Tenant',
  UpdateSaveTenantResponse = '[Residents Tenants Item] Update Save Tenant Response',
  GetTenantNote = '[Residents Tenants Item] Get Tenant Note',
  GetTenantNoteSuccess = '[Residents Tenants Item] Get Tenant Note Success',
  SaveTenantNote = '[Residents Tenants Item] Save Tenant Note',
  CreateTenant = '[Residents Tenants Item] Create Tenant',
  DisableTenant = '[Residents Tenants Item] Disable Tenant',
  BanTenant = '[Residents Tenants Item] Ban Tenant',
  UnBanTenant = '[Residents Tenants Item] UnBan Tenant',
  DeleteTenant = '[Residents Tenants Item] Delete Tenant',
  TotallyDeleteTenant = '[Residents Tenants Item] Totally Delete Tenant',
  EnableTenant = '[Residents Tenants Item] Enable Tenant',
  DeleteTenantFromStore = '[Residents Tenants Item] Delete Tenant From Store',
  GetTenantActivityLogs = '[Residents Tenants Item] Get Tenant Activity Logs',
  GetTenantActivityLogsSuccess = '[Residents Tenants Item] Get Tenant Activity Logs Success',
  UpdateTenantActivityLogsSearchQuery = '[Residents Tenants Item] Update Search Query',
  GetEncoders = '[Residents Tenants Item] Get Encoders',
  GetEncodersSuccess = '[Residents Tenants Item] Get Encoders Success',
  GenerateKey = '[Residents Tenants Item] Generate Key',
  DeleteKey = '[Residents Tenants Item] Delete Key',
  GetTaggedTenants = '[Residents Dashboard] Get Tagged Tenants',
  GetTaggedTenantsSuccess = '[Residents Dashboard] Get Tagged Tenants Success',
  UpdateTaggedTenantsSearchQueryAction = '[Residents Dashboard] Update Tagged Tenants Search Query',
  GetTaggedTenantAuditTrails = '[Residents Dashboard] Get Tagged Tenant Audit Trails',
  GetTaggedTenantAuditTrailsSuccess = '[Residents Dashboard] Get Tagged Tenant Audit Trails Success',
  GetTaggedVisitorLogs = '[Residents Dashboard] Get Tagged Visitor Logs',
  GetTaggedVisitorLogsSuccess = '[Residents Dashboard] Get Tagged Visitor Logs Success',
  GetTenantAvailableDoors = '[Residents Tenants Item] Get Tenant Available Doors',
  GetTenantAvailableDoorsSuccess = '[Residents Tenants Item] Get Tenant Available Doors Success',
  UpdateTenantAvailableDoorsSearchQuery = '[Residents Tenants Item] Update Tenant Available Doors Search Query',
  DeleteFromStoreAvailableDoors = '[Residents Tenants Item] Delete From Store Available Doors',
  AssignmentTenantAvailableDoor = '[Residents Tenants Item] Assignment Tenant Available Doors',
  RemovalTenantAvailableDoor = '[Residents Tenants Item] Removal Tenant Available Doors',
  GetTenantVisitorsList = '[Residents Tenants Item] Get Tenant Visitors List',
  GetTenantVisitorsListSuccess = '[Residents Tenants Item] Get Tenant Visitors List Success',
  UpdateTenantVisitorsListSearchQuery = '[Residents Tenants Item] Update Visitors List Search Query',

  GetTenantAvailableLobby = '[Residents Tenants Item] Get Tenant Available Lobby',
  GetTenantFirstLobbyList = '[Residents Tenants Item] Get Tenant First Lobby List',
  GetTenantSecondLobbyList = '[Residents Tenants Item] Get Tenant Second Lobby List',
  GetTenantAvailableLobbySuccess = '[Residents Tenants Item] Get Tenant Available Lobby Success',
  GetTenantFirstLobbyListSuccess = '[Residents Tenants Item] Get Tenant First Lobby List Success',
  GetTenantSecondLobbyListSuccess = '[Residents Tenants Item] Get Tenant Second Lobby List Success',
  UpdateTenantAvailableLobbySearchQuery = '[Residents Tenants Item] Update Tenant Available Lobby Search Query',
  UpdateTenantFirstLobbyListSearchQuery = '[Residents Tenants Item] Update Tenant First Lobby List Search Query',
  UpdateTenantSecondLobbyListSearchQuery = '[Residents Tenants Item] Update Tenant Second Lobby List Search Query',
  DeleteFromStoreAvailableLobby = '[Residents Tenants Item] Delete From Store Available Lobby',

  GetTenantAvailableUals = '[Residents Tenants Item] Get Tenant Available Uals',
  GetTenantAvailableUalsSuccess = '[Residents Tenants Item] Get Tenant Available Uals Success',
  UpdateTenantAvailableUalsSearchQuery = '[Residents Tenants Item] Update Tenant Available Uals Search Query',
  DeleteFromStoreAvailableUals = '[Residents Tenants Item] Delete From Store Available Uals',
  AssignmentTenantAvailableUal = '[Residents Tenants Item] Assignment Tenant Available Uals',
  RemovalTenantAvailableUal = '[Residents Tenants Item] Removal Tenant Available Uals',

  GetTenantAvailableLobbyUals = '[Residents Tenants Item] Get Tenant Available Lobby Uals',
  GetTenantAvailableLobbyUalsSuccess = '[Residents Tenants Item] Get Tenant Available Lobby Uals Success',
  UpdateTenantAvailableLobbyUalsSearchQuery = '[Residents Tenants Item] Update Tenant Available Lobby Uals Search Query',
  DeleteFromStoreAvailableLobbyUals = '[Residents Tenants Item] Delete From Store Available Lobby Uals',
  // RemovalTenantAvailableUal = '[Residents Tenants Item] Removal Tenant Available Uals',

  GetTenantAvailableStickerUals = '[Residents Tenants Item] Get Tenant Available Sticker Uals',
  GetTenantAvailableStickerUalsSuccess = '[Residents Tenants Item] Get Tenant Available Sticker Uals Success',
  UpdateTenantAvailableStickerUalsSearchQuery = '[Residents Tenants Item] Update Tenant Available Sticker Uals Search Query',
  DeleteFromStoreAvailableStickerUals = '[Residents Tenants Item] Delete From Store Available Sticker Uals',
  AssignmentTenantAvailableStickerUal = '[Residents Tenants Item] Assignment Tenant Available Sticker Uals',
  RemovalTenantAvailableStickerUal = '[Residents Tenants Item] Removal Tenant Available Sticker Uals',

  GetTenantAvailableApartments = '[Residents Tenants Item] Get Tenant Available Apartments',
  GetTenantAvailableApartmentsSuccess = '[Residents Tenants Item] Get Tenant Available Apartments Success',
  UpdateTenantAvailableApartmentsSearchQuery = '[Residents Tenants Item] Update Tenant Available Apartments Search Query',

  GetTenantAvailableZones = '[Residents Tenants Item] Get Tenant Available Zones',
  GetTenantAvailableZonesSuccess = '[Residents Tenants Item] Get Tenant Available Zones Success',
  UpdateTenantAvailableZonesSearchQuery = '[Residents Tenants Item] Update Tenant Available Zones Search Query',
  DeleteFromStoreAvailableZones = '[Residents Tenants Item] Delete From Store Available Zones',
  AssignmentTenantAvailableZone = '[Residents Tenants Item] Assignment Tenant Available Zones',
  RemovalTenantAvailableZone = '[Residents Tenants Item] Removal Tenant Available Zones',

  GetTenantAvailableStickerZones = '[Residents Tenants Item] Get Tenant Available Sticker Zones',
  GetTenantAvailableStickerZonesSuccess = '[Residents Tenants Item] Get Tenant Available Sticker Zones Success',
  UpdateTenantAvailableStickerZonesSearchQuery = '[Residents Tenants Item] Update Tenant Available Sticker Zones Search Query',
  DeleteFromStoreAvailableStickerZones = '[Residents Tenants Item] Delete From Store Available Sticker Zones',
  AssignmentTenantAvailableStickerZone = '[Residents Tenants Item] Assignment Tenant Available Sticker Zones',
  RemovalTenantAvailableStickerZone = '[Residents Tenants Item] Removal Tenant Available Sticker Zones',

  ExpireFob = '[Residents Tenants Item] Expire Fob',
  ExpireFobSuccess = '[Residents Tenants Item] Expire Fob Success',
  DeleteFobKey = '[Residents Tenants Item] Delete Fob Key',
  DeleteFobKeySuccess = '[Residents Tenants Item] Delete Fob Key Success',
  DeleteFobUser = '[Residents Tenants Item] Delete Fob User',
  DeleteFobUserSuccess = '[Residents Tenants Item] Delete Fob User Success',

  UpdateDeleteFobStatus = '[Residents Tenants Item] Update Delete Fob Status',
  ClearPopupData = '[Residents Tenants Item] Clear Popup Data'
}

export class GetTenantsAction implements Action {
  readonly type = TenantsActions.GetTenants;

  constructor(public payload: QueryParamsInterface) {}
}

export class GetTenantsSuccessAction implements Action {
  readonly type = TenantsActions.GetTenantsSuccess;

  constructor(public payload: TenantsRequestResponseInterface) {}
}

export class UpdateTenantsSearchQueryAction implements Action {
  readonly type = TenantsActions.UpdateTenantsSearchQuery;

  constructor(public payload: QueryParamsInterface) {}
}

export class GetTenantAction implements Action {
  readonly type = TenantsActions.GetTenant;

  constructor(public payload: number) {}
}

export class GetTenantSuccessAction implements Action {
  readonly type = TenantsActions.GetTenantSuccess;

  constructor(public payload: TenantItemInterface) {}
}

export class SaveTenantAction implements Action {
  readonly type = TenantsActions.SaveTenant;

  constructor(public payload: SaveTenantInterface) {}
}

export class UpdateSaveTenantResponseAction implements Action {
  readonly type = TenantsActions.UpdateSaveTenantResponse;

  constructor(public payload: boolean) {}
}

export class GetTenantNoteAction implements Action {
  readonly type = TenantsActions.GetTenantNote;

  constructor(public payload: number) {}
}

export class GetTenantNoteSuccessAction implements Action {
  readonly type = TenantsActions.GetTenantNoteSuccess;

  constructor(public payload: TenantNoteInterface) {}
}

export class SaveTenantNoteAction implements Action {
  readonly type = TenantsActions.SaveTenantNote;

  constructor(public payload: TenantNoteInterface) {}
}

export class CreateTenantAction implements Action {
  readonly type = TenantsActions.CreateTenant;

  constructor(public payload: any) {}
}

export class DeleteTenantAction implements Action {
  readonly type = TenantsActions.DeleteTenant;

  constructor(public payload: number) {}
}

export class TotallyDeleteTenantAction implements Action {
  readonly type = TenantsActions.TotallyDeleteTenant;

  constructor(public payload: number) {}
}

export class DisableTenantAction implements Action {
  readonly type = TenantsActions.DisableTenant;

  constructor(public payload: number) {}
}

export class EnableTenantAction implements Action {
  readonly type = TenantsActions.EnableTenant;

  constructor(public payload: number) {}
}

export class BanTenantAction implements Action {
  readonly type = TenantsActions.BanTenant;

  constructor(public payload: number) {}
}

export class UnBanTenantAction implements Action {
  readonly type = TenantsActions.UnBanTenant;

  constructor(public payload: number) {}
}

export class DeleteTenantFormStoreAction implements Action {
  readonly type = TenantsActions.DeleteTenantFromStore;

  constructor(public payload?) {}
}

export class GetTenantAvailableLobbyAction implements Action {
  readonly type: string = TenantsActions.GetTenantAvailableLobby;

  constructor(public payload: any) {}
}
export class GetTenantFirstLobbyListAction implements Action {
  readonly type: string = TenantsActions.GetTenantFirstLobbyList;

  constructor(public payload: any) {}
}
export class GetTenantSecondLobbyListAction implements Action {
  readonly type: string = TenantsActions.GetTenantSecondLobbyList;

  constructor(public payload: any) {}
}

export class DeleteFromStoreResidentsAvailableLobby implements Action {
  readonly type: string = TenantsActions.DeleteFromStoreAvailableLobby;

  constructor(public payload?) {}
}

export class GetTenantAvailableLobbySuccessAction implements Action {
  readonly type: string = TenantsActions.GetTenantAvailableLobbySuccess;

  constructor(
    public payload: TenantsItemAvailableDoorsRequestResponseInterface
  ) {}
}

export class GetTenantFirstLobbyListSuccessAction implements Action {
  readonly type: string = TenantsActions.GetTenantFirstLobbyListSuccess;

  constructor(
    public payload: TenantsItemAvailableDoorsRequestResponseInterface
  ) {}
}

export class GetTenantSecondLobbyListSuccessAction implements Action {
  readonly type: string = TenantsActions.GetTenantSecondLobbyListSuccess;

  constructor(
    public payload: TenantsItemAvailableDoorsRequestResponseInterface
  ) {}
}

export class UpdateTenantAvailableLobbySearchQueryAction implements Action {
  readonly type: string = TenantsActions.UpdateTenantAvailableLobbySearchQuery;

  constructor(public payload: QueryParamsInterface) {}
}
export class UpdateTenantFirstLobbyListSearchQueryAction implements Action {
  readonly type: string = TenantsActions.UpdateTenantFirstLobbyListSearchQuery;

  constructor(public payload: QueryParamsInterface) {}
}
export class UpdateTenantSecondLobbyListSearchQueryAction implements Action {
  readonly type: string = TenantsActions.UpdateTenantSecondLobbyListSearchQuery;

  constructor(public payload: QueryParamsInterface) {}
}

export class GetTenantAvailableUalsAction implements Action {
  readonly type: string = TenantsActions.GetTenantAvailableUals;

  constructor(public payload: any) {}
}

export class GetTenantAvailableLobbyUalsAction implements Action {
  readonly type: string = TenantsActions.GetTenantAvailableLobbyUals;

  constructor(public payload: any) {}
}

export class DeleteFromStoreResidentsAvailableUals implements Action {
  readonly type: string = TenantsActions.DeleteFromStoreAvailableUals;

  constructor(public payload?) {}
}

export class DeleteFromStoreResidentsAvailableLobbyUals implements Action {
  readonly type: string = TenantsActions.DeleteFromStoreAvailableLobbyUals;

  constructor(public payload?) {}
}

export class GetTenantAvailableUalsSuccessAction implements Action {
  readonly type: string = TenantsActions.GetTenantAvailableUalsSuccess;

  constructor(
    public payload: TenantsItemAvailableUalsRequestResponseInterface
  ) {}
}

export class GetTenantAvailableLobbyUalsSuccessAction implements Action {
  readonly type: string = TenantsActions.GetTenantAvailableLobbyUalsSuccess;

  constructor(
    public payload: TenantsItemAvailableUalsRequestResponseInterface
  ) {}
}

export class UpdateTenantAvailableUalsSearchQueryAction implements Action {
  readonly type: string = TenantsActions.UpdateTenantAvailableUalsSearchQuery;

  constructor(public payload: QueryParamsInterface) {}
}
export class UpdateTenantAvailableLobbyUalsSearchQueryAction implements Action {
  readonly type: string =
    TenantsActions.UpdateTenantAvailableLobbyUalsSearchQuery;

  constructor(public payload: QueryParamsInterface) {}
}

export class AssignmentTenantAvailableUalAction implements Action {
  readonly type: string = TenantsActions.AssignmentTenantAvailableUal;

  constructor(public payload: any) {}
}

export class RemovalTenantAvailableUalAction implements Action {
  readonly type: string = TenantsActions.RemovalTenantAvailableUal;

  constructor(public payload: any) {}
}

export class GetTenantAvailableStickerUalsAction implements Action {
  readonly type: string = TenantsActions.GetTenantAvailableStickerUals;

  constructor(public payload: any) {}
}

export class DeleteFromStoreResidentsAvailableStickerUals implements Action {
  readonly type: string = TenantsActions.DeleteFromStoreAvailableStickerUals;

  constructor(public payload?) {}
}

export class GetTenantAvailableStickerUalsSuccessAction implements Action {
  readonly type: string = TenantsActions.GetTenantAvailableStickerUalsSuccess;

  constructor(
    public payload: TenantsItemAvailableUalsRequestResponseInterface
  ) {}
}

export class UpdateTenantAvailableStickerUalsSearchQueryAction
  implements Action {
  readonly type: string =
    TenantsActions.UpdateTenantAvailableStickerUalsSearchQuery;

  constructor(public payload: QueryParamsInterface) {}
}

export class AssignmentTenantAvailableStickerUalAction implements Action {
  readonly type: string = TenantsActions.AssignmentTenantAvailableStickerUal;

  constructor(public payload: any) {}
}

export class RemovalTenantAvailableStickerUalAction implements Action {
  readonly type: string = TenantsActions.RemovalTenantAvailableStickerUal;

  constructor(public payload: any) {}
}

export class GetTenantAvailableApartmentsAction implements Action {
  readonly type: string = TenantsActions.GetTenantAvailableApartments;

  constructor(public payload?: any) {}
}

export class GetTenantAvailableApartmentsSuccessAction implements Action {
  readonly type: string = TenantsActions.GetTenantAvailableApartmentsSuccess;

  constructor(public payload?: any) {}
}

export class UpdateTenantAvailableApartmentsSearchQueryAction
  implements Action {
  readonly type: string =
    TenantsActions.UpdateTenantAvailableApartmentsSearchQuery;

  constructor(public payload?: QueryParamsInterface) {}
}

export class GetTenantAvailableZonesAction implements Action {
  readonly type: string = TenantsActions.GetTenantAvailableZones;

  constructor(public payload: any) {}
}

export class DeleteFromStoreResidentsAvailableZones implements Action {
  readonly type: string = TenantsActions.DeleteFromStoreAvailableZones;

  constructor(public payload?) {}
}

export class GetTenantAvailableZonesSuccessAction implements Action {
  readonly type: string = TenantsActions.GetTenantAvailableZonesSuccess;

  constructor(
    public payload: TenantsItemAvailableZonesRequestResponseInterface
  ) {}
}

export class UpdateTenantAvailableZonesSearchQueryAction implements Action {
  readonly type: string = TenantsActions.UpdateTenantAvailableZonesSearchQuery;

  constructor(public payload: QueryParamsInterface) {}
}

export class AssignmentTenantAvailableZoneAction implements Action {
  readonly type: string = TenantsActions.AssignmentTenantAvailableZone;

  constructor(public payload: any) {}
}

export class RemovalTenantAvailableZoneAction implements Action {
  readonly type: string = TenantsActions.RemovalTenantAvailableZone;

  constructor(public payload: any) {}
}

export class GetTenantAvailableStickerZonesAction implements Action {
  readonly type: string = TenantsActions.GetTenantAvailableStickerZones;

  constructor(public payload: any) {}
}

export class DeleteFromStoreResidentsAvailableStickerZones implements Action {
  readonly type: string = TenantsActions.DeleteFromStoreAvailableStickerZones;

  constructor(public payload?) {}
}

export class GetTenantAvailableStickerZonesSuccessAction implements Action {
  readonly type: string = TenantsActions.GetTenantAvailableStickerZonesSuccess;

  constructor(
    public payload: TenantsItemAvailableZonesRequestResponseInterface
  ) {}
}

export class UpdateTenantAvailableStickerZonesSearchQueryAction
  implements Action {
  readonly type: string =
    TenantsActions.UpdateTenantAvailableStickerZonesSearchQuery;

  constructor(public payload: QueryParamsInterface) {}
}

export class AssignmentTenantAvailableStickerZoneAction implements Action {
  readonly type: string = TenantsActions.AssignmentTenantAvailableStickerZone;

  constructor(public payload: any) {}
}

export class RemovalTenantAvailableStickerZoneAction implements Action {
  readonly type: string = TenantsActions.RemovalTenantAvailableStickerZone;

  constructor(public payload: any) {}
}

export class GetTenantAvailableDoorsAction implements Action {
  readonly type: string = TenantsActions.GetTenantAvailableDoors;

  constructor(public payload: any) {}
}

export class GetTenantAvailableDoorsSuccessAction implements Action {
  readonly type: string = TenantsActions.GetTenantAvailableDoorsSuccess;

  constructor(
    public payload: TenantsItemAvailableDoorsRequestResponseInterface
  ) {}
}

export class UpdateTenantAvailableDoorsSearchQueryAction implements Action {
  readonly type: string = TenantsActions.UpdateTenantAvailableDoorsSearchQuery;

  constructor(public payload: QueryParamsInterface) {}
}

export class DeleteFromStoreResidentsAvailableDoors implements Action {
  readonly type: string = TenantsActions.DeleteFromStoreAvailableDoors;

  constructor(public payload?) {}
}

export class AssignmentTenantAvailableDoorAction implements Action {
  readonly type: string = TenantsActions.AssignmentTenantAvailableDoor;

  constructor(public payload: any) {}
}

export class RemovalTenantAvailableDoorAction implements Action {
  readonly type: string = TenantsActions.RemovalTenantAvailableDoor;

  constructor(public payload: any) {}
}

export class GetTenantActivityLogsAction implements Action {
  readonly type: string = TenantsActions.GetTenantActivityLogs;

  constructor(public payload: QueryParamsInterface) {}
}

export class GetTenantActivityLogsSuccessAction implements Action {
  readonly type: string = TenantsActions.GetTenantActivityLogsSuccess;

  constructor(
    public payload: TenantsItemActivityLogsRequestResponseInterface
  ) {}
}

export class UpdateTenantActivityLogsSearchQueryAction implements Action {
  readonly type: string = TenantsActions.UpdateTenantActivityLogsSearchQuery;

  constructor(public payload: QueryParamsInterface) {}
}

export class GetTenantVisitorsListAction implements Action {
  readonly type: string = TenantsActions.GetTenantVisitorsList;

  constructor(public payload: QueryParamsInterface) {}
}

export class GetTenantVisitorsListSuccessAction implements Action {
  readonly type: string = TenantsActions.GetTenantVisitorsListSuccess;

  constructor(
    public payload: TenantsItemVisitorsListRequestResponseInterface
  ) {}
}

export class UpdateTenantVisitorsListSearchQueryAction implements Action {
  readonly type: string = TenantsActions.UpdateTenantVisitorsListSearchQuery;

  constructor(public payload: QueryParamsInterface) {}
}

export class GetEncodersAction implements Action {
  readonly type = TenantsActions.GetEncoders;

  constructor(public payload?: CompanyQueryInterface) {}
}

export class GetEncodersSuccessAction implements Action {
  readonly type = TenantsActions.GetEncodersSuccess;

  constructor(public payload?) {}
}

export class GenerateKeyAction implements Action {
  readonly type = TenantsActions.GenerateKey;

  constructor(public payload: GenerateKeyActionPayloadInterface) {}
}

export class DeleteKeyAction implements Action {
  readonly type = TenantsActions.DeleteKey;

  constructor(public payload: number) {}
}

export class GetTaggedTenantsAction implements Action {
  readonly type = TenantsActions.GetTaggedTenants;

  constructor(public payload: QueryParamsInterface) {}
}

export class GetTaggedTenantsSuccessAction implements Action {
  readonly type = TenantsActions.GetTaggedTenantsSuccess;

  constructor(public payload: TaggedTenantsRequestResponseInterface) {}
}

export class UpdateTaggedTenantsSearchQueryAction implements Action {
  readonly type = TenantsActions.UpdateTaggedTenantsSearchQueryAction;

  constructor(public payload: QueryParamsInterface) {}
}

export class GetTaggedTenantAuditTrailsAction implements Action {
  readonly type = TenantsActions.GetTaggedTenantAuditTrails;

  constructor(public payload: number) {}
}

export class GetTaggedTenantAuditTrailsSuccessAction implements Action {
  readonly type = TenantsActions.GetTaggedTenantAuditTrailsSuccess;

  constructor(
    public payload: TaggedTenantAuditTrailsRequestResponseInterface
  ) {}
}

export class GetTaggedVisitorLogsAction implements Action {
  readonly type = TenantsActions.GetTaggedVisitorLogs;

  constructor(public payload: number) {}
}

export class GetTaggedVisitorLogsSuccessAction implements Action {
  readonly type = TenantsActions.GetTaggedVisitorLogsSuccess;

  constructor(public payload: TaggedVisitorLogsRequestResponseInterface) {}
}

export class ExpireFobAction implements Action {
  readonly type = TenantsActions.ExpireFob;

  constructor(public payload: {id: number; data: object}) {}
}
export class ExpireFobSuccessAction implements Action {
  readonly type = TenantsActions.ExpireFobSuccess;

  constructor(public payload: any) {}
}
export class DeleteFobKeyAction implements Action {
  readonly type = TenantsActions.DeleteFobKey;

  constructor(public payload: {id: number; data: object}) {}
}
export class DeleteFobKeySuccessAction implements Action {
  readonly type = TenantsActions.DeleteFobKeySuccess;

  constructor(public payload: any) {}
}

export class DeleteFobUserAction implements Action {
  readonly type = TenantsActions.DeleteFobUser;

  constructor(public payload: any) {}
}
export class DeleteFobUserSuccessAction implements Action {
  readonly type = TenantsActions.DeleteFobUserSuccess;

  constructor(public payload: any) {}
}

export class UpdateDeleteFobStatusAction implements Action {
  readonly type = TenantsActions.UpdateDeleteFobStatus;

  constructor(public payload: any) {}
}

export class ClearPopupDataAction implements Action {
  readonly type = TenantsActions.ClearPopupData;

  constructor(public payload?: any) {}
}

export type TenantsActionsUnion =
  | GetTenantsAction
  | GetTenantsSuccessAction
  | UpdateTenantsSearchQueryAction
  | GetTenantAction
  | GetTenantSuccessAction
  | SaveTenantAction
  | GetTenantNoteAction
  | GetTenantNoteSuccessAction
  | SaveTenantNoteAction
  | CreateTenantAction
  | DeleteTenantFormStoreAction
  | DisableTenantAction
  | EnableTenantAction
  | BanTenantAction
  | UnBanTenantAction
  | GetTenantActivityLogsAction
  | GetTenantActivityLogsSuccessAction
  | UpdateTenantActivityLogsSearchQueryAction
  | DeleteTenantAction
  | TotallyDeleteTenantAction
  | GetEncodersAction
  | GetEncodersSuccessAction
  | GenerateKeyAction
  | GetTaggedTenantsAction
  | GetTaggedTenantsSuccessAction
  | UpdateTaggedTenantsSearchQueryAction
  | GetTaggedTenantAuditTrailsAction
  | GetTaggedTenantAuditTrailsSuccessAction
  | GetTaggedVisitorLogsAction
  | GetTaggedVisitorLogsSuccessAction
  | GetTenantAvailableDoorsAction
  | GetTenantAvailableDoorsSuccessAction
  | AssignmentTenantAvailableDoorAction
  | RemovalTenantAvailableDoorAction
  | GetTenantVisitorsListAction
  | GetTenantVisitorsListSuccessAction
  | UpdateTenantVisitorsListSearchQueryAction
  | UpdateTenantAvailableDoorsSearchQueryAction
  | UpdateTenantAvailableUalsSearchQueryAction
  | UpdateTenantAvailableLobbyUalsSearchQueryAction
  | GetTenantAvailableUalsAction
  | GetTenantAvailableUalsSuccessAction
  | AssignmentTenantAvailableUalAction
  | RemovalTenantAvailableUalAction
  | GetTenantAvailableLobbyUalsAction
  | UpdateTenantAvailableStickerUalsSearchQueryAction
  | GetTenantAvailableStickerUalsAction
  | GetTenantAvailableStickerUalsSuccessAction
  | AssignmentTenantAvailableStickerUalAction
  | RemovalTenantAvailableStickerUalAction
  | UpdateTenantAvailableLobbySearchQueryAction
  | UpdateTenantFirstLobbyListSearchQueryAction
  | UpdateTenantSecondLobbyListSearchQueryAction
  | GetTenantAvailableLobbyAction
  | GetTenantFirstLobbyListAction
  | GetTenantSecondLobbyListAction
  | GetTenantAvailableLobbySuccessAction
  | GetTenantFirstLobbyListSuccessAction
  | GetTenantSecondLobbyListSuccessAction
  | UpdateTenantAvailableZonesSearchQueryAction
  | GetTenantAvailableZonesAction
  | GetTenantAvailableZonesSuccessAction
  | AssignmentTenantAvailableZoneAction
  | RemovalTenantAvailableZoneAction
  | UpdateTenantAvailableStickerZonesSearchQueryAction
  | GetTenantAvailableStickerZonesAction
  | GetTenantAvailableStickerZonesSuccessAction
  | AssignmentTenantAvailableStickerZoneAction
  | RemovalTenantAvailableStickerZoneAction
  | ExpireFobAction
  | ExpireFobSuccessAction
  | DeleteFobKeyAction
  | DeleteFobKeySuccessAction
  | DeleteFobUserAction
  | DeleteFobUserSuccessAction
  | ClearPopupDataAction;
