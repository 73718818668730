import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
export class LdapComponent {
    constructor(activatedRouter, router, service) {
        this.activatedRouter = activatedRouter;
        this.router = router;
        this.service = service;
        this.mode = 'indeterminate';
        this.spinner = true;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const url = this.router.url.slice(1);
            if (window.location.search) {
                this.service.redirectUrl(url).subscribe((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (res.token) {
                        localStorage.setItem('access_token', res.token);
                        localStorage.setItem('refresh_token', res.refreshToken);
                        yield this.router.navigate(['/dashboard']);
                    }
                    else {
                        yield this.router.navigate(['/auth/signin'], { relativeTo: this.activatedRouter, queryParams: { error: res.message } });
                    }
                }));
            }
        });
    }
    LdapRedirect() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const url = this.router.url.slice(1);
            yield this.service.redirectUrl(url).subscribe(res => {
            });
            yield this.router.navigate(['/dashboard']);
        });
    }
}
