import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {NotifierService} from 'angular-notifier';
import {throwError} from 'rxjs';
import {QueryParamsInterface} from '../interfaces/query-params.interface';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {TenantsState} from '../dashboard/residents/Store/reducers/tenants.reducer';
import {
  UpdateDeleteFobStatusAction,
  UpdateSaveTenantResponseAction
} from '../dashboard/residents/Store/actions/tenants.actions';
import {ProfileState} from '../Store/reducers/profile.reducer';
import {UpdateLoadingStatusAction} from '../Store/actions/profile.actions';

const apiUrl = environment.apiUrl;
const apiUrlShort = environment.shortUrl;
const apiUrlAutomations = environment.automationsApiUrl;
const apiWhitelist = ['auth/signin', 'ldap/get-login-url', 'passwordrecovery'];

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private token;
  private refresh_token;

  constructor(
    private http: HttpClient,
    private notifierService: NotifierService,
    private router: Router,
    private store: Store<TenantsState>,
    private profileStore: Store<ProfileState>
  ) {}

  public filterQueryParams = (query: QueryParamsInterface, isEncode?) => {
    const newQuery = {...query};
    let params = new HttpParams();
    for (const key in newQuery) {
      if (Object.is(newQuery[key], NaN) || newQuery[key] === null) {
        delete newQuery[key];
      } else if (
        typeof newQuery[key] === 'string' &&
        newQuery[key].length === 0
      ) {
        delete newQuery[key];
      }
      // if (isEncode) {
      //   newQuery[key] = encodeURIComponent(newQuery[key])
      // }
    }
    return newQuery;
  };

  public call = (method: string, url: string, body?, queryParams?) => {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    queryParams = this.filterQueryParams(queryParams);
    return this.http
      .request(method, apiUrl + url, {
        body,
        headers,
        params: queryParams
      })
      .pipe(
        catchError((err) => {
          err.error.error.forEach((item) => {
            this.notifierService.notify('error', item.message);
            if (item.message === 'Sign in first') {
              this.router.navigate(['auth/signin']);
            }
            if (
              item.message === 'Invalid token' ||
              item.message === 'No token provided'
            ) {
              this.router.navigate(['auth/signin']);
            }
          });
          this.store.dispatch(new UpdateSaveTenantResponseAction(true));
          this.store.dispatch(new UpdateDeleteFobStatusAction(false));
          this.profileStore.dispatch(new UpdateLoadingStatusAction(false));
          return throwError(err);
        })
      );
  };
  public callAutomation = (
    method: string,
    url: string,
    body?,
    queryParams?
  ) => {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    queryParams = this.filterQueryParams(queryParams);
    return this.http
      .request(method, apiUrlAutomations + url, {
        body,
        headers,
        params: queryParams
      })
      .pipe(
        catchError((err) => {
          if (err && err.error && err.error.error && err.error.error.length) {
            err.error.error.forEach((item) => {
              this.notifierService.notify('error', item.message);
              if (item.message === 'Sign in first') {
                this.router.navigate(['auth/signin']);
              }
              if (
                item.message === 'Invalid token' ||
                item.message === 'No token provided'
              ) {
                this.router.navigate(['auth/signin']);
              }
            });
          } else if (err && err.error && err.error.message) {
            this.notifierService.notify('error', err.error.message);
          }
          this.store.dispatch(new UpdateSaveTenantResponseAction(true));
          this.store.dispatch(new UpdateDeleteFobStatusAction(false));
          this.profileStore.dispatch(new UpdateLoadingStatusAction(false));
          return throwError(err);
        })
      );
  };

  public callShortUrl = (method: string, url: string, body?, queryParams?) => {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    queryParams = this.filterQueryParams(queryParams);

    return this.http
      .request(method, apiUrlShort + url, {
        body,
        headers,
        params: queryParams
      })
      .pipe(
        catchError((err) => {
          err.error.error.forEach((item) => {
            this.notifierService.notify('error', item.message);
            if (item.message === 'Sign in first') {
              this.router.navigate(['auth/signin']);
            }
            if (
              item.message === 'Invalid token' ||
              item.message === 'No token provided'
            ) {
              this.router.navigate(['auth/signin']);
            }
          });

          return throwError(err);
        })
      );
  };

  public callShortUrlWithHeaders = (
    method: string,
    url: string,
    body?,
    queryParams?
  ) => {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    queryParams = this.filterQueryParams(queryParams, true);
    console.log(queryParams);
    return this.http
      .request(method, 'https://dev-api.walkdotin.com/' + url, {
        body,
        headers,
        params: queryParams,
        observe: 'response'
      })
      .pipe(
        catchError((err) => {
          err.error.error.forEach((item) => {
            this.notifierService.notify('error', item.message);
            if (item.message === 'Sign in first') {
              this.router.navigate(['auth/signin']);
            }
            if (
              item.message === 'Invalid token' ||
              item.message === 'No token provided'
            ) {
              this.router.navigate(['auth/signin']);
            }
          });

          return throwError(err);
        })
      );
  };

  /*public callAppClips = (method: string, url: string, body?, queryParams?) => {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    queryParams = this.filterQueryParams(queryParams);

    return this.http.request(method, apiUrlAppClips + url, {
      body, headers,
      params: queryParams
    })
      .pipe(
        catchError(err => {
          err.error.error.forEach(item => {this.notifierService.notify('error', item.message);
            if (item.message === 'Sign in first') {
              this.router.navigate(['auth/signin']);
            }
          });

          return throwError(err);
        })
      );
  };*/

  public call2 = (
    method: string,
    url: string,
    body?,
    queryParams?,
    isMicro = false
  ) => {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    queryParams = this.filterQueryParams(queryParams);

    return this.http
      .request(method, `${apiUrl}${url}`, {
        body,
        headers,
        params: queryParams,
        responseType: 'blob'
      })
      .pipe(
        catchError((err) => {
          err.error.error.forEach((item) => {
            this.notifierService.notify('error', item.message);
            if (item.message === 'Sign in first') {
              this.router.navigate(['auth/signin']);
            }
            if (
              item.message === 'Invalid token' ||
              item.message === 'No token provided'
            ) {
              this.router.navigate(['auth/signin']);
            }
          });

          return throwError(err);
        })
      );
  };

  public fileCall = (method: string, url: string, body) => {
    return this.http
      .request(method, apiUrl + url, {
        body
      })
      .pipe(
        catchError((err) => {
          err.error.error.forEach((item) => {
            this.notifierService.notify('error', item.message);
            if (
              item.message === 'Invalid token' ||
              item.message === 'No token provided'
            ) {
              this.router.navigate(['auth/signin']);
            }
          });
          return throwError(err);
        })
      );
  };

  public addCompanyToQueryParams = (query) => {
    let resultQuery = query;
    this.profileStore.select('profile').subscribe(({profile}: any) => {
      if (profile.company > 0) {
        resultQuery = {company: profile.company, ...resultQuery};
      }
    });
    return resultQuery;
  };

  public refreshToken = () => {
    // const token = localStorage.getItem('access_token');
    // const refreshToken = localStorage.getItem('refresh_token');
    // this.token = token;
    // this.refresh_token = refreshToken;
    // if (token && refreshToken) {
    //   const decode_token: {exp?: number} = jwt_decode(token);
    //   if (decode_token.exp && decode_token.exp < Date.now() / 1000) {
    //     try {
    //       this.call('POST', 'auth/refresh', {refreshToken}).subscribe((res: {token: string, refreshToken: string}) => {
    //         if (res) {
    //           localStorage.setItem('access_token', res.token);
    //           localStorage.setItem('refresh_token', res.refreshToken);
    //           this.token = res.token;
    //           this.refresh_token = res.refreshToken;
    //         }
    //       })
    //     } catch (e) {
    //
    //     }
    //   }
    // }
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.request('POST', apiUrl + 'auth/refresh', {
      headers,
      body: {refreshToken: localStorage.getItem('refresh_token')}
    });
  };
}
