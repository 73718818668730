import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {
  SettingsAutomationsLogsActions,
  SettingsAutomationsLogsActionsUnion
} from '../actions/automations-logs.actions';
import {
  AutomationsLogsRequestResponseInterface,
  AutomationStepsInterface
} from '../../interfaces/automations-logs.interface';

export interface AutomationsLogsState {
  automationsLogs: AutomationsLogsRequestResponseInterface;
  automationsLogsSearchQuery: QueryParamsInterface;
  automationSteps: AutomationStepsInterface[];
}

const initialState: AutomationsLogsState = {
  automationsLogs: {automationReportData: [], total: 0},
  automationsLogsSearchQuery: null,
  automationSteps: []
};

export function automationsLogsReducer(
  state: AutomationsLogsState = initialState,
  action: SettingsAutomationsLogsActionsUnion
) {
  switch (action.type) {
    case SettingsAutomationsLogsActions.GetAutomationsLogsSuccess: {
      return {
        ...state,
        automationsLogs: action.payload
      };
    }
    case SettingsAutomationsLogsActions.DeleteAutomationsLogsFromStore: {
      return {
        ...state,
        automationsLogs: {automationReportData: [], total: 0}
      };
    }
    case SettingsAutomationsLogsActions.UpdateAutomationsLogsSearchQuery: {
      const result = {...state.automationsLogsSearchQuery};
      if (result.building && !action.payload.building) {
        delete result.building;
      }
      return {
        ...state,
        automationsLogsSearchQuery: {
          ...result,
          ...action.payload
        }
      };
    }
    case SettingsAutomationsLogsActions.GetAutomationSteps: {
      return {
        ...state,
        automationSteps: action.payload
      };
    }
    case SettingsAutomationsLogsActions.DeleteAutomationStepsFromStore: {
      return {
        ...state,
        automationSteps: []
      };
    }
    default: {
      return state;
    }
  }
}
