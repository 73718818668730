import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {RolesActions, RolesActionsUnion} from '../actions/roles.actions';
import {
  RoleInterface,
  RolesRequestResponseInterface
} from '../../interfaces/role.interface';

export interface RolesState {
  roles: RolesRequestResponseInterface;
  rolesSearchQuery: QueryParamsInterface;
  role: RoleInterface;
  allRoles: RolesRequestResponseInterface;
  neighborhoods: any;
  company: any;
  companyLoading: boolean,
}

const initialState: RolesState = {
  role: null,
  roles: {roles: [], total: 0},
  rolesSearchQuery: null,
  allRoles: null,
  neighborhoods: null,
  company: null,
  companyLoading: false
};

export function rolesReducer(state = initialState, action: RolesActionsUnion) {
  switch (action.type) {
    case RolesActions.GetRolesSuccess: {
      return {
        ...state,
        roles: action.payload
      };
    }
    case RolesActions.UpdateRolesSearchQueryRaw: {
      return {
        ...state,
        rolesSearchQuery: action.payload
      };
    }
    case RolesActions.GetRoleSuccess: {
      return {
        ...state,
        role: action.payload
      };
    }
    case RolesActions.DeleteRoleFromStore: {
      return {
        ...state,
        role: null,
        company: null,
        companyLoading: false
      };
    }
    case RolesActions.GetAllRolesSuccess: {
      return {
        ...state,
        allRoles: action.payload
      };
    }
    case RolesActions.GetAllNeighborhoodsSuccess: {
      return {
        ...state,
        neighborhoods: action.payload
      };
    }
    case RolesActions.GetCompanyInfo: {
      return {
        ...state,
        companyLoading: true
      }
    }
    case RolesActions.GetCompanyInfoSuccess: {
      return {
        ...state,
        company: action.payload,
        companyLoading: false
      }
    }
    case RolesActions.GetCompanyInfoForAdmin: {
      return {
        ...state,
        companyLoading: true
      }
    }
    case RolesActions.GetCompanyInfoForAdminSuccess: {
      return {
        ...state,
        company: {modules: action.payload},
        companyLoading: false
      }
    }
    case RolesActions.SetCompanyLoading: {
      return {
        ...state,
        companyLoading: action.payload
      }
    }
    default: {
      return state;
    }
  }
}
