import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {map, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {GetProfileSuccessAction} from '../../Store/actions/profile.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<any>,
    private auth: AuthService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.auth.getProfile().pipe(
      tap(profile => {
        this.store.dispatch(new GetProfileSuccessAction(profile));
      }),
      map(Boolean)
    );

  }
}
