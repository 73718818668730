import {
  AuditLogDeltaInterface,
  AuditLogRequestResponseInterface
} from '../../interfaces/audit-log.interface';
import {
  AuditLogActionsActionsUnion,
  AuditLogActions
} from '../actions/audit-log.actions';
import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import * as moment from 'moment';

export interface AuditLogState {
  auditLogs: AuditLogRequestResponseInterface;
  auditLogsSearchQuery: any;

  actionTypes: any;
  entities: any;
  userTypes: any;

  delta: AuditLogDeltaInterface;
}

const initialState: AuditLogState = {
  auditLogs: {data: [], total: 0},
  auditLogsSearchQuery: null,
  actionTypes: null,
  entities: null,
  userTypes: null,
  delta: null
};

export function auditLogsReducer(
  state: AuditLogState = initialState,
  action: AuditLogActionsActionsUnion
) {
  switch (action.type) {
    case AuditLogActions.GetAuditLogsSuccess: {
      return {
        ...state,
        auditLogs: action.payload
      };
    }
    case AuditLogActions.UpdateAuditLogsSearchQuery: {
      const result = action.payload;
      if (result.dateFrom) {
        result.dateFrom = moment(result.dateFrom).format('YYYY-MM-DD');
      }
      if (result.dateTo) {
        result.dateTo = moment(result.dateTo).format('YYYY-MM-DD');
      }

      if (!result.userName) {
        result.userName = '';
      }

      if (!result.userId && typeof result.userId !== 'number') {
        result.userId = '';
      }

      return {
        ...state,
        auditLogsSearchQuery: {...state.auditLogsSearchQuery, ...result}
      };
    }
    case AuditLogActions.GetActionTypesSuccess: {
      return {
        ...state,
        actionTypes: action.payload
      };
    }
    case AuditLogActions.GetEntitySuccess: {
      return {
        ...state,
        entities: action.payload
      };
    }
    case AuditLogActions.GetUserTypesSuccess: {
      return {
        ...state,
        userTypes: action.payload
      };
    }
    case AuditLogActions.DeleteDataFromStore: {
      return {
        ...state,
        auditLogs: {data: [], total: 0},
        actionTypes: null,
        entities: null,
        userTypes: null,
        delta: null
      };
    }
    case AuditLogActions.GetLogDelta: {
      return {
        ...state,
        delta: action.payload
      };
    }
    case AuditLogActions.DeleteLogDeltaFromStore: {
      return {
        ...state,
        delta: null
      };
    }
    default: {
      return state;
    }
  }
}
