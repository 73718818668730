import * as i0 from "./modal-wrapper.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modal-wrapper.component";
import * as i3 from "../../services/modal.service";
var styles_ModalWrapperComponent = [i0.styles];
var RenderType_ModalWrapperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalWrapperComponent, data: {} });
export { RenderType_ModalWrapperComponent as RenderType_ModalWrapperComponent };
export function View_ModalWrapperComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { modalWrapper: 0 }), (_l()(), i1.ɵeld(1, 16777216, [[1, 3], ["modalWrapper", 1]], null, 0, "div", [["class", "modal-wrapper"]], null, null, null, null, null))], null, null); }
export function View_ModalWrapperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-wrapper", [], null, null, null, View_ModalWrapperComponent_0, RenderType_ModalWrapperComponent)), i1.ɵdid(1, 114688, null, 0, i2.ModalWrapperComponent, [i3.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalWrapperComponentNgFactory = i1.ɵccf("app-modal-wrapper", i2.ModalWrapperComponent, View_ModalWrapperComponent_Host_0, {}, {}, []);
export { ModalWrapperComponentNgFactory as ModalWrapperComponentNgFactory };
