import { OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConnectAccessAutomationOperandValueAction, CreateAccessAutomationAction, DeleteAccessAutomationFromStoreAction, DeleteExpressionAction, DisconnectAccessAutomationOperandValueAction, GetAccessAutomationAction, GetAccessAutomationAllActionsAction, GetAccessAutomationAllOperandsAction, SaveAccessAutomationAction, UpdateExpressionOperatorAction } from '../Store/actions/access-automations.actions';
import { dashboardSystemAccessAutomationDataSelectUrl, accessAutomationOperand as operandConst, dashboardSystemAccessAutomationAllActionsDataSelectUrl, dashboardSystemAccessAutomationAvailableCommandsListDataSelectUrl, dashboardSystemAccessAutomationAllOperandsListDataSelectUrl, dashboardSystemAccessAutomationLoadingDataSelectUrl, dashboardSystemAccessAutomationConnectOperandValueLoadingDataSelectUrl, dashboardSystemAccessAutomationDisconnectOperandValueLoadingDataSelectUrl, dashboardSystemBuildingIdSelectUrl, dashboardSystemAccessAutomationBuildingCommonAreaDataSelectUrl, dashboardSystemAccessAutomationLeasingUalsDataSelectUrl, dashboardSystemAccessAutomationVacancyUalsDataSelectUrl, dashboardSystemBuildingDataSelectUrl } from '../constants/constants';
export class AccessAutomationsItemsComponent {
    constructor(store, router, activeRoute) {
        this.store = store;
        this.router = router;
        this.activeRoute = activeRoute;
        this.events = new Subject();
        this.mode = 'indeterminate';
        this.spinner = true;
        this.subscriptions = [];
        this.isResidentsStatus = false;
        this.isFirstOperation = false;
        this.isFirstOperationUnderWord = false;
        this.isApartmentStatus = false;
        this.isSecondOperation = false;
        this.isSecondOperationUnderWord = false;
        this.isSelectedMove = false;
        this.isMoveInDate = false;
        this.isMoveOutDate = false;
        this.isActions = false;
        this.isActionTo = false;
        this.isLeasingGroups = false;
        this.isVacancyGroups = false;
        this.isBuildingCommonAreas = false;
        this.modalsList = [
            'isResidentsStatus',
            'isApartmentStatus',
            'isSelectedMove',
            'isMoveInDate',
            'isMoveOutDate',
            'isFirstOperationUnderWord',
            'isSecondOperationUnderWord',
            'isActions',
            'isActionTo',
            'isLeasingGroups',
            'isVacancyGroups',
            'isBuildingCommonAreas'
        ];
        this.residentsStatusList = [];
        this.apartmentsStatusList = [];
        this.moveInDateStatusList = [];
        this.moveOutDateStatusList = [];
        this.actionsList = [];
        this.actionsToList = [];
        this.buildingCommonAreaList = [];
        this.leasingUalsList = [];
        this.vacancyUalsList = [];
        this.isFirstInit = true;
        this.applyAction = (index) => {
            const action = this.newActions.at(index);
            action.get('checked').setValue(false);
        };
        this.applyCommand = (index) => {
            const action = this.newActions.at(index);
            action.get('checkedCommands').setValue(false);
        };
        this.clearCommandsFromArray = (formArray) => {
            while (formArray.length !== 0) {
                formArray.removeAt(0);
            }
        };
        this.setAction = (item, index) => {
            const action = this.newActions.at(index);
            const currentCommands = action.get('commands');
            this.clearCommandsFromArray(currentCommands);
            action.get('sort').setValue(index + 1);
            const actionField = action.get('action');
            actionField.get('alias').setValue(item.alias);
            actionField.get('id').setValue(item.id);
            actionField.get('name').setValue(item.name);
            if (item.alias === 'add_to_system' || item.alias === 'disable_resident') {
                const commandsList = this.getCurrentCommandsList(item.alias, index);
                const currentCommands = action.get('commands');
                this.addCommandGroup(Object.assign({}, commandsList[0].element, { commandId: commandsList[0].id }), currentCommands);
            }
        };
        this.getCurrentCommandsList = (alias, actionIndex) => {
            const currentAction = this.actionsList.find((el) => el.alias === alias);
            const filterArr = currentAction.commands.filter((command) => command.element.alias !== 'building_group_common_access' &&
                command.element.alias !== 'rentable_items' &&
                !this.checkCommand(command.element.alias, actionIndex)) || [];
            return filterArr;
        };
        this.cancel = () => {
            const openModal = this.checkOpenedModal();
            if (openModal) {
                return;
            }
            this.events.next(false);
            this.modalService.closeModal();
        };
        this.checkDisableSaveButton = () => {
            const fullAction = this.newActions.value.length
                ? this.newActions.value.find((action) => action.action && action.action.name && action.commands.length)
                : null;
            return (this.user.permissions.system.buildings.write &&
                this.residentsStatus.value.length &&
                this.apartmentStatus.value.length &&
                (this.moveInDate.value.length || this.moveOutDate.value.length) &&
                fullAction &&
                (!this.automationForm.pristine || !this.form.pristine));
        };
        this.length = length;
        this.operandConst = operandConst;
        this.form = new FormGroup({
            name: new FormControl('', [
                Validators.required,
                Validators.maxLength(64),
                Validators.minLength(3)
            ]),
            description: new FormControl('', [
                Validators.minLength(3),
                Validators.maxLength(128)
            ]),
            residentsStatus: new FormControl([]),
            firstOperation: new FormControl(null),
            apartmentStatus: new FormControl([]),
            secondOperation: new FormControl(null),
            selectedMove: new FormControl('moveIn'),
            moveInDate: new FormControl([]),
            moveOutDate: new FormControl([]),
            actions: new FormControl([]),
            actionTo: new FormControl([]),
            newCommands: new FormArray([]),
            leasingGroups: new FormControl([]),
            vacancyGroups: new FormControl([]),
            buildingCommonAreas: new FormControl([]),
            newActions: new FormArray([])
        });
        this.automationForm = new FormGroup({
            commands: new FormArray([]),
            companyId: new FormControl(1),
            expressions: new FormArray([]),
            name: new FormControl('', [
                Validators.required,
                Validators.maxLength(64),
                Validators.minLength(3)
            ]),
            description: new FormControl('', [
                Validators.minLength(3),
                Validators.maxLength(128)
            ]),
            status: new FormControl(false),
            rules: new FormControl([]),
            author: new FormControl()
        });
    }
    canDeactivate() {
        return true;
    }
    get expressions() {
        return this.automationForm.get('expressions');
    }
    residentsExpression(operandIndex) {
        const index = this.expressions.controls.findIndex((control) => control.value.operandId === operandIndex);
        return this.expressions.controls[index];
    }
    getCurrentExpression(operandIndex) {
        const index = this.expressions.controls.findIndex((control) => control.value.operandId === operandIndex);
        return this.expressions.controls[index];
    }
    get operandValues() {
        return this.expressions.get('operandValues');
    }
    get commands() {
        return this.automationForm.get('commands');
    }
    get residentsStatus() {
        return this.form.controls['residentsStatus'];
    }
    get apartmentStatus() {
        return this.form.controls['apartmentStatus'];
    }
    get firstOperation() {
        return this.form.controls['firstOperation'];
    }
    get secondOperation() {
        return this.form.controls['secondOperation'];
    }
    get selectedMove() {
        return this.form.controls['selectedMove'];
    }
    get moveInDate() {
        return this.form.controls['moveInDate'];
    }
    get moveOutDate() {
        return this.form.controls['moveOutDate'];
    }
    get actions() {
        return this.form.controls['actions'];
    }
    get actionTo() {
        return this.form.controls['actionTo'];
    }
    get leasingGroups() {
        return this.form.controls['leasingGroups'];
    }
    get vacancyGroups() {
        return this.form.controls['vacancyGroups'];
    }
    get buildingCommonAreas() {
        return this.form.controls['buildingCommonAreas'];
    }
    get newActions() {
        return this.form.controls['newActions'];
    }
    get newCommands() {
        return this.form.controls['commands'];
    }
    ngOnInit() {
        this.store.select('profile').subscribe(({ profile }) => {
            if (profile) {
                this.user = profile;
                this.companyId = this.user.company;
                if (!this.user.isGlobalAdminRole) {
                }
            }
            else {
                this.router.navigate(['auth/signin']);
            }
        });
        this.subscriptions.push(this.store
            .select(...dashboardSystemBuildingIdSelectUrl)
            .subscribe((id) => {
            if (id) {
                this.buildingId = id;
            }
        }));
        this.subscriptions.push(this.store
            .select(...dashboardSystemBuildingDataSelectUrl)
            .subscribe((building) => {
            if (building) {
                this.companyId = building.company;
            }
        }));
        if (this.options && this.options.id) {
            this.automationId = this.options.id;
            this.store.dispatch(new GetAccessAutomationAction(this.options.id));
        }
        else {
            this.addNewActionsGroups();
        }
        this.store.dispatch(new GetAccessAutomationAllActionsAction());
        this.store.dispatch(new GetAccessAutomationAllOperandsAction(this.companyId || this.user.company));
        this.subscriptions.push(this.store
            .select(...dashboardSystemAccessAutomationAllOperandsListDataSelectUrl)
            .subscribe((operands) => {
            if (operands.length) {
                const operandResidents = operands.find((el) => el.id === operandConst['RESIDENTS_STATUS']) || null;
                const operandApartments = operands.find((el) => el.id === operandConst['APARTMENTS_STATUS']) || null;
                const operandMoveIn = operands.find((el) => el.id === operandConst['MOVE_IN']) || null;
                const operandMoveOut = operands.find((el) => el.id === operandConst['MOVE_OUT']) || null;
                if (operandResidents) {
                    this.residentsStatusList = operandResidents.operandValues;
                }
                if (operandApartments) {
                    this.apartmentsStatusList = operandApartments.operandValues;
                }
                if (operandMoveIn) {
                    this.moveInDateStatusList = operandMoveIn.operandValues;
                }
                if (operandMoveOut) {
                    this.moveOutDateStatusList = operandMoveOut.operandValues;
                }
            }
        }));
        this.subscriptions.push(this.store
            .select(...dashboardSystemAccessAutomationLoadingDataSelectUrl)
            .subscribe((loading) => {
            this.spinner = loading;
        }));
        this.subscriptions.push(this.store
            .select(...dashboardSystemAccessAutomationConnectOperandValueLoadingDataSelectUrl)
            .subscribe((loading) => {
            this.connectOperandsValueLoading = loading;
        }));
        this.subscriptions.push(this.store
            .select(...dashboardSystemAccessAutomationDisconnectOperandValueLoadingDataSelectUrl)
            .subscribe((loading) => {
            this.disconnectOperandsValueLoading = loading;
        }));
        this.subscriptions.push(this.store
            .select(...dashboardSystemAccessAutomationAllActionsDataSelectUrl)
            .subscribe((allActions) => {
            this.actionsList = allActions;
        }));
        this.subscriptions.push(this.store
            .select(...dashboardSystemAccessAutomationBuildingCommonAreaDataSelectUrl)
            .subscribe((buildingCommonArea) => {
            if (buildingCommonArea) {
                this.buildingCommonAreaList =
                    buildingCommonArea.commonAreaAccessDoors;
                const currentBuildingCommonAreas = this.automationForm.controls.rules.value.find((el) => el.subject === 'buildingCommonAccess');
                if (currentBuildingCommonAreas) {
                    const areas = [];
                    currentBuildingCommonAreas.values.forEach((el) => {
                        areas.push(this.buildingCommonAreaList.find((item) => item.id === Number(el.value)));
                    });
                    this.buildingCommonAreas.setValue(areas);
                }
            }
        }));
        this.subscriptions.push(this.store
            .select(...dashboardSystemAccessAutomationLeasingUalsDataSelectUrl)
            .subscribe((leasingUals) => {
            if (leasingUals) {
                this.leasingUalsList = leasingUals.commonAreaAccessUals;
                const currentLeasing = this.automationForm.controls.rules.value.find((el) => el.subject === 'leasing');
                if (currentLeasing) {
                    const leasing = [];
                    currentLeasing.values.forEach((el) => {
                        leasing.push(this.leasingUalsList.find((item) => item.id === Number(el.value)));
                    });
                    this.leasingGroups.setValue(leasing);
                }
            }
        }));
        this.subscriptions.push(this.store
            .select(...dashboardSystemAccessAutomationVacancyUalsDataSelectUrl)
            .subscribe((vacancyUals) => {
            if (vacancyUals) {
                this.vacancyUalsList = vacancyUals.commonAreaAccessUals;
                const currentVacancy = this.automationForm.controls.rules.value.find((el) => el.subject === 'vacancy');
                if (currentVacancy) {
                    const vacancy = [];
                    currentVacancy.values.forEach((el) => {
                        vacancy.push(this.vacancyUalsList.find((item) => item.id === Number(el.value)));
                    });
                    this.vacancyGroups.setValue(vacancy);
                }
            }
        }));
        this.subscriptions.push(this.store
            .select(...dashboardSystemAccessAutomationAvailableCommandsListDataSelectUrl)
            .subscribe((commands) => {
            if (commands.length) {
                this.actionsToList = [];
                commands.forEach((command) => {
                    if (command.element.alias !== 'building_group_common_access' &&
                        command.element.alias !== 'rentable_items') {
                        this.actionsToList.push(command.element);
                    }
                });
            }
        }));
        this.updateAutomationForm();
        this.subscriptions.push(this.form.valueChanges.subscribe(() => {
            if (!this.isFirstInit) {
                this.form.markAsDirty();
            }
        }));
    }
    updateAutomationForm() {
        this.subscriptions.push(this.store
            .select(...dashboardSystemAccessAutomationDataSelectUrl)
            .subscribe((automation) => {
            if (automation) {
                if (this.isFirstInit) {
                    this.automationForm.patchValue(automation);
                }
                if (automation.name) {
                    this.form.controls.name.setValue(automation.name);
                }
                if (automation.description) {
                    this.form.controls.description.setValue(automation.description);
                }
                if (automation.expressions.length) {
                    const expressions = this.automationForm.get('expressions');
                    expressions.clear();
                    automation.expressions.forEach((expression) => {
                        this.addExpressionsGroup(expression);
                    });
                    this.sortExpressions(automation.expressions);
                }
                if (automation.commands.length && this.isFirstInit) {
                    this.mapActions(automation.commands).forEach((el) => {
                        this.addNewActionsGroups(el);
                    });
                }
                this.isFirstInit = false;
            }
            else {
            }
        }));
    }
    mapActions(actions) {
        let arr = [];
        if (actions.length) {
            actions.forEach((action) => {
                if (!arr.find((el) => el.action.alias === action.command.action.alias)) {
                    arr.push({
                        action: action.command.action,
                        commands: [
                            Object.assign({}, action.command.element, { commandId: action.commandId })
                        ],
                        sort: action.sort
                    });
                }
                else {
                    const currentAction = arr.find((el) => el.action.alias === action.command.action.alias);
                    currentAction.commands.push(Object.assign({}, action.command.element, { commandId: action.commandId }));
                }
            });
        }
        arr.sort((a, b) => {
            return a.sort - b.sort;
        });
        return arr;
    }
    formattingOperandValuesToString(value) {
        if (value.length) {
            const mapValues = value.map((item) => item.value);
            return mapValues.join(', ');
        }
    }
    formattingActionToToString(value) {
        if (value.length) {
            const mapValues = value.map((item) => item.name);
            return mapValues.join(', ');
        }
    }
    sortExpressions(expressions) {
        expressions.forEach((expression) => {
            if (expression.operandId === operandConst['APARTMENTS_STATUS']) {
                this.apartmentStatus.setValue(expression.operandValues.length
                    ? expression.operandValues
                    : [{ id: 0, operandId: 0, value: 'N/A' }]);
                const moveInExpression = this.getCurrentExpression(operandConst['MOVE_IN']);
                const moveOutExpression = this.getCurrentExpression(operandConst['MOVE_OUT']);
                this.secondOperation.setValue(this.selectedMove.value !== null ? expression.operator : null);
            }
            else if (expression.operandId === operandConst['RESIDENTS_STATUS']) {
                this.residentsStatus.setValue(expression.operandValues.length
                    ? expression.operandValues
                    : [{ id: 0, operandId: 0, value: 'N/A' }]);
                const apartmentsExpression = this.getCurrentExpression(operandConst['APARTMENTS_STATUS']);
                this.firstOperation.setValue(apartmentsExpression && apartmentsExpression.value.operator
                    ? expression.operator
                    : null);
            }
            else if (expression.operandId === operandConst['MOVE_IN']) {
                this.moveInDate.setValue(expression.operandValues);
                this.selectedMove.setValue('moveIn');
            }
            else if (expression.operandId === operandConst['MOVE_OUT']) {
                this.moveOutDate.setValue(expression.operandValues);
                this.selectedMove.setValue('moveOut');
            }
        });
    }
    changeSelectWindowStatus(selectWindow) {
        const openModal = this.checkOpenedModal();
        if (openModal) {
            return;
        }
        else {
            this[selectWindow] = true;
        }
    }
    changeCheckbox(event, item, control) {
        if (item.id === 0 && event.target.checked) {
            this[control].setValue([{ id: 0, value: 'N/A', operandId: 0 }]);
        }
        else if (!event.target.checked) {
            this[control].setValue(this[control].value.filter((el) => el.id !== item.id));
        }
        else {
            this[control].setValue(this[control].value.filter((el) => el.id !== 0));
            this[control].value.push(item);
        }
    }
    checkStatusNA(control) {
        return this[control].value.find((el) => el.id === 0);
    }
    changeCheckboxActionTo(event, item, index) {
        const currentAction = this.newActions.at(index);
        const currentCommands = currentAction.get('commands');
        if (!event.target.checked) {
            const findIndex = currentCommands.value.findIndex((el) => el.alias === item.element.alias);
            currentCommands.removeAt(findIndex);
        }
        else {
            this.addCommandGroup(Object.assign({}, item.element, { commandId: item.id }), currentCommands);
        }
    }
    changeCheckboxGroupsOrAreas(event, item, control) {
        if (event.target.checked) {
            this[control].setValue(this[control].value.filter((el) => el.name !== 'N/A'));
            this[control].value.push(item);
        }
        else {
            this[control].setValue(this[control].value.filter((el) => el.name !== item.name));
        }
    }
    changeActionsRadio(item) {
    }
    addOperation() {
        const openModal = this.checkOpenedModal();
        if (openModal) {
            return;
        }
        if (this.residentsStatus.value.length && !this.firstOperation.value) {
            if (this.automationId) {
                const currentExpression = this.getCurrentExpression(operandConst['RESIDENTS_STATUS']);
                this.firstOperation.setValue(currentExpression.value.operator || 'AND');
            }
            else {
                this.firstOperation.setValue('AND');
            }
        }
        else if (this.apartmentStatus.value.length &&
            !this.secondOperation.value) {
            if (this.automationId) {
                const currentExpression = this.getCurrentExpression(operandConst['APARTMENTS_STATUS']);
                this.secondOperation.setValue(currentExpression.value.operator || 'AND');
            }
            else {
                this.secondOperation.setValue('AND');
                this.selectedMove.setValue('moveIn');
            }
        }
    }
    changeOperation(elem) {
        this[elem] = true;
    }
    changeRadio(event, control) {
        this[control].setValue(event.target.value);
    }
    changeRadioMove(event, control) {
        this[control].setValue(event.target.value);
        if (event.target.value === 'moveIn') {
            this.moveOutDate.setValue([]);
        }
        if (event.target.value === 'moveOut') {
            this.moveInDate.setValue([]);
        }
    }
    deleteAutomationElement() {
        const openModal = this.checkOpenedModal();
        if (openModal) {
            return;
        }
        if (this.secondOperation.value) {
            this.secondOperation.setValue(null);
            if (this.selectedMove.value === 'moveIn') {
                this.moveInDate.setValue([]);
                this.disconnectOperandValues(operandConst['MOVE_IN']);
            }
            else {
                this.moveOutDate.setValue([]);
                this.disconnectOperandValues(operandConst['MOVE_OUT']);
            }
            this.selectedMove.setValue(null);
        }
        else if (this.firstOperation.value) {
            this.firstOperation.setValue(null);
            this.apartmentStatus.setValue([]);
            this.disconnectOperandValues(operandConst['APARTMENTS_STATUS']);
        }
        else {
            this.residentsStatus.setValue([]);
            this.disconnectOperandValues(operandConst['RESIDENTS_STATUS']);
        }
    }
    applyChanges(elem) {
        this[elem] = false;
        if (this.automationId) {
            if (elem === 'isFirstOperationUnderWord') {
                const currentExpression = this.getCurrentExpression(operandConst['RESIDENTS_STATUS']);
                this.store.dispatch(new UpdateExpressionOperatorAction({
                    expressionId: currentExpression.value.id,
                    data: {
                        operator: this.firstOperation.value,
                        operatorLeft: 'IN',
                        order: 1,
                        automationId: this.automationId,
                        operandId: operandConst['RESIDENTS_STATUS']
                    }
                }));
            }
            if (elem === 'isSecondOperationUnderWord') {
                const currentExpression = this.getCurrentExpression(operandConst['APARTMENTS_STATUS']);
                this.store.dispatch(new UpdateExpressionOperatorAction({
                    expressionId: currentExpression.value.id,
                    data: {
                        operator: this.secondOperation.value,
                        operatorLeft: 'IN',
                        order: 1,
                        automationId: this.automationId,
                        operandId: operandConst['APARTMENTS_STATUS']
                    }
                }));
            }
            if (elem === 'isResidentsStatus') {
                this.connectAndDisconnectOperandsValue(operandConst['RESIDENTS_STATUS'], this.residentsStatus);
            }
            if (elem === 'isApartmentStatus') {
                this.connectAndDisconnectOperandsValue(operandConst['APARTMENTS_STATUS'], this.apartmentStatus);
            }
            if (elem === 'isMoveInDate') {
                this.connectAndDisconnectOperandsValue(operandConst['MOVE_IN'], this.moveInDate);
            }
            if (elem === 'isMoveOutDate') {
                this.connectAndDisconnectOperandsValue(operandConst['MOVE_OUT'], this.moveOutDate);
            }
            if (elem === 'isSelectedMove') {
                if (this.selectedMove.value === 'moveIn' &&
                    !this.getCurrentExpression(operandConst['MOVE_IN'])) {
                    this.store.dispatch(new DeleteExpressionAction({
                        id: this.getCurrentExpression(operandConst['MOVE_OUT']).value.id,
                        automationId: this.automationId,
                        operandId: operandConst['MOVE_OUT']
                    }));
                }
                else if (this.selectedMove.value === 'moveOut' &&
                    !this.getCurrentExpression(operandConst['MOVE_OUT'])) {
                    this.store.dispatch(new DeleteExpressionAction({
                        id: this.getCurrentExpression(operandConst['MOVE_IN']).value.id,
                        automationId: this.automationId,
                        operandId: operandConst['MOVE_IN']
                    }));
                }
            }
        }
    }
    connectAndDisconnectOperandsValue(expression, operandValues) {
        const originalExpressionValues = this.getCurrentExpression(expression)
            .value;
        const expressionId = originalExpressionValues.id;
        const operandId = originalExpressionValues.operand.id;
        const currentOperandValues = operandValues.value;
        if (originalExpressionValues.operandValues.length) {
            originalExpressionValues.operandValues.forEach((operandValue) => {
                const findEl = currentOperandValues.find((el) => el.id === operandValue.id);
                if (!findEl && operandValue.id !== 0) {
                    this.store.dispatch(new DisconnectAccessAutomationOperandValueAction({
                        expressionId,
                        operandId,
                        valueId: operandValue.id,
                        data: operandValue
                    }));
                }
            });
        }
        if (currentOperandValues.length) {
            currentOperandValues.forEach((operandValue) => {
                const findEl = originalExpressionValues.operandValues.find((el) => el.id === operandValue.id);
                if (!findEl) {
                    this.store.dispatch(new ConnectAccessAutomationOperandValueAction({
                        expressionId,
                        valueId: operandValue.id,
                        data: operandValue
                    }));
                }
            });
        }
    }
    disconnectOperandValues(expression) {
        const originalExpressionValues = this.getCurrentExpression(expression)
            .value;
        const expressionId = originalExpressionValues.id;
        const operandId = originalExpressionValues.operand.id;
        const operandValues = originalExpressionValues.operandValues;
        if (operandValues.length) {
            operandValues.forEach((operandValue) => {
                if (operandValue.id !== 0) {
                    this.store.dispatch(new DisconnectAccessAutomationOperandValueAction({
                        expressionId,
                        operandId,
                        valueId: operandValue.id,
                        data: operandValue
                    }));
                }
            });
        }
    }
    generateOperandValuesGroup() {
        return new FormGroup({
            id: new FormControl(null),
            operandId: new FormControl(null),
            value: new FormControl()
        });
    }
    addOperandValuesGroup(operandValue, operandValuesField) {
        const operandValuesGroup = this.generateOperandValuesGroup();
        if (operandValue) {
            operandValuesGroup.patchValue(operandValue);
        }
        operandValuesField.push(operandValuesGroup);
    }
    generateExpressionsGroup(expressions) {
        const arr = new FormArray([]);
        const result = new FormGroup({
            automationId: new FormControl(null),
            id: new FormControl(null),
            operand: new FormGroup({
                alias: new FormControl(),
                id: new FormControl(null),
                name: new FormControl()
            }),
            operandId: new FormControl(),
            operandValues: new FormArray([]),
            operator: new FormControl('AND'),
            operatorLeft: new FormControl('IN'),
            order: new FormControl(null)
        });
        const operandValues = result.get('operandValues');
        if (expressions && expressions.operandValues.length) {
            expressions.operandValues.forEach((operandValue) => {
                this.addOperandValuesGroup(operandValue, operandValues);
            });
        }
        return result;
    }
    addExpressionsGroup(expressions) {
        const expressionsGroup = this.generateExpressionsGroup(expressions);
        if (expressions) {
            expressionsGroup.patchValue(expressions);
        }
        this.expressions.push(expressionsGroup);
    }
    generateCommandsGroup() {
        return new FormGroup({
            automationId: new FormControl(),
            commandId: new FormControl(),
            sort: new FormControl(),
            command: new FormGroup({
                action: new FormGroup({
                    alias: new FormControl(),
                    id: new FormControl(null),
                    name: new FormControl()
                }),
                actionId: new FormControl(null),
                element: new FormGroup({
                    alias: new FormControl(),
                    id: new FormControl(null),
                    name: new FormControl()
                }),
                elementId: new FormControl(null),
                id: new FormControl(null)
            })
        });
    }
    addCommandsGroup(commands) {
        const commandsGroup = this.generateCommandsGroup();
        if (commands) {
            commandsGroup.patchValue(commands);
        }
        this.commands.push(commandsGroup);
    }
    generateNewCommandsGroup() {
        return new FormGroup({
            id: new FormControl(),
            name: new FormControl(),
            alias: new FormControl(),
            commandId: new FormControl()
        });
    }
    addCommandGroup(commandValue, commandsField) {
        const commandValueGroup = this.generateNewCommandsGroup();
        if (commandValue) {
            commandValueGroup.patchValue(commandValue);
        }
        commandsField.push(commandValueGroup);
    }
    generateNewActionsGroup(actions) {
        const result = new FormGroup({
            sort: new FormControl(this.newActions.value.length ? this.newActions.value.length + 1 : 1),
            action: new FormGroup({
                id: new FormControl(),
                name: new FormControl(),
                alias: new FormControl()
            }),
            commands: new FormArray([]),
            checked: new FormControl(false),
            checkedCommands: new FormControl(false)
        });
        const commandsValues = result.get('commands');
        if (actions && actions.commands.length) {
            actions.commands.forEach((commandValue) => {
                this.addCommandGroup(commandValue, commandsValues);
            });
        }
        return result;
    }
    checkOpenedModal() {
        const openModal = this.modalsList.find((el) => this[el]);
        return (openModal ||
            this.newActions.value.find((el) => el.checked || el.checkedCommands));
    }
    openActionDropdown(index) {
        const openModal = this.checkOpenedModal();
        if (openModal) {
            return;
        }
        const action = this.newActions.at(index);
        action.get('checked').setValue(true);
    }
    openCommandsDropdown(index) {
        const openModal = this.checkOpenedModal();
        if (openModal) {
            return;
        }
        const action = this.newActions.at(index);
        action.get('checkedCommands').setValue(true);
    }
    checkCommand(alias, actionIndex) {
        return this.newActions.value.find((action, index) => index !== actionIndex &&
            action.commands.some((command) => command.alias === alias));
    }
    addNewActionsGroups(actions) {
        const openModal = this.checkOpenedModal();
        if (openModal) {
            return;
        }
        const actionsGroup = this.generateNewActionsGroup(actions);
        if (actions) {
            actionsGroup.patchValue(actions);
        }
        this.newActions.push(actionsGroup);
    }
    checkOperandValues(expressionGroup, item) {
        const value = expressionGroup.value;
        return value.find((el) => el.id === item.id);
    }
    checkActionTo(action, item) {
        return action.value.commands.find((el) => el.name === item.element.name);
    }
    checkGroupsOrAreas(expressionGroup, item) {
        const value = expressionGroup.value;
        return value.find((el) => el.id === item.id);
    }
    generateName() {
        return `Resident ${this.selectedMove.value === 'moveIn' ? 'move-in' : 'move-out'}; ${this.newActions.value[0].action.name.toLowerCase()}`;
    }
    generateRulesForSubmit() {
        const currentBuildingRule = this.automationForm.controls.rules.value.find((el) => el.subject === 'building');
        const building = currentBuildingRule
            ? currentBuildingRule
            : {
                subject: 'building',
                condition: 'IN',
                values: [
                    {
                        value: this.buildingId
                    }
                ],
                fields: [
                    {
                        field: 'buildingId'
                    }
                ]
            };
        const rulesArr = [building];
        return rulesArr;
    }
    generateCommandsArray() {
        const arr = [];
        this.newActions.value.forEach((item, index) => {
            if (item && item.commands.length) {
                item.commands.forEach((el) => {
                    if (el && el.commandId) {
                        arr.push({ commandId: el.commandId, sort: index + 1 });
                    }
                });
            }
        });
        return arr;
    }
    generateSubmitBody() {
        return Object.assign({ name: this.automationForm.value.name, description: this.automationForm.value.description, companyId: this.user.company, status: this.automationId
                ? this.automationForm.controls.status.value
                : true, commands: this.generateCommandsArray() }, (!this.automationId && {
            expressions: [
                {
                    operatorLeft: 'IN',
                    operator: this.firstOperation.value,
                    order: 1,
                    operandId: operandConst['RESIDENTS_STATUS'],
                    operandValues: this.residentsStatus.value.find((el) => el.value === 'N/A')
                        ? []
                        : this.residentsStatus.value.map((el) => el.id)
                },
                {
                    operatorLeft: 'IN',
                    operator: this.secondOperation.value,
                    order: 2,
                    operandId: operandConst['APARTMENTS_STATUS'],
                    operandValues: this.apartmentStatus.value.find((el) => el.value === 'N/A')
                        ? []
                        : this.apartmentStatus.value.map((el) => el.id)
                },
                {
                    operatorLeft: 'IN',
                    operator: 'AND',
                    order: 3,
                    operandId: this.selectedMove.value === 'moveIn'
                        ? operandConst['MOVE_IN']
                        : operandConst['MOVE_OUT'],
                    operandValues: this.selectedMove.value === 'moveIn'
                        ? this.moveInDate.value.map((el) => el.id)
                        : this.moveOutDate.value.map((el) => el.id)
                }
            ]
        }), { author: this.automationForm.value.author
                ? this.automationForm.value.author
                : `${this.user.first} ${this.user.last}`, rules: this.generateRulesForSubmit() });
    }
    submitForm() {
        this.automationForm.markAllAsTouched();
        const openModal = this.checkOpenedModal();
        if (openModal) {
            return;
        }
        if (this.automationForm.valid &&
            this.residentsStatus.value.length &&
            this.apartmentStatus.value.length &&
            (this.moveInDate.value.length || this.moveOutDate.value.length) &&
            this.newActions.value.length) {
            const body = this.generateSubmitBody();
            this.store.dispatch(this.automationId
                ? new SaveAccessAutomationAction({ data: body, id: this.automationId })
                : new CreateAccessAutomationAction(body));
        }
    }
    ngOnDestroy() {
        this.subscriptions.forEach((e) => e.unsubscribe());
        this.store.dispatch(new DeleteAccessAutomationFromStoreAction());
    }
    checkIndex(index, element, name) {
        const i = this.actionTo.value.findIndex((el) => el.name === element.name);
        return i === index && name === element.alias;
    }
    checkActionIndex(index, element, alias) {
        const i = this.newActions.value.findIndex((el) => el.action.alias === element.value.action.alias);
        return i === index && alias === element.value.action.alias;
    }
    trackBy(index, item) {
        return index;
    }
    getActionsList(index) {
        let arr = [];
        this.actionsList.forEach((item) => {
            const findEl = this.newActions.value.find((el, i) => el.action.alias === item.alias);
            const elIndex = findEl
                ? this.newActions.controls.findIndex((control) => control.value.action.alias === findEl.action.alias)
                : null;
            const findAddToSystem = this.newActions.value.find((el, i) => el.action.alias === 'add_to_system');
            const findDisableResident = this.newActions.value.find((el, i) => el.action.alias === 'disable_resident');
            const indexAddToSystem = findAddToSystem
                ? this.newActions.controls.findIndex((control) => control.value.action.alias === findAddToSystem.action.alias)
                : null;
            const indexDisableResident = findDisableResident
                ? this.newActions.controls.findIndex((control) => control.value.action.alias === findDisableResident.action.alias)
                : null;
            if (this.newActions.value &&
                this.newActions.value.length &&
                ((findEl && elIndex === index) || !findEl)) {
                arr.push(item);
            }
            if (item.alias === 'add_to_system' &&
                findDisableResident &&
                indexDisableResident !== index) {
                arr = arr.filter((el) => el.alias !== 'add_to_system');
            }
            if (item.alias === 'disable_resident' &&
                findAddToSystem &&
                indexAddToSystem !== index) {
                arr = arr.filter((el) => el.alias !== 'disable_resident');
            }
        });
        return arr;
    }
    moveUp(index) {
        if (index > 0) {
            const currentAction = this.newActions.at(index);
            const currentActionSort = currentAction.get('sort');
            currentActionSort.setValue(index);
            const prevAction = this.newActions.at(index - 1);
            const prevActionSort = prevAction.get('sort');
            prevActionSort.setValue(index + 1);
            this.newActions.removeAt(index);
            this.newActions.insert(index - 1, currentAction);
        }
    }
    moveDown(index) {
        const actions = this.newActions.value;
        if (index < actions.length - 1) {
            const currentAction = this.newActions.at(index);
            const currentActionSort = currentAction.get('sort');
            currentActionSort.setValue(index + 2);
            const nextAction = this.newActions.at(index + 1);
            const nextActionSort = nextAction.get('sort');
            nextActionSort.setValue(index + 1);
            this.newActions.removeAt(index);
            this.newActions.insert(index + 1, currentAction);
        }
    }
    swap(arr, index1, index2) {
        arr = [...arr];
        const temp = arr[index1];
        arr[index1] = arr[index2];
        arr[index2] = temp;
        return arr;
    }
    deleteAction(i) {
        const openModal = this.checkOpenedModal();
        if (openModal) {
            return;
        }
        if (i === 0 && this.newActions.value.length <= 1) {
            const currentAction = this.newActions.at(i);
            currentAction.reset();
            const currentCommands = currentAction.get('commands');
            currentCommands.removeAt(0);
        }
        else {
            this.newActions.removeAt(i);
        }
    }
    checkForAddedNewAction() {
        return (this.getActionsList().length ||
            (this.newActions.value[this.newActions.value.length - 1] &&
                (!this.newActions.value[this.newActions.value.length - 1].action.name ||
                    !this.newActions.value[this.newActions.value.length - 1].commands
                        .length)));
    }
}
