import * as i0 from "./forgot-password.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./forgot-password.component";
import * as i5 from "../../services/auth/auth.service";
import * as i6 from "@angular/router";
var styles_ForgotPasswordComponent = [i0.styles];
var RenderType_ForgotPasswordComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForgotPasswordComponent, data: {} });
export { RenderType_ForgotPasswordComponent as RenderType_ForgotPasswordComponent };
function View_ForgotPasswordComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "field-state"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Email is required!"]))], null, null); }
function View_ForgotPasswordComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 29, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(2, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(4, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 20, "div", [["class", "form-group bg-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "back"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeFormContent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "walkIn-i walkIn-i-arrow"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Back"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Forgot password"])), (_l()(), i1.ɵeld(11, 0, null, null, 14, "div", [["class", "field-box with-icon"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(13, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(14, { "field-error": 0, "field-success": 1 }), (_l()(), i1.ɵeld(15, 0, null, null, 1, "p", [["class", "field-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Email"])), (_l()(), i1.ɵeld(17, 0, null, null, 5, "input", [["formControlName", "email"], ["placeholder", "Email"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 18).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 18)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 18)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(20, 671744, null, 0, i2.FormControlName, [[3, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlName]), i1.ɵdid(22, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(23, 0, null, null, 0, "i", [["class", "walkIn-i top10 walkIn-i-user clr-green"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotPasswordComponent_2)), i1.ɵdid(25, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(26, 0, null, null, 3, "div", [["class", "form-group bg-transparent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 2, "button", [["class", "btn btn-green"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Send email"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 2, 0, currVal_7); var currVal_8 = "field-box with-icon"; var currVal_9 = _ck(_v, 14, 0, (_co.form.controls["email"].invalid && _co.form.controls["email"].touched), (_co.form.controls["email"].valid && _co.form.controls["email"].touched)); _ck(_v, 13, 0, currVal_8, currVal_9); var currVal_17 = "email"; _ck(_v, 20, 0, currVal_17); var currVal_18 = (_co.form.controls["email"].invalid && _co.form.controls["email"].touched); _ck(_v, 25, 0, currVal_18); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_10 = i1.ɵnov(_v, 22).ngClassUntouched; var currVal_11 = i1.ɵnov(_v, 22).ngClassTouched; var currVal_12 = i1.ɵnov(_v, 22).ngClassPristine; var currVal_13 = i1.ɵnov(_v, 22).ngClassDirty; var currVal_14 = i1.ɵnov(_v, 22).ngClassValid; var currVal_15 = i1.ɵnov(_v, 22).ngClassInvalid; var currVal_16 = i1.ɵnov(_v, 22).ngClassPending; _ck(_v, 17, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); }); }
function View_ForgotPasswordComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "form-group bg-white"], ["style", "display: flex; justify-content: center; align-items: center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Thank you! An email has been sent with further instructions. If you haven't received it, please check your spam folder. "]))], null, null); }
export function View_ForgotPasswordComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "auth"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "r-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/images/icons/new_logo_login.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "l-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotPasswordComponent_1)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotPasswordComponent_3)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.success; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.success; _ck(_v, 10, 0, currVal_1); }, null); }
export function View_ForgotPasswordComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-forgot-password", [], null, null, null, View_ForgotPasswordComponent_0, RenderType_ForgotPasswordComponent)), i1.ɵdid(1, 114688, null, 0, i4.ForgotPasswordComponent, [i5.AuthService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForgotPasswordComponentNgFactory = i1.ɵccf("app-forgot-password", i4.ForgotPasswordComponent, View_ForgotPasswordComponent_Host_0, {}, {}, []);
export { ForgotPasswordComponentNgFactory as ForgotPasswordComponentNgFactory };
