import {EncodersRequestResponseInterface} from '../../../interfaces/encoderInterface';
import {SystemKeysActions, SystemKeysActionsUnion} from '../actions/system-keys.actions';
import {SystemKeyInterface} from '../../interfaces/system-keys.interface';

export interface SystemKeysState {
  encoders: EncodersRequestResponseInterface;
  key: SystemKeyInterface;
}

const initialState: SystemKeysState = {
  encoders: null,
  key: null
};

export function systemKeysReducer(state: SystemKeysState = initialState, action: SystemKeysActionsUnion) {
  switch (action.type) {
    case SystemKeysActions.GetEncodersSuccess: {
      return {
        ...state,
        encoders: action.payload
      };
    }
    case SystemKeysActions.ReadKeySuccess: {
      return {
        ...state,
        key: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
