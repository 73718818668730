import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-apple-app-site-association',
  templateUrl: './apple-app-site-association.component.html',
  styleUrls: ['./apple-app-site-association.component.sass']
})
export class AppleAppSiteAssociationComponent implements OnInit {
  public text;
  public textArr = []

  constructor(
    private http: HttpClient
  ) {
    this.loadText();
  }

  ngOnInit() {
  }

  public loadText() {
    this.http.get('apple-app-site-association.txt', {responseType: 'text'}).subscribe(data => {
      this.text = data;
    })
  }
}
