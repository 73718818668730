import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalService} from '../../services/modal.service';
import {Subject} from 'rxjs';
import {ModalOptionsInterface} from '../../interfaces/modal.interface';
import {Router} from '@angular/router';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.sass']
})
export class ConfirmationModalComponent implements OnInit, OnDestroy {

  public events: Subject<any> = new Subject<any>();
  public defaultMessage: string = 'Changes are not saved. Are you really want to exit?';
  public options: ModalOptionsInterface;
  public modalService: ModalService;
  public message;

  constructor(private router: Router) {
  }

  ngOnInit() {
    if (this.router.url.includes('prospects/apartments' || 'residents/apartments')) {
      if (!this.router.url.includes('manual')) {
        this.message = 'Need add an apartment to the building';
      }
    }
  }

  ngOnDestroy(): void {
    this.events = null;
  }

  public cancel = () => {
    this.events.next(false);
    this.modalService.closeModal();
  };

  public confirm = () => {
    this.events.next(true);
    this.modalService.closeModal();
  };

}
