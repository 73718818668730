import * as i0 from "./confirmation-modal.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./confirmation-modal.component";
import * as i4 from "@angular/router";
var styles_ConfirmationModalComponent = [i0.styles];
var RenderType_ConfirmationModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationModalComponent, data: {} });
export { RenderType_ConfirmationModalComponent as RenderType_ConfirmationModalComponent };
function View_ConfirmationModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-sm btn-green"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirm"]))], null, null); }
export function View_ConfirmationModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "confirmation-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "content-wrapper"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "apartment-modal": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["class", "btn-wrap"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(10, { "one-button": 0 }), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "btn btn-sm btn-grey"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationModalComponent_1)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "content-wrapper"; var currVal_1 = _ck(_v, 4, 0, _co.message); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = "btn-wrap"; var currVal_4 = _ck(_v, 10, 0, ((_co.options == null) ? null : _co.options.isInfo)); _ck(_v, 9, 0, currVal_3, currVal_4); var currVal_6 = (!_co.message && !((_co.options == null) ? null : _co.options.isInfo)); _ck(_v, 15, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((((_co.options == null) ? null : _co.options.message) || _co.message) || _co.defaultMessage); _ck(_v, 6, 0, currVal_2); var currVal_5 = (((_co.options == null) ? null : _co.options.isInfo) ? "Back" : "Cancel"); _ck(_v, 13, 0, currVal_5); }); }
export function View_ConfirmationModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirmation-modal", [], null, null, null, View_ConfirmationModalComponent_0, RenderType_ConfirmationModalComponent)), i1.ɵdid(1, 245760, null, 0, i3.ConfirmationModalComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmationModalComponentNgFactory = i1.ɵccf("app-confirmation-modal", i3.ConfirmationModalComponent, View_ConfirmationModalComponent_Host_0, {}, {}, []);
export { ConfirmationModalComponentNgFactory as ConfirmationModalComponentNgFactory };
