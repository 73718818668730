import { AuthService } from './auth.service';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { GetProfileSuccessAction } from '../../Store/actions/profile.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "./auth.service";
export class AuthGuard {
    constructor(store, auth) {
        this.store = store;
        this.auth = auth;
    }
    canActivate(route, state) {
        return this.auth.getProfile().pipe(tap(profile => {
            this.store.dispatch(new GetProfileSuccessAction(profile));
        }), map(Boolean));
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.AuthService)); }, token: AuthGuard, providedIn: "root" });
