import {Action} from '@ngrx/store';
import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {
  AccessLevelInterface,
  AccessLevelsRequestResponseInterface,
  SaveAccessLevelInterface
} from '../../interfaces/access-level.interface';
import {
  TenantsItemAvailableDoorsRequestResponseInterface, TenantsItemAvailableZonesRequestResponseInterface
} from "../../../residents/interfaces/tenants.interface";

export enum AccessLevelsActions {
  GetAccessLevels = '[Access Levels] Get Access Levels',
  GetAccessLevelsSuccess = '[Access Levels] Get Access Levels Success',
  UpdateAccessLevelsSearchQuery = '[Access Levels] Update Access Levels Search Query',
  GetAccessLevel = '[Access Levels Item] Get Access Level',
  GetAccessLevelSuccess = '[Access Levels Item] Get Access Level Success',
  SaveAccessLevel = '[Access Levels Item] Save Access Level',
  DeleteAccessLevelFromStore = '[Access Levels Item] Delete Access Level From Store',
  CreateAccessLevel = '[Access Levels Item] Create Access Level',
  DeleteAccessLevel = '[Access Levels Item] Delete Access Level',

  GetAccessLevelAvailableDoors = '[Access Level Item] Get Access Level Available Doors',
  GetAccessLevelAvailableDoorsSuccess = '[Access Level Item] Get Access Level Available Doors Success',
  UpdateAccessLevelAvailableDoorsSearchQuery = '[Access Level Item] Update Access Level Available Doors Search Query',
  DeleteFromStoreAvailableDoors = '[Access Level Item] Delete From Store Available Doors',

  GetAccessLevelAvailableDoorsAll = '[Access Level Item] Get Access Level Available Doors All',
  GetAccessLevelAvailableDoorsAllSuccess = '[Access Level Item] Get Access Level Available Doors All Success',
  UpdateAccessLevelAvailableDoorsAllSearchQuery = '[Access Level Item] Update Access Level Available Doors All Search Query',
  DeleteFromStoreAvailableDoorsAll = '[Access Level Item] Delete From Store Available Doors All',

  GetAccessLevelAvailableZones = '[Access Level Item] Get Access Level Available Zones',
  GetAccessLevelAvailableZonesSuccess = '[Access Level Item] Get Access Level Available Zones Success',
  UpdateAccessLevelAvailableZonesSearchQuery = '[Access Level Item] Update Access Level Available Zones Search Query',
  DeleteFromStoreAvailableZones = '[Access Level Item] Delete From Store Available Zones',

  GetAccessLevelAvailableZonesAll = '[Access Level Item] Get Access Level Available Zones All',
  GetAccessLevelAvailableZonesAllSuccess = '[Access Level Item] Get Access Level Available Zones All Success',
  UpdateAccessLevelAvailableZonesAllSearchQuery = '[Access Level Item] Update Access Level Available Zones All Search Query',
  DeleteFromStoreAvailableZonesAll = '[Access Level Item] Delete From Store Available Zones All',


}

export class GetAccessLevelsAction implements Action {
  readonly type = AccessLevelsActions.GetAccessLevels;

  constructor(public payload: QueryParamsInterface) {
  }
}

export class GetAccessLevelsSuccessAction implements Action {
  readonly type = AccessLevelsActions.GetAccessLevelsSuccess;

  constructor(public payload: AccessLevelsRequestResponseInterface) {
  }
}

export class UpdateAccessLevelsSearchQueryAction implements Action {
  readonly type = AccessLevelsActions.UpdateAccessLevelsSearchQuery;

  constructor(public payload: QueryParamsInterface) {
  }

}

export class GetAccessLevelAction implements Action {
  readonly type = AccessLevelsActions.GetAccessLevel;

  constructor(public payload: number) {
  }
}

export class GetAccessLevelSuccessAction implements Action {
  readonly type = AccessLevelsActions.GetAccessLevelSuccess;

  constructor(public payload: AccessLevelInterface) {
  }
}

export class SaveAccessLevelAction implements Action {
  readonly type = AccessLevelsActions.SaveAccessLevel;

  constructor(public payload: SaveAccessLevelInterface) {
  }
}

export class DeleteAccessLevelFormStoreAction implements Action {
  readonly type = AccessLevelsActions.DeleteAccessLevelFromStore;

  constructor(public payload?) {
  }
}

export class CreateAccessLevelAction implements Action {
  readonly type = AccessLevelsActions.CreateAccessLevel;

  constructor(public payload: AccessLevelInterface) {
  }
}

export class DeleteAccessLevelAction implements Action {
  readonly type = AccessLevelsActions.DeleteAccessLevel;

  constructor(public payload: number) {
  }
}

export class GetAccessLevelAvailableDoorsAction implements Action {
  readonly type: string = AccessLevelsActions.GetAccessLevelAvailableDoors;

  constructor(public payload: any) {
  }
}

export class DeleteFromStoreAccessLevelAvailableDoors implements Action {
  readonly type: string = AccessLevelsActions.DeleteFromStoreAvailableDoors;

  constructor(public payload?) {
  }
}

export class GetAccessLevelAvailableDoorsSuccessAction implements Action {
  readonly type: string = AccessLevelsActions.GetAccessLevelAvailableDoorsSuccess;

  constructor(public payload: TenantsItemAvailableDoorsRequestResponseInterface) {
  }
}

export class UpdateAccessLevelAvailableDoorsSearchQueryAction implements Action {
  readonly type: string = AccessLevelsActions.UpdateAccessLevelAvailableDoorsSearchQuery;

  constructor(public payload: QueryParamsInterface) {
  }
}

export class GetAccessLevelAvailableDoorsAllAction implements Action {
  readonly type: string = AccessLevelsActions.GetAccessLevelAvailableDoorsAll;

  constructor(public payload?: any) {
  }
}

export class DeleteFromStoreAccessLevelAvailableDoorsAll implements Action {
  readonly type: string = AccessLevelsActions.DeleteFromStoreAvailableDoorsAll;

  constructor(public payload?) {
  }
}

export class GetAccessLevelAvailableDoorsAllSuccessAction implements Action {
  readonly type: string = AccessLevelsActions.GetAccessLevelAvailableDoorsAllSuccess;

  constructor(public payload: TenantsItemAvailableDoorsRequestResponseInterface) {
  }
}

export class UpdateAccessLevelAvailableDoorsAllSearchQueryAction implements Action {
  readonly type: string = AccessLevelsActions.UpdateAccessLevelAvailableDoorsAllSearchQuery;

  constructor(public payload: QueryParamsInterface) {
  }
}

export class GetAccessLevelAvailableZonesAction implements Action {
  readonly type: string = AccessLevelsActions.GetAccessLevelAvailableZones;

  constructor(public payload: any) {
  }
}

export class DeleteFromStoreAccessLevelAvailableZones implements Action {
  readonly type: string = AccessLevelsActions.DeleteFromStoreAvailableZones;

  constructor(public payload?) {
  }
}

export class GetAccessLevelAvailableZonesSuccessAction implements Action {
  readonly type: string = AccessLevelsActions.GetAccessLevelAvailableZonesSuccess;

  constructor(public payload: TenantsItemAvailableZonesRequestResponseInterface) {
  }
}

export class UpdateAccessLevelAvailableZonesSearchQueryAction implements Action {
  readonly type: string = AccessLevelsActions.UpdateAccessLevelAvailableZonesSearchQuery;

  constructor(public payload: QueryParamsInterface) {
  }
}

export class GetAccessLevelAvailableZonesAllAction implements Action {
  readonly type: string = AccessLevelsActions.GetAccessLevelAvailableZonesAll;

  constructor(public payload?: any) {
  }
}

export class DeleteFromStoreAccessLevelAvailableZonesAll implements Action {
  readonly type: string = AccessLevelsActions.DeleteFromStoreAvailableZonesAll;

  constructor(public payload?) {
  }
}

export class GetAccessLevelAvailableZonesAllSuccessAction implements Action {
  readonly type: string = AccessLevelsActions.GetAccessLevelAvailableZonesAllSuccess;

  constructor(public payload: TenantsItemAvailableZonesRequestResponseInterface) {
  }
}

export class UpdateAccessLevelAvailableZonesAllSearchQueryAction implements Action {
  readonly type: string = AccessLevelsActions.UpdateAccessLevelAvailableZonesAllSearchQuery;

  constructor(public payload: QueryParamsInterface) {
  }
}




export type AccessLevelsActionsUnion =
  GetAccessLevelsAction
  | GetAccessLevelsSuccessAction
  | UpdateAccessLevelsSearchQueryAction
  | GetAccessLevelAction
  | GetAccessLevelSuccessAction
  | SaveAccessLevelAction
  | DeleteAccessLevelFormStoreAction
  | CreateAccessLevelAction
  | DeleteAccessLevelAction
  | GetAccessLevelAvailableDoorsAction
  | GetAccessLevelAvailableDoorsSuccessAction
  | UpdateAccessLevelAvailableDoorsSearchQueryAction
  | DeleteFromStoreAccessLevelAvailableDoors
  | GetAccessLevelAvailableDoorsAllAction
  | GetAccessLevelAvailableDoorsAllSuccessAction
  | UpdateAccessLevelAvailableDoorsAllSearchQueryAction
  | DeleteFromStoreAccessLevelAvailableDoorsAll
  | GetAccessLevelAvailableZonesAction
  | GetAccessLevelAvailableZonesSuccessAction
  | UpdateAccessLevelAvailableZonesSearchQueryAction
  | DeleteFromStoreAccessLevelAvailableZones
  | GetAccessLevelAvailableZonesAllAction
  | GetAccessLevelAvailableZonesAllSuccessAction
  | UpdateAccessLevelAvailableZonesAllSearchQueryAction
  | DeleteFromStoreAccessLevelAvailableZonesAll
  ;
