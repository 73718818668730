import { ApiService } from '../../../services/api.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/api.service";
const url = 'prospects/agentdashboard';
const apartments = 'apartments';
const visitors = 'visitors';
const body = {
    "channelSid": "CH9152e06d4bac4c92a102e7a2f6ebe707",
    "from": "prospective_tenant/1/prospect.four@example.com",
    "body": "Hi !!!!!!!!!"
};
const body2 = {
    "channelSid": "CHecfba374752745059a7d10c8e3142b6d",
    "from": "prospective_tenant/1/prospect.four@example.com",
    "body": "Hello !!!!!!!!!"
};
export class ProspectsAgentDashboardService {
    constructor(api) {
        this.api = api;
        this.getApartments = (query = { page: 1, limit: 10 }) => this.api.call('GET', `${url}/${apartments}`, null, query);
        this.getVisitors = ({ id, query }) => this.api.call('GET', `${url}/${visitors}/${id}`, null, query);
        this.getApartment = (id) => this.api.call('GET', `${url}/${visitors}/${id}`);
        this.getToken = () => this.api.call('POST', 'chat/token', { 'deviceId': 'desktop', 'deviceType': 'Web' });
        this.createChat = (userId, userIdentity, apartmentId) => this.api.call('POST', 'chat/create', { 'userId': userId, 'userIdentity': userIdentity, 'apartmentId': apartmentId });
        this.getPushToken = (token) => this.api.call('POST', `push/register`, { 'token': token });
        this.deletePushToken = (token) => this.api.call('DELETE', `push/unregister`, { 'token': token });
        this.getStatistic = () => this.api.call('GET', `${url}/${apartments}/statistic`);
        this.getCheckinChats = () => this.api.call('GET', `${url}/self-tour/`);
        this.getActiveChats = () => this.api.call('GET', `${url}/self-tour/active`);
        this.sendMeMess = () => this.api.call('POST', 'chat/message', body);
        this.sendMeMess2 = () => this.api.call('POST', 'chat/message', body2);
        this.disableChat = (sid, mute, ptKeyId) => this.api.call('POST', 'chat/update', { 'conversationSid': sid, 'muted': mute, ptKeyId });
    }
}
ProspectsAgentDashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProspectsAgentDashboardService_Factory() { return new ProspectsAgentDashboardService(i0.ɵɵinject(i1.ApiService)); }, token: ProspectsAgentDashboardService, providedIn: "root" });
