import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {AccessAutomationsState} from '../reducers/access-automations.reducer';
import {Store} from '@ngrx/store';
import {AccessAutomationsService} from '../../services/access-automations.service';
import {Router} from '@angular/router';
import {NotifierService} from 'angular-notifier';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import {
  AccessAutomationsActions,
  AddNewOperandValueAction,
  ConnectAccessAutomationOperandValueAction,
  ConnectAccessAutomationOperandValueSuccessAction,
  CreateAccessAutomationAction,
  CreateExpressionAction,
  CreateExpressionSuccessAction,
  DeleteAccessAutomationAction,
  DeleteAccessAutomationFromTableAction,
  DeleteExpressionAction,
  DeleteExpressionSuccessAction,
  DisableAccessAutomationAction,
  DisconnectAccessAutomationOperandValueAction,
  DisconnectAccessAutomationOperandValueSuccessAction,
  EnableAccessAutomationAction,
  EnableAccessAutomationSuccessAction,
  EnableDisableAccessAutomationAction,
  GetAccessAutomationAction,
  GetAccessAutomationActionAction,
  GetAccessAutomationActionSuccessAction,
  GetAccessAutomationAllActionsAction,
  GetAccessAutomationAllActionsSuccessAction,
  GetAccessAutomationAllOperandsAction,
  GetAccessAutomationAllOperandsSuccessAction,
  GetAccessAutomationApartmentsOperandValuesAction,
  GetAccessAutomationApartmentsOperandValuesSuccessAction,
  GetAccessAutomationBuildingCommonAreaAction,
  GetAccessAutomationBuildingCommonAreaSuccessAction,
  GetAccessAutomationLeasingUalsAction,
  GetAccessAutomationLeasingUalsSuccessAction,
  GetAccessAutomationMoveInOperandValuesAction,
  GetAccessAutomationMoveInOperandValuesSuccessAction,
  GetAccessAutomationMoveOutOperandValuesAction,
  GetAccessAutomationMoveOutOperandValuesSuccessAction,
  GetAccessAutomationResidentsOperandValuesAction,
  GetAccessAutomationResidentsOperandValuesSuccessAction,
  GetAccessAutomationsAction,
  GetAccessAutomationsSuccessAction,
  GetAccessAutomationSuccessAction,
  GetAccessAutomationVacancyUalsAction,
  GetAccessAutomationVacancyUalsSuccessAction,
  RemoveOperandValueAction,
  SaveAccessAutomationAction,
  SecondEnableAccessAutomationAction,
  UpdateAccessAutomationsSearchQueryAction,
  UpdateExpressionOperatorAction,
  UpdateExpressionOperatorSuccessAction
} from '../actions/access-automations.actions';
import {UpdateLoadingStatusAction} from '../../../../Store/actions/profile.actions';
import {
  AccessAutomationsTableInterface,
  AccessAutomationsRequestResponseInterface,
  AccessAutomationOperandValueInterface,
  AccessAutomationActionInterface,
  ElementInterface,
  AccessAutomationOperandsInterface,
  AccessAutomationInterface,
  AccessAutomationExpressionInterface,
  BuildingCommonAreaInterface,
  UalsInterface
} from '../../interfaces/access-automations.interface';
import {
  dashboardSystemAccessAutomationsQuerySelectUrl,
  dashboardSystemBuildingDataSelectUrl
} from '../../constants/constants';
import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import * as moment from 'moment';
import {ModalService} from '../../../../modal/services/modal.service';
import {GetTenantNoteAction} from '../../../residents/Store/actions/tenants.actions';
import {of} from 'rxjs';
@Injectable()
export class AccessAutomationsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AccessAutomationsState>,
    private service: AccessAutomationsService,
    private router: Router,
    private notifierService: NotifierService,
    private modalService: ModalService
  ) {}

  @Effect() updateAccessAutomationsSearchQuery = this.actions$.pipe(
    ofType(AccessAutomationsActions.UpdateAccessAutomationsSearchQuery),
    map(
      (action: UpdateAccessAutomationsSearchQueryAction) =>
        new GetAccessAutomationsAction(action.payload)
    ),

    catchError((err, caught) => caught)
  );

  @Effect() getAccessAutomations = this.actions$.pipe(
    ofType(AccessAutomationsActions.GetAccessAutomations),
    switchMap((action: GetAccessAutomationsAction) => {
      this.store.dispatch(new UpdateLoadingStatusAction(true));
      return this.service.getAccessAutomations(action.payload).pipe(
        map((response: any) => {
          response.data = response.data.map((automation: any) => {
            return {
              ...automation,
              updatedAgo: moment(automation.updatedAt).fromNow()
            };
          });
          return response;
        })
      );
    }),
    map((response: AccessAutomationsRequestResponseInterface) => {
      this.store.dispatch(new UpdateLoadingStatusAction(false));
      return new GetAccessAutomationsSuccessAction(response);
    }),

    catchError((err, caught) => caught)
  );

  @Effect() getAccessAutomation = this.actions$.pipe(
    ofType(AccessAutomationsActions.GetAccessAutomation),
    switchMap((action: GetAccessAutomationAction) =>
      this.service.getAccessAutomation(action.payload)
    ),
    map(
      (response: AccessAutomationInterface) =>
        new GetAccessAutomationSuccessAction(response)
    ),

    catchError((err, caught) => {
      // if (err && err.error && err.error.error.length && err.error.error[0].type === 'wrong_company') {
      //   this.router.navigate([`dashboard/system/access-level`]);
      // }
      return caught;
    })
  );

  @Effect() saveAccessAutomation = this.actions$.pipe(
    ofType(AccessAutomationsActions.SaveAccessAutomation),
    switchMap((action: SaveAccessAutomationAction) =>
      this.service.updateAccessAutomation(
        action.payload.data,
        action.payload.id
      )
    ),
    tap((response: any) => {
      this.modalService.closeModal();
      return this.notifierService.notify(
        'success',
        response.message || `Automation '${response.name}' is updated`
      );
    }),
    // tap(() => this.router.navigate(['dashboard/settings/access-automations'])),
    // map(() => new DeleteAccessAutomationFromStoreAction()),
    concatMap(() => [
      this.store.select(...dashboardSystemAccessAutomationsQuerySelectUrl)
    ]),
    switchMap((queryParams) => queryParams),
    map((query: QueryParamsInterface) => new GetAccessAutomationsAction(query)),

    catchError((err, caught) => caught)
  );

  @Effect() createAccessAutomation = this.actions$.pipe(
    ofType(AccessAutomationsActions.CreateAccessAutomation),
    switchMap((action: CreateAccessAutomationAction) =>
      this.service.createAccessAutomation(action.payload)
    ),
    tap((response: any) => {
      this.modalService.closeModal();
      return this.notifierService.notify(
        'success',
        response.message || `Automation '${response.name}' is created`
      );
    }),
    // tap(() => this.router.navigate(['dashboard/settings/access-automations'])),
    // map(() => new DeleteAccessAutomationFromStoreAction()),
    concatMap(() => [
      this.store.select(...dashboardSystemAccessAutomationsQuerySelectUrl)
    ]),
    switchMap((queryParams) => queryParams),
    map((query: QueryParamsInterface) => new GetAccessAutomationsAction(query)),

    catchError((err, caught) => caught)
  );

  @Effect() deleteAccessAutomation = this.actions$.pipe(
    ofType(AccessAutomationsActions.DeleteAccessAutomation),
    switchMap((action: DeleteAccessAutomationAction) =>
      this.service.deleteAccessAutomation(action.payload)
    ),
    tap(({message}: any) => this.notifierService.notify('success', message)),
    // tap(() => this.router.navigate(['dashboard/settings/access-automations'])),
    // map(() => new DeleteAccessAutomationFromStoreAction()),
    // concatMap(() => [
    //   this.store.select(...dashboardSystemAccessAutomationsQuerySelectUrl)
    // ]),
    switchMap((queryParams) => queryParams),
    map((query: QueryParamsInterface) => new GetAccessAutomationsAction(query)),

    catchError((err, caught) => caught)
  );

  @Effect() deleteAccessAutomationFromTable = this.actions$.pipe(
    ofType(AccessAutomationsActions.DeleteAccessAutomationFromTable),
    switchMap((action: DeleteAccessAutomationFromTableAction) =>
      this.service.deleteAccessAutomation(action.payload)
    ),
    tap(() => this.notifierService.notify('success', `Automation is deleted`)),
    concatMap(() => [
      this.store.select(...dashboardSystemAccessAutomationsQuerySelectUrl)
    ]),
    switchMap((queryParams) => queryParams),
    map((query: QueryParamsInterface) => new GetAccessAutomationsAction(query)),
    catchError((err, caught) => caught)
  );

  @Effect() enableDisableAccessAutomation = this.actions$.pipe(
    ofType(AccessAutomationsActions.EnableDisableAccessAutomation),
    switchMap((action: EnableDisableAccessAutomationAction) =>
      this.service.enableAccessAutomation(
        action.payload.id,
        action.payload.status
      )
    ),
    tap((response: any) =>
      this.notifierService.notify(
        'success',
        response.message || `Automation '${response.name}' is updated`
      )
    ),
    concatMap(() => [
      this.store.select(...dashboardSystemAccessAutomationsQuerySelectUrl)
    ]),
    switchMap((queryParams) => queryParams),
    map((query: QueryParamsInterface) => new GetAccessAutomationsAction(query)),
    catchError((err, caught) => caught)
  );

  @Effect() enableAccessAutomation = this.actions$.pipe(
    ofType(AccessAutomationsActions.EnableAccessAutomation),
    switchMap((action: EnableAccessAutomationAction) => {
      return of([
        this.service.enableDisableAccessAutomation(
          action.payload.id,
          action.payload.status
        )
      ]);
    }),
    tap((obs: any) => {
      let building;
      this.store
        .select(...dashboardSystemBuildingDataSelectUrl)
        .subscribe((item) => {
          if (item) {
            building = item;
          }
        });
      return obs[0].subscribe(() => {
        return this.store.dispatch(
          new SecondEnableAccessAutomationAction({
            companyId: building.company || null,
            rules: [
              {
                subject: 'building',
                condition: 'IN',
                values: [
                  {
                    value:
                      building && building.buildingId
                        ? building.buildingId.toString()
                        : null
                  }
                ],
                fields: [
                  {
                    field: 'buildingId'
                  }
                ]
              }
            ]
          })
        );
      });
    }),
    map(() => new EnableAccessAutomationSuccessAction()),
    // concatMap(() => [
    //   this.store.select(...dashboardSystemAccessAutomationsQuerySelectUrl)
    // ]),
    // switchMap((queryParams) => queryParams),
    // map((query: QueryParamsInterface) => new GetAccessAutomationsAction(query)),
    catchError((err, caught) => caught)
  );

  @Effect() secondEnableAccessAutomation = this.actions$.pipe(
    ofType(AccessAutomationsActions.SecondEnableAccessAutomation),
    switchMap((action: SecondEnableAccessAutomationAction) => {
      return this.service.secondEnableAutomation(action.payload);
    }),
    tap((response: any) =>
      this.notifierService.notify(
        'success',
        response.message || `Automation has been updated`
      )
    ),
    concatMap(() => [
      this.store.select(...dashboardSystemAccessAutomationsQuerySelectUrl)
    ]),
    switchMap((queryParams) => queryParams),
    map((query: QueryParamsInterface) => new GetAccessAutomationsAction(query)),
    catchError((err, caught) => caught)
  );

  @Effect() disableAccessAutomation = this.actions$.pipe(
    ofType(AccessAutomationsActions.DisableAccessAutomation),
    switchMap((action: DisableAccessAutomationAction) =>
      this.service.enableDisableAccessAutomation(
        action.payload.id,
        action.payload.status
      )
    ),
    tap((response: any) =>
      this.notifierService.notify(
        'success',
        response.message || `Automation '${response.name}' is updated`
      )
    ),
    concatMap(() => [
      this.store.select(...dashboardSystemAccessAutomationsQuerySelectUrl)
    ]),
    switchMap((queryParams) => queryParams),
    map((query: QueryParamsInterface) => new GetAccessAutomationsAction(query)),
    catchError((err, caught) => caught)
  );

  // @Effect() getAccessAutomationResidentsOperandValues = this.actions$.pipe(
  //   ofType(AccessAutomationsActions.GetAccessAutomationResidentsOperandValues),
  //   switchMap((action: GetAccessAutomationResidentsOperandValuesAction) =>
  //     this.service.getOperandValues(action.payload)
  //   ),
  //   map(
  //     (response: AccessAutomationOperandValueInterface[]) =>
  //       new GetAccessAutomationResidentsOperandValuesSuccessAction(response)
  //   ),
  //
  //   catchError((err, caught) => caught)
  // );
  //
  // @Effect() getAccessAutomationApartmentsOperandValues = this.actions$.pipe(
  //   ofType(AccessAutomationsActions.GetAccessAutomationApartmentsOperandValues),
  //   switchMap((action: GetAccessAutomationApartmentsOperandValuesAction) =>
  //     this.service.getOperandValues(action.payload)
  //   ),
  //   map(
  //     (response: AccessAutomationOperandValueInterface[]) =>
  //       new GetAccessAutomationApartmentsOperandValuesSuccessAction(response)
  //   ),
  //
  //   catchError((err, caught) => caught)
  // );
  //
  // @Effect() getAccessAutomationMoveInOperandValues = this.actions$.pipe(
  //   ofType(AccessAutomationsActions.GetAccessAutomationMoveInOperandValues),
  //   switchMap((action: GetAccessAutomationMoveInOperandValuesAction) =>
  //     this.service.getOperandValues(action.payload)
  //   ),
  //   map(
  //     (response: AccessAutomationOperandValueInterface[]) =>
  //       new GetAccessAutomationMoveInOperandValuesSuccessAction(response)
  //   ),
  //
  //   catchError((err, caught) => caught)
  // );
  //
  // @Effect() getAccessAutomationMoveOutOperandValues = this.actions$.pipe(
  //   ofType(AccessAutomationsActions.GetAccessAutomationMoveOutOperandValues),
  //   switchMap((action: GetAccessAutomationMoveOutOperandValuesAction) =>
  //     this.service.getOperandValues(action.payload)
  //   ),
  //   map(
  //     (response: AccessAutomationOperandValueInterface[]) =>
  //       new GetAccessAutomationMoveOutOperandValuesSuccessAction(response)
  //   ),
  //
  //   catchError((err, caught) => caught)
  // );

  @Effect() getAccessAutomationAllActions = this.actions$.pipe(
    ofType(AccessAutomationsActions.GetAccessAutomationAllActions),
    switchMap((action: GetAccessAutomationAllActionsAction) =>
      this.service.getAllActions()
    ),
    map(
      (response: ElementInterface[]) =>
        new GetAccessAutomationAllActionsSuccessAction(response)
    ),

    catchError((err, caught) => caught)
  );

  @Effect() getAccessAutomationAllOperands = this.actions$.pipe(
    ofType(AccessAutomationsActions.GetAccessAutomationAllOperands),
    switchMap((action: GetAccessAutomationAllOperandsAction) =>
      this.service.getAllOperands(action.payload)
    ),
    map(
      (response: AccessAutomationOperandsInterface[]) =>
        new GetAccessAutomationAllOperandsSuccessAction(response)
    ),

    catchError((err, caught) => caught)
  );

  @Effect() getAccessAutomationAction = this.actions$.pipe(
    ofType(AccessAutomationsActions.GetAccessAutomationAction),
    switchMap((action: GetAccessAutomationActionAction) =>
      this.service.getAction(action.payload)
    ),
    map(
      (response: AccessAutomationActionInterface) =>
        new GetAccessAutomationActionSuccessAction(response)
    ),

    catchError((err, caught) => caught)
  );

  @Effect() connectAccessAutomationOperandValue = this.actions$.pipe(
    ofType(AccessAutomationsActions.ConnectAccessAutomationOperandValue),
    concatMap((action: ConnectAccessAutomationOperandValueAction) => {
      return of([
        action.payload.data.id === 0
          ? null
          : this.service.expressionsValueConnect(
              action.payload.expressionId,
              action.payload.valueId
            ),
        action.payload.expressionId,
        action.payload.data
      ]);
    }),
    tap((obs: any) => {
      if (obs[2].id === 0) {
        return this.store.dispatch(
          new AddNewOperandValueAction({
            operandValue: obs[2],
            expressionId: obs[1]
          })
        );
      } else {
        return obs[0].subscribe(() => {
          return this.store.dispatch(
            new AddNewOperandValueAction({
              operandValue: obs[2],
              expressionId: obs[1]
            })
          );
        });
      }
    }),
    map(() => {
      return new ConnectAccessAutomationOperandValueSuccessAction();
    }),

    catchError((err, caught) => {
      // if (err && err.error && err.error.error.length && err.error.error[0].type === 'wrong_company') {
      //   this.router.navigate([`dashboard/system/access-level`]);
      // }
      return caught;
    })
  );

  @Effect() disconnectAccessAutomationOperandValue = this.actions$.pipe(
    ofType(AccessAutomationsActions.DisconnectAccessAutomationOperandValue),
    concatMap((action: DisconnectAccessAutomationOperandValueAction) => {
      return of([
        this.service.expressionsValueDisconnect(
          action.payload.expressionId,
          action.payload.valueId
        ),
        action.payload.expressionId,
        action.payload.operandId,
        action.payload.data
      ]);
    }),
    tap((obs: any) => {
      return obs[0].subscribe(() => {
        return this.store.dispatch(
          new RemoveOperandValueAction({
            operandValue: obs[3],
            expressionId: obs[1],
            operandId: obs[2]
          })
        );
      });
    }),
    map(() => new DisconnectAccessAutomationOperandValueSuccessAction()),

    catchError((err, caught) => {
      // if (err && err.error && err.error.error.length && err.error.error[0].type === 'wrong_company') {
      //   this.router.navigate([`dashboard/system/access-level`]);
      // }
      return caught;
    })
  );

  @Effect() updateExpressionOperator = this.actions$.pipe(
    ofType(AccessAutomationsActions.UpdateExpressionOperator),
    switchMap((action: UpdateExpressionOperatorAction) =>
      this.service.updateExpressions(
        action.payload.expressionId,
        action.payload.data
      )
    ),
    map((response: AccessAutomationExpressionInterface) => {
      return new UpdateExpressionOperatorSuccessAction(response);
    }),
    // tap(() => this.router.navigate(['dashboard/settings/access-automations'])),
    // map(() => new DeleteAccessAutomationFromStoreAction()),

    catchError((err, caught) => caught)
  );

  @Effect() createExpression = this.actions$.pipe(
    ofType(AccessAutomationsActions.CreateExpression),
    switchMap((action: CreateExpressionAction) => {
      return this.service.createExpression(action.payload.data);
    }),
    map((response: AccessAutomationExpressionInterface) => {
      return new CreateExpressionSuccessAction(response);
    }),
    catchError((err, caught) => {
      // if (err && err.error && err.error.error.length && err.error.error[0].type === 'wrong_company') {
      //   this.router.navigate([`dashboard/system/access-level`]);
      // }
      return caught;
    })
  );

  @Effect() deleteExpression = this.actions$.pipe(
    ofType(AccessAutomationsActions.DeleteExpression),
    concatMap((action: DeleteExpressionAction) => {
      return of([
        this.service.deleteExpression(action.payload.id),
        action.payload.automationId,
        action.payload.operandId
      ]);
    }),
    tap((obs: any) => {
      return obs[0].subscribe(() => {
        return this.store.dispatch(
          new CreateExpressionAction({
            data: {
              operator: 'AND',
              operatorLeft: 'IN',
              automationId: obs[1],
              order: 3,
              operandId: obs[2] === 3 ? 4 : 3
            },
            automationId: obs[1],
            operandId: obs[2]
          })
        );
      });
    }),
    map((obs: any) => {
      return new DeleteExpressionSuccessAction(obs[2]);
    }),

    catchError((err, caught) => {
      // if (err && err.error && err.error.error.length && err.error.error[0].type === 'wrong_company') {
      //   this.router.navigate([`dashboard/system/access-level`]);
      // }
      return caught;
    })
  );

  @Effect() getAccessAutomationBuildingCommonArea = this.actions$.pipe(
    ofType(AccessAutomationsActions.GetAccessAutomationBuildingCommonArea),
    switchMap((action: GetAccessAutomationBuildingCommonAreaAction) =>
      this.service.getCommonAreaAccessDoors({
        buildingId: action.payload.buildingId,
        query: null
      })
    ),
    map(
      (response: BuildingCommonAreaInterface) =>
        new GetAccessAutomationBuildingCommonAreaSuccessAction(response)
    ),

    catchError((err, caught) => caught)
  );

  @Effect() GetAccessAutomationLeasingUals = this.actions$.pipe(
    ofType(AccessAutomationsActions.GetAccessAutomationLeasingUals),
    concatMap((action: GetAccessAutomationLeasingUalsAction) =>
      this.service.getCommonAreaAccessUals({
        buildingId: action.payload.buildingId,
        query: {search: 'leasing'}
      })
    ),
    map(
      (response: UalsInterface) =>
        new GetAccessAutomationLeasingUalsSuccessAction(response)
    ),

    catchError((err, caught) => caught)
  );

  @Effect() GetAccessAutomationVacancyUals = this.actions$.pipe(
    ofType(AccessAutomationsActions.GetAccessAutomationVacancyUals),
    concatMap((action: GetAccessAutomationVacancyUalsAction) =>
      this.service.getCommonAreaAccessUals({
        buildingId: action.payload.buildingId,
        query: {search: 'vacancy'}
      })
    ),
    map(
      (response: UalsInterface) =>
        new GetAccessAutomationVacancyUalsSuccessAction(response)
    ),

    catchError((err, caught) => caught)
  );
}
