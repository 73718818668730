import {Action} from '@ngrx/store';
import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {
  AccessAutomationsTableInterface,
  AccessAutomationsRequestResponseInterface,
  SaveAccessAutomationInterface,
  AccessAutomationOperandValueInterface,
  AccessAutomationActionInterface,
  ElementInterface,
  SaveAccessAutomationDataInterface,
  AccessAutomationOperandsInterface,
  AccessAutomationInterface,
  AccessAutomationExpressionInterface,
  BuildingCommonAreaInterface,
  UalsInterface
} from '../../interfaces/access-automations.interface';

export enum AccessAutomationsActions {
  GetAccessAutomations = '[Access Automations] Get Access Automations',
  GetAccessAutomationsSuccess = '[Access Automations] Get Access Automations Success',
  UpdateAccessAutomationsSearchQuery = '[Access Automations] Update Access Automations Search Query',

  GetAccessAutomation = '[Access Automations] Get Access Automation',
  GetAccessAutomationSuccess = '[Access Automations] Get Access Automation Success',
  CreateAccessAutomation = '[Access Automations] Create Access Automation',
  SaveAccessAutomation = '[Access Automations] Save Access Automation',
  EnableDisableAccessAutomation = '[Access Automations] Enable Disable Access Automation',

  EnableAccessAutomation = '[Access Automations] Enable Access Automation',
  EnableAccessAutomationSuccess = '[Access Automations] Enable Access Automation Success',
  SecondEnableAccessAutomation = '[Access Automations] Second Enable Access Automation',

  DisableAccessAutomation = '[Access Automations] Disable Access Automation',
  DeleteAccessAutomation = '[Access Automations] Delete Access Automations',
  DeleteAccessAutomationFromTable = '[Access Automations] Delete Access Automations From Table',
  DeleteAccessAutomationFromStore = '[Access Automations] Delete Access Automations From Store',

  CreateExpression = '[Access Automations] Create Expression',
  CreateExpressionSuccess = '[Access Automations] Create Expression Success',
  DeleteExpression = '[Access Automations] Delete Expression',
  DeleteExpressionSuccess = '[Access Automations] Delete Expression Success',

  GetAccessAutomationAllOperandValues = '[Access Automations] Get Access Automation All Operand Values',
  GetAccessAutomationAllOperandValuesSuccess = '[Access Automations] Get Access Automation All Operand Values Success',
  GetAccessAutomationResidentsOperandValues = '[Access Automations] Get Access Automation Residents Operand Values',
  GetAccessAutomationResidentsOperandValuesSuccess = '[Access Automations] Get Access Automation Residents Operand Values Success',
  GetAccessAutomationApartmentsOperandValues = '[Access Automations] Get Access Automation Apartments Operand Values',
  GetAccessAutomationApartmentsOperandValuesSuccess = '[Access Automations] Get Access Automation Apartments Operand Values Success',
  GetAccessAutomationMoveInOperandValues = '[Access Automations] Get Access Automation Move In Operand Values',
  GetAccessAutomationMoveInOperandValuesSuccess = '[Access Automations] Get Access Automation Move In Operand Values Success',
  GetAccessAutomationMoveOutOperandValues = '[Access Automations] Get Access Automation Move Out Operand Values',
  GetAccessAutomationMoveOutOperandValuesSuccess = '[Access Automations] Get Access Automation Move Out Operand Values Success',

  ConnectAccessAutomationOperandValue = '[Access Automations] Connect Access Automation Operand Value',
  ConnectAccessAutomationOperandValueSuccess = '[Access Automations] Connect Access Automation Operand Value Success',
  AddNewOperandValue = '[Access Automation] Add New Operand Value',
  DisconnectAccessAutomationOperandValue = '[Access Automations] Disconnect Access Automation Operand Value',
  DisconnectAccessAutomationOperandValueSuccess = '[Access Automations] Disconnect Access Automation Operand Value Success',
  RemoveOperandValue = '[Access Automation] Remove Operand Value',

  GetAccessAutomationAllOperands = '[Access Automations] Get Access Automation All Operands',
  GetAccessAutomationAllOperandsSuccess = '[Access Automations] Get Access Automation All Operands Success',

  GetAccessAutomationAllActions = '[Access Automations] Get Access Automation All Actions',
  GetAccessAutomationAllActionsSuccess = '[Access Automations] Get Access Automation All Actions Success',
  GetAccessAutomationAction = '[Access Automations] Get Access Automation Action',
  GetAccessAutomationActionSuccess = '[Access Automations] Get Access Automation Action Success',

  UpdateExpressionOperator = '[Access Automations] Update Expression Operator',
  UpdateExpressionOperatorSuccess = '[Access Automations] Update Expression Operator Success',

  GetAccessAutomationBuildingCommonArea = '[Access Automations] Get Access Automation Building Common Area',
  GetAccessAutomationBuildingCommonAreaSuccess = '[Access Automations] Get Access Automation Building Common Area Success',

  GetAccessAutomationLeasingUals = '[Access Automations] Get Access Automation Leasing Uals',
  GetAccessAutomationLeasingUalsSuccess = '[Access Automations] Get Access Automation Leasing Uals Success',
  GetAccessAutomationVacancyUals = '[Access Automations] Get Access Automation Vacancy Uals',
  GetAccessAutomationVacancyUalsSuccess = '[Access Automations] Get Access Automation Vacancy Uals Success'
}

export class GetAccessAutomationsAction implements Action {
  readonly type = AccessAutomationsActions.GetAccessAutomations;

  constructor(public payload: QueryParamsInterface) {}
}
export class GetAccessAutomationsSuccessAction implements Action {
  readonly type = AccessAutomationsActions.GetAccessAutomationsSuccess;

  constructor(public payload: AccessAutomationsRequestResponseInterface) {}
}
export class UpdateAccessAutomationsSearchQueryAction implements Action {
  readonly type = AccessAutomationsActions.UpdateAccessAutomationsSearchQuery;

  constructor(public payload: QueryParamsInterface) {}
}
export class GetAccessAutomationAction implements Action {
  readonly type = AccessAutomationsActions.GetAccessAutomation;

  constructor(public payload: number) {}
}
export class GetAccessAutomationSuccessAction implements Action {
  readonly type = AccessAutomationsActions.GetAccessAutomationSuccess;

  constructor(public payload: AccessAutomationInterface) {}
}
export class CreateAccessAutomationAction implements Action {
  readonly type = AccessAutomationsActions.CreateAccessAutomation;

  constructor(public payload: SaveAccessAutomationDataInterface) {}
}
export class SaveAccessAutomationAction implements Action {
  readonly type = AccessAutomationsActions.SaveAccessAutomation;

  constructor(public payload: SaveAccessAutomationInterface) {}
}

export class EnableDisableAccessAutomationAction implements Action {
  readonly type = AccessAutomationsActions.EnableDisableAccessAutomation;

  constructor(public payload: {id: number; status: boolean}) {}
}

export class EnableAccessAutomationAction implements Action {
  readonly type = AccessAutomationsActions.EnableAccessAutomation;

  constructor(public payload: {id: number; status: boolean}) {}
}

export class EnableAccessAutomationSuccessAction implements Action {
  readonly type = AccessAutomationsActions.EnableAccessAutomationSuccess;

  constructor() {}
}

export class SecondEnableAccessAutomationAction implements Action {
  readonly type = AccessAutomationsActions.SecondEnableAccessAutomation;

  constructor(public payload?: any) {}
}

export class DisableAccessAutomationAction implements Action {
  readonly type = AccessAutomationsActions.DisableAccessAutomation;

  constructor(public payload: {id: number; status: boolean}) {}
}

export class DeleteAccessAutomationAction implements Action {
  readonly type = AccessAutomationsActions.DeleteAccessAutomation;

  constructor(public payload: number) {}
}
export class DeleteAccessAutomationFromTableAction implements Action {
  readonly type = AccessAutomationsActions.DeleteAccessAutomationFromTable;

  constructor(public payload: number) {}
}
export class DeleteAccessAutomationFromStoreAction implements Action {
  readonly type = AccessAutomationsActions.DeleteAccessAutomationFromStore;

  constructor(public payload?: number) {}
}

export class GetAccessAutomationAllOperandValuesAction implements Action {
  readonly type = AccessAutomationsActions.GetAccessAutomationAllOperandValues;

  constructor(public payload: number) {}
}
export class GetAccessAutomationAllOperandValuesSuccessAction
  implements Action {
  readonly type =
    AccessAutomationsActions.GetAccessAutomationAllOperandValuesSuccess;

  constructor(public payload: AccessAutomationOperandValueInterface[]) {}
}
export class GetAccessAutomationResidentsOperandValuesAction implements Action {
  readonly type =
    AccessAutomationsActions.GetAccessAutomationResidentsOperandValues;

  constructor(public payload: number) {}
}
export class GetAccessAutomationResidentsOperandValuesSuccessAction
  implements Action {
  readonly type =
    AccessAutomationsActions.GetAccessAutomationResidentsOperandValuesSuccess;

  constructor(public payload: AccessAutomationOperandValueInterface[]) {}
}

export class GetAccessAutomationApartmentsOperandValuesAction
  implements Action {
  readonly type =
    AccessAutomationsActions.GetAccessAutomationApartmentsOperandValues;

  constructor(public payload: number) {}
}
export class GetAccessAutomationApartmentsOperandValuesSuccessAction
  implements Action {
  readonly type =
    AccessAutomationsActions.GetAccessAutomationApartmentsOperandValuesSuccess;

  constructor(public payload: AccessAutomationOperandValueInterface[]) {}
}

export class GetAccessAutomationMoveInOperandValuesAction implements Action {
  readonly type =
    AccessAutomationsActions.GetAccessAutomationMoveInOperandValues;

  constructor(public payload: number) {}
}
export class GetAccessAutomationMoveInOperandValuesSuccessAction
  implements Action {
  readonly type =
    AccessAutomationsActions.GetAccessAutomationMoveInOperandValuesSuccess;

  constructor(public payload: AccessAutomationOperandValueInterface[]) {}
}

export class GetAccessAutomationMoveOutOperandValuesAction implements Action {
  readonly type =
    AccessAutomationsActions.GetAccessAutomationMoveOutOperandValues;

  constructor(public payload: number) {}
}
export class GetAccessAutomationMoveOutOperandValuesSuccessAction
  implements Action {
  readonly type =
    AccessAutomationsActions.GetAccessAutomationMoveOutOperandValuesSuccess;

  constructor(public payload: AccessAutomationOperandValueInterface[]) {}
}
export class GetAccessAutomationAllActionsAction implements Action {
  readonly type = AccessAutomationsActions.GetAccessAutomationAllActions;

  constructor() {}
}
export class GetAccessAutomationAllActionsSuccessAction implements Action {
  readonly type = AccessAutomationsActions.GetAccessAutomationAllActionsSuccess;

  constructor(public payload: ElementInterface[]) {}
}
export class GetAccessAutomationActionAction implements Action {
  readonly type = AccessAutomationsActions.GetAccessAutomationAction;

  constructor(public payload: number) {}
}
export class GetAccessAutomationActionSuccessAction implements Action {
  readonly type = AccessAutomationsActions.GetAccessAutomationActionSuccess;

  constructor(public payload: AccessAutomationActionInterface) {}
}
export class GetAccessAutomationAllOperandsAction implements Action {
  readonly type = AccessAutomationsActions.GetAccessAutomationAllOperands;

  constructor(public payload: number) {}
}
export class GetAccessAutomationAllOperandsSuccessAction implements Action {
  readonly type =
    AccessAutomationsActions.GetAccessAutomationAllOperandsSuccess;

  constructor(public payload: AccessAutomationOperandsInterface[]) {}
}

export class ConnectAccessAutomationOperandValueAction implements Action {
  readonly type = AccessAutomationsActions.ConnectAccessAutomationOperandValue;

  constructor(
    public payload: {
      expressionId: number;
      valueId: number;
      data: AccessAutomationOperandValueInterface;
    }
  ) {}
}
export class ConnectAccessAutomationOperandValueSuccessAction
  implements Action {
  readonly type =
    AccessAutomationsActions.ConnectAccessAutomationOperandValueSuccess;

  constructor() {}
}

export class AddNewOperandValueAction implements Action {
  readonly type = AccessAutomationsActions.AddNewOperandValue;

  constructor(
    public payload: {
      operandValue: AccessAutomationOperandValueInterface;
      expressionId: number;
    }
  ) {}
}

export class DisconnectAccessAutomationOperandValueAction implements Action {
  readonly type =
    AccessAutomationsActions.DisconnectAccessAutomationOperandValue;

  constructor(
    public payload: {
      expressionId: number;
      operandId: number;
      valueId: number;
      data: AccessAutomationOperandValueInterface;
    }
  ) {}
}

export class DisconnectAccessAutomationOperandValueSuccessAction
  implements Action {
  readonly type =
    AccessAutomationsActions.DisconnectAccessAutomationOperandValueSuccess;

  constructor() {}
}

export class RemoveOperandValueAction implements Action {
  readonly type = AccessAutomationsActions.RemoveOperandValue;

  constructor(
    public payload: {
      operandValue: AccessAutomationOperandValueInterface;
      expressionId: number;
      operandId: number;
    }
  ) {}
}

export class UpdateExpressionOperatorAction implements Action {
  readonly type = AccessAutomationsActions.UpdateExpressionOperator;

  constructor(public payload: {expressionId: number; data: any}) {}
}

export class UpdateExpressionOperatorSuccessAction implements Action {
  readonly type = AccessAutomationsActions.UpdateExpressionOperatorSuccess;

  constructor(public payload: AccessAutomationExpressionInterface) {}
}

export class CreateExpressionAction implements Action {
  readonly type = AccessAutomationsActions.CreateExpression;
  constructor(
    public payload: {
      data: AccessAutomationExpressionInterface;
      automationId: number;
      operandId: number;
    }
  ) {}
}
export class CreateExpressionSuccessAction implements Action {
  readonly type = AccessAutomationsActions.CreateExpressionSuccess;
  constructor(public payload: AccessAutomationExpressionInterface) {}
}
export class DeleteExpressionAction implements Action {
  readonly type = AccessAutomationsActions.DeleteExpression;
  constructor(
    public payload: {id: number; automationId: number; operandId: number}
  ) {}
}
export class DeleteExpressionSuccessAction implements Action {
  readonly type = AccessAutomationsActions.DeleteExpressionSuccess;
  constructor(public payload: number) {}
}

export class GetAccessAutomationBuildingCommonAreaAction implements Action {
  readonly type =
    AccessAutomationsActions.GetAccessAutomationBuildingCommonArea;
  constructor(public payload?: QueryParamsInterface) {}
}

export class GetAccessAutomationBuildingCommonAreaSuccessAction
  implements Action {
  readonly type =
    AccessAutomationsActions.GetAccessAutomationBuildingCommonAreaSuccess;
  constructor(public payload?: BuildingCommonAreaInterface) {}
}

export class GetAccessAutomationLeasingUalsAction implements Action {
  readonly type = AccessAutomationsActions.GetAccessAutomationLeasingUals;
  constructor(public payload?: QueryParamsInterface) {}
}

export class GetAccessAutomationLeasingUalsSuccessAction implements Action {
  readonly type =
    AccessAutomationsActions.GetAccessAutomationLeasingUalsSuccess;
  constructor(public payload?: UalsInterface) {}
}

export class GetAccessAutomationVacancyUalsAction implements Action {
  readonly type = AccessAutomationsActions.GetAccessAutomationVacancyUals;
  constructor(public payload?: QueryParamsInterface) {}
}

export class GetAccessAutomationVacancyUalsSuccessAction implements Action {
  readonly type =
    AccessAutomationsActions.GetAccessAutomationVacancyUalsSuccess;
  constructor(public payload?: UalsInterface) {}
}

export type AccessAutomationsActionsUnion =
  | GetAccessAutomationsAction
  | GetAccessAutomationsSuccessAction
  | UpdateAccessAutomationsSearchQueryAction
  | GetAccessAutomationAction
  | GetAccessAutomationSuccessAction
  | CreateAccessAutomationAction
  | SaveAccessAutomationAction
  | EnableDisableAccessAutomationAction
  | EnableAccessAutomationAction
  | EnableAccessAutomationSuccessAction
  | SecondEnableAccessAutomationAction
  | DisableAccessAutomationAction
  | DeleteAccessAutomationAction
  | DeleteAccessAutomationFromStoreAction
  | GetAccessAutomationAllOperandValuesAction
  | GetAccessAutomationAllOperandValuesSuccessAction
  | GetAccessAutomationResidentsOperandValuesAction
  | GetAccessAutomationResidentsOperandValuesSuccessAction
  | GetAccessAutomationApartmentsOperandValuesAction
  | GetAccessAutomationApartmentsOperandValuesSuccessAction
  | GetAccessAutomationMoveInOperandValuesAction
  | GetAccessAutomationMoveInOperandValuesSuccessAction
  | GetAccessAutomationMoveOutOperandValuesAction
  | GetAccessAutomationMoveOutOperandValuesSuccessAction
  | GetAccessAutomationAllActionsAction
  | GetAccessAutomationAllActionsSuccessAction
  | GetAccessAutomationActionAction
  | GetAccessAutomationActionSuccessAction
  | GetAccessAutomationAllOperandsAction
  | GetAccessAutomationAllOperandsSuccessAction
  | ConnectAccessAutomationOperandValueAction
  | ConnectAccessAutomationOperandValueSuccessAction
  | DisconnectAccessAutomationOperandValueAction
  | DisconnectAccessAutomationOperandValueSuccessAction
  | AddNewOperandValueAction
  | RemoveOperandValueAction
  | UpdateExpressionOperatorAction
  | UpdateExpressionOperatorSuccessAction
  | CreateExpressionAction
  | CreateExpressionSuccessAction
  | DeleteExpressionAction
  | DeleteExpressionSuccessAction
  | GetAccessAutomationBuildingCommonAreaAction
  | GetAccessAutomationBuildingCommonAreaSuccessAction
  | GetAccessAutomationLeasingUalsAction
  | GetAccessAutomationLeasingUalsSuccessAction
  | GetAccessAutomationVacancyUalsAction
  | GetAccessAutomationVacancyUalsSuccessAction;
