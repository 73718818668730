<div class="confirmation-modal">
  <div class="content-wrapper">
    <div class="automation-log-container">
      <div class="steps-container" *ngFor="let step of steps; let i = index">
        <div class="steps-title">Step {{ i + 1 }}</div>
        <div>
          <span class="steps-element-title">"Action":</span> "{{ step?.msg }}"
        </div>
        <div>
          <span class="steps-element-title">"Status":</span>
          <span
            class="status-text"
            [ngClass]="{
              success: step?.result === 'success',
              skip: step?.result === 'skip',
              warn: step?.result === 'warn'
            }"
            > "{{ step.result }}"</span
          >
        </div>
      </div>
    </div>
    <div class="btn-wrap">
      <button type="button" (click)="cancel()" class="btn btn-sm btn-green">
        <span>Confirm</span>
      </button>
    </div>
  </div>
</div>
