import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {
  BuildingsGroupActions,
  BuildingsGroupActionsUnion
} from '../actions/buildings-group.actions';
import {
  BuildingsGroupInterface,
  BuildingsGroupRequestResponseInterface
} from '../../interfaces/buildings-group.interface';

export interface BuildingsGroupState {
  buildingsGroup: BuildingsGroupRequestResponseInterface;
  buildingGroup: BuildingsGroupInterface;
  buildingsGroupSearchQuery: QueryParamsInterface;
  modalInfo: {isOpen: boolean; message: string};
}

const initialState: BuildingsGroupState = {
  buildingsGroup: {buildingsGroup: [], total: 0},
  buildingGroup: null,
  buildingsGroupSearchQuery: null,
  modalInfo: {isOpen: false, message: null}
};

export function buildingsGroupReducer(
  state: BuildingsGroupState = initialState,
  action: BuildingsGroupActionsUnion
) {
  switch (action.type) {
    case BuildingsGroupActions.GetBuildingsGroupSuccess: {
      return {
        ...state,
        buildingsGroup: action.payload
      };
    }
    case BuildingsGroupActions.UpdateBuildingsGroupSearchQuery: {
      return {
        ...state,
        buildingsGroupSearchQuery: {
          ...state.buildingsGroupSearchQuery,
          ...action.payload
        }
      };
    }
    case BuildingsGroupActions.GetBuildingGroupSuccess: {
      return {
        ...state,
        buildingGroup: action.payload
      };
    }
    case BuildingsGroupActions.DeleteBuildingGroupFromStore: {
      return {
        ...state,
        buildingGroup: null
      };
    }
    case BuildingsGroupActions.OpenInfoModal: {
      return {
        ...state,
        modalInfo: {
          isOpen: action.payload.isOpen,
          message: action.payload.message
        }
      };
    }
    default: {
      return state;
    }
  }
}
