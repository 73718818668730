import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ModalService} from '../../services/modal.service';

@Component({
  selector: 'app-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  styleUrls: ['./modal-wrapper.component.sass']
})
export class ModalWrapperComponent implements OnInit {

  @ViewChild('modalWrapper', {
    static: true,
    read: ViewContainerRef
  }) modalWrapper;

  constructor(
    private modalService: ModalService
  ) {
  }

  ngOnInit() {
    this.modalService.modalWrapper = this.modalWrapper;
  }

}
