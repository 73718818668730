import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {
  UserInterface,
  UserRequestResponseInterface
} from '../../interfaces/user.interface';
import {UsersActions, UsersActionsUnion} from '../actions/users.actions';

export interface UsersState {
  users: UserRequestResponseInterface;
  usersSearchQuery: QueryParamsInterface | null;
  user: any;
  company: any;
}

const initialState: UsersState = {
  users: {users: [], total: 0},
  usersSearchQuery: null,
  user: null,
  company: null
};

export function usersReducer(
  state: UsersState = initialState,
  action: UsersActionsUnion
) {
  switch (action.type) {
    case UsersActions.GetUsersSuccess: {
      return {
        ...state,
        users: action.payload
      };
    }
    case UsersActions.UpdateUsersSearchQueryRaw: {
      return {
        ...state,
        usersSearchQuery: {
          ...state.usersSearchQuery,
          ...action.payload
        }
      };
    }
    case UsersActions.GetUserSuccess: {
      return {
        ...state,
        user: action.payload
      };
    }
    case UsersActions.DeleteUserFromStore: {
      return {
        ...state,
        user: null
      };
    }
    case UsersActions.GetCompanySuccess: {
      return {
        ...state,
        company: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
