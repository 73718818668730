import {
  AccessLevelInterface,
  AccessLevelsRequestResponseInterface
} from '../../interfaces/access-level.interface';
import {QueryParamsInterface} from '../../../../interfaces/query-params.interface';
import {
  AccessLevelsActions,
  AccessLevelsActionsUnion
} from '../actions/access-levels.actions';
import {
  TenantsItemAvailableDoorsRequestResponseInterface,
  TenantsItemAvailableZonesRequestResponseInterface
} from '../../../residents/interfaces/tenants.interface';

export interface AccessLevelsState {
  accessLevels: AccessLevelsRequestResponseInterface;
  accessLevel: AccessLevelInterface;
  accessLevelsSearchQuery: QueryParamsInterface;
  accessLevelAvailableDoors: TenantsItemAvailableDoorsRequestResponseInterface;
  accessLevelAvailableDoorsSearchQuery: QueryParamsInterface;
  accessLevelAvailableDoorsAll: TenantsItemAvailableDoorsRequestResponseInterface;
  accessLevelAvailableDoorsAllSearchQuery: QueryParamsInterface;
  accessLevelAvailableZones: TenantsItemAvailableZonesRequestResponseInterface;
  accessLevelAvailableZonesSearchQuery: QueryParamsInterface;
  accessLevelAvailableZonesAll: TenantsItemAvailableZonesRequestResponseInterface;
  accessLevelAvailableZonesAllSearchQuery: QueryParamsInterface;
}

const initialState: AccessLevelsState = {
  accessLevels: {accessLevels: [], total: 0},
  accessLevel: null,
  accessLevelsSearchQuery: null,
  accessLevelAvailableDoors: null,
  accessLevelAvailableDoorsSearchQuery: null,
  accessLevelAvailableDoorsAll: null,
  accessLevelAvailableDoorsAllSearchQuery: null,
  accessLevelAvailableZones: null,
  accessLevelAvailableZonesSearchQuery: null,
  accessLevelAvailableZonesAll: null,
  accessLevelAvailableZonesAllSearchQuery: null
};

export function accessLevelsReducer(
  state: AccessLevelsState = initialState,
  action: AccessLevelsActionsUnion
) {
  switch (action.type) {
    case AccessLevelsActions.GetAccessLevelsSuccess: {
      return {
        ...state,
        accessLevels: action.payload
      };
    }
    case AccessLevelsActions.UpdateAccessLevelsSearchQuery: {
      return {
        ...state,
        accessLevelsSearchQuery: {
          ...state.accessLevelsSearchQuery,
          ...action.payload
        }
      };
    }
    case AccessLevelsActions.GetAccessLevelSuccess: {
      return {
        ...state,
        accessLevel: action.payload
      };
    }
    case AccessLevelsActions.DeleteAccessLevelFromStore: {
      return {
        ...state,
        accessLevel: null
      };
    }

    case AccessLevelsActions.GetAccessLevelAvailableDoorsSuccess: {
      return {
        ...state,
        accessLevelAvailableDoors: action.payload
      };
    }
    case AccessLevelsActions.UpdateAccessLevelAvailableDoorsSearchQuery: {
      return {
        ...state,
        accessLevelAvailableDoorsSearchQuery: {
          ...state.accessLevelAvailableDoorsSearchQuery,
          ...action.payload
        }
      };
    }

    case AccessLevelsActions.DeleteFromStoreAvailableDoors: {
      return {
        ...state,
        accessLevelAvailableDoors: null
      };
    }

    case AccessLevelsActions.GetAccessLevelAvailableDoorsAllSuccess: {
      return {
        ...state,
        accessLevelAvailableDoorsAll: action.payload
      };
    }
    case AccessLevelsActions.UpdateAccessLevelAvailableDoorsAllSearchQuery: {
      return {
        ...state,
        accessLevelAvailableDoorsAllSearchQuery: {
          ...state.accessLevelAvailableDoorsAllSearchQuery,
          ...action.payload
        }
      };
    }

    case AccessLevelsActions.DeleteFromStoreAvailableDoorsAll: {
      return {
        ...state,
        accessLevelAvailableDoorsAll: null
      };
    }

    case AccessLevelsActions.GetAccessLevelAvailableZonesSuccess: {
      return {
        ...state,
        accessLevelAvailableZones: action.payload
      };
    }
    case AccessLevelsActions.UpdateAccessLevelAvailableZonesSearchQuery: {
      return {
        ...state,
        accessLevelAvailableZonesSearchQuery: {
          ...state.accessLevelAvailableZonesSearchQuery,
          ...action.payload
        }
      };
    }

    case AccessLevelsActions.DeleteFromStoreAvailableZones: {
      return {
        ...state,
        accessLevelAvailableZones: null
      };
    }

    case AccessLevelsActions.GetAccessLevelAvailableZonesAllSuccess: {
      return {
        ...state,
        accessLevelAvailableZonesAll: action.payload
      };
    }
    case AccessLevelsActions.UpdateAccessLevelAvailableZonesAllSearchQuery: {
      return {
        ...state,
        accessLevelAvailableZonesAllSearchQuery: {
          ...state.accessLevelAvailableZonesAllSearchQuery,
          ...action.payload
        }
      };
    }

    case AccessLevelsActions.DeleteFromStoreAvailableZonesAll: {
      return {
        ...state,
        accessLevelAvailableZonesAll: null
      };
    }
    default: {
      return state;
    }
  }
}
