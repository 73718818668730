import {
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  ViewContainerRef
} from '@angular/core';
import {ConfirmationModalComponent} from '../components/confirmation-modal/confirmation-modal.component';
import {
  AutomationsLogsStepsModalOptionsInterface,
  AutomationsModalOptionsInterface,
  ModalOptionsInterface
} from '../interfaces/modal.interface';
import {Router} from '@angular/router';
import {DoorsModalComponent} from '../components/doors-modal/doors-modal.component';
import {AccessAutomationsItemsComponent} from '../../dashboard/system/access-automations-items/access-automations-items.component';
import {AutomationsLogsStepsModalComponent} from '../components/automation-report-modal/automation-report-modal.component';
import {AuditLogDeltaModalComponent} from '../components/audit-log-delta-modal/audit-log-delta-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public modalWrapper: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private router: Router
  ) {}

  public showModal = (options?: ModalOptionsInterface) => {
    /*window.scroll({
      left: 0,
      top: 0,
      behavior: 'smooth'
    });*/
    const confirmationModalComponent = this.componentFactoryResolver.resolveComponentFactory(
      ConfirmationModalComponent
    );
    const confirmationModalComponentRef: ComponentRef<ConfirmationModalComponent> = this.modalWrapper.createComponent(
      confirmationModalComponent
    );
    confirmationModalComponentRef.instance.options = options;
    confirmationModalComponentRef.instance.modalService = this;
    document.body.style.overflow = 'hidden';
    return confirmationModalComponentRef.instance.events;
  };

  public showDoorsModal = (options?: ModalOptionsInterface) => {
    /*window.scroll({
      left: 0,
      top: 0,
      behavior: 'smooth'
    });*/
    const confirmationModalComponent = this.componentFactoryResolver.resolveComponentFactory(
      DoorsModalComponent
    );
    const confirmationModalComponentRef: ComponentRef<DoorsModalComponent> = this.modalWrapper.createComponent(
      confirmationModalComponent
    );
    confirmationModalComponentRef.instance.options = options;
    confirmationModalComponentRef.instance.modalService = this;
    document.body.style.overflow = 'hidden';
    return confirmationModalComponentRef.instance.events;
  };

  public showAutomationModal = (options?: AutomationsModalOptionsInterface) => {
    /*window.scroll({
      left: 0,
      top: 0,
      behavior: 'smooth'
    });*/
    const automationModalComponent = this.componentFactoryResolver.resolveComponentFactory(
      AccessAutomationsItemsComponent
    );
    const automationModalComponentRef: ComponentRef<AccessAutomationsItemsComponent> = this.modalWrapper.createComponent(
      automationModalComponent
    );
    automationModalComponentRef.instance.options = options;
    automationModalComponentRef.instance.modalService = this;
    document.body.style.overflow = 'hidden';
    return automationModalComponentRef.instance.events;
  };

  public showAutomationReportModal = (
    options?: AutomationsLogsStepsModalOptionsInterface
  ) => {
    /*window.scroll({
      left: 0,
      top: 0,
      behavior: 'smooth'
    });*/
    const automationsLogsStepsModalComponent = this.componentFactoryResolver.resolveComponentFactory(
      AutomationsLogsStepsModalComponent
    );
    const automationsLogsStepsModalComponentRef: ComponentRef<AutomationsLogsStepsModalComponent> = this.modalWrapper.createComponent(
      automationsLogsStepsModalComponent
    );
    automationsLogsStepsModalComponentRef.instance.options = options;
    automationsLogsStepsModalComponentRef.instance.modalService = this;
    document.body.style.overflow = 'hidden';
    return automationsLogsStepsModalComponentRef.instance.events;
  };
  public showAuditLogDeltaModal = (
    options?: AutomationsLogsStepsModalOptionsInterface
  ) => {
    /*window.scroll({
      left: 0,
      top: 0,
      behavior: 'smooth'
    });*/
    const auditLogDeltaModalComponent = this.componentFactoryResolver.resolveComponentFactory(
      AuditLogDeltaModalComponent
    );
    const auditLogDeltaModalComponentRef: ComponentRef<AuditLogDeltaModalComponent> = this.modalWrapper.createComponent(
      auditLogDeltaModalComponent
    );
    auditLogDeltaModalComponentRef.instance.options = options;
    auditLogDeltaModalComponentRef.instance.modalService = this;
    document.body.style.overflow = 'hidden';
    return auditLogDeltaModalComponentRef.instance.events;
  };

  public closeModal = () => {
    this.modalWrapper.clear();
    document.body.style.overflow = 'inherit';
    if (
      this.router.url.includes('prospects/apartments' || 'residents/apartments')
    ) {
      document
        .getElementById('building-select')
        .scrollIntoView({block: 'center', behavior: 'smooth'});
    }
  };
}
