import * as tslib_1 from "tslib";
import { ProspectsAgentDashboardService } from '../dashboard/prospects/services/prospects-agent-dashboard.service';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { environment } from '../../environments/environment';
import { EventEmitter } from '@angular/core';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { UpdateMessagesAction } from '../Store/actions/profile.actions';
export class MessagingService {
    constructor(service, store) {
        this.service = service;
        this.store = store;
        this.conversationArr = [];
        this.conversationMessagesMap = new Map();
        this.conversationParticipantsMap = new Map();
        this.selectedConversationMap = new Map();
        this.subscribedConversationsMap = new Map();
        this.messagesBox = [];
        this.countMess = [0];
        this.twilioLoading = false;
        this.twilioClient = new Promise((resolve, reject) => {
            this.resolveTwilioClient = resolve;
        });
        this.firebaseClient = new Promise((resolve, reject) => {
            this.resolveFirebaseClient = resolve;
            this.rejectFirebaseClient = reject;
        });
        this.healthCheck = new BehaviorSubject(false);
        this.dateNow = moment();
        this.change = new EventEmitter();
        this.participantJoinListener = this.participantJoined.bind(this);
        this.messageAddedListener = this.messageAdded.bind(this);
        this.updateToken = this.updatedToken.bind(this);
        this.updateListener = this.updatedAttr.bind(this);
        this.initFirebase()
            .then(() => this.initClient())
            .catch((e) => this.initClient(false));
    }
    requestPermission() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let resolvePermission;
            let rejectPermission;
            const requestPermission = new Promise((resolve, reject) => {
                resolvePermission = resolve;
                rejectPermission = reject;
            });
            console.log('Requesting permission...');
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                    resolvePermission();
                }
                else {
                    console.log('Unable to get permission to notify.');
                    rejectPermission();
                }
            });
            return requestPermission;
        });
    }
    initFirebase() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const { firebaseApiKey: apiKey, firebaseAuthDomain: authDomain, firebaseDatabaseURL: databaseURL, firebaseProjectId: projectId, firebaseStorageBucket: storageBucket, firebaseMessagingSenderId: messagingSenderId, firebaseAppId: appId, firebaseMeasurementId: measurementId, firebasePublicVapidKey: publicVapidKey } = environment;
                this.firebaseApp = yield firebase.messaging(firebase.initializeApp({
                    apiKey,
                    authDomain,
                    databaseURL,
                    projectId,
                    storageBucket,
                    messagingSenderId,
                    appId,
                    measurementId
                }, 'twilioChat'));
                yield this.requestPermission();
                this.firebaseApp.usePublicVapidKey(publicVapidKey);
                this.firebaseApp.onMessage(this.handlePush.bind(this));
                this.resolveFirebaseClient(this.firebaseApp);
            }
            catch (e) {
                console.error('Firebase init error', e.message);
                this.rejectFirebaseClient();
            }
        });
    }
    initClient(firebase = true) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.service
                .getToken()
                .pipe(first())
                .subscribe((data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (firebase) {
                    const fbToken = yield this.firebaseApp.getToken();
                    yield this.initTwilioChat(data.token, fbToken);
                    yield this.service.getPushToken(fbToken).pipe(first()).subscribe();
                    if (this.interval) {
                        clearInterval(this.interval);
                    }
                    this.interval = setInterval(() => {
                        this.service.getPushToken(fbToken).pipe(first()).subscribe();
                    }, 60000);
                    this.pushToken = fbToken;
                }
                else {
                    yield this.initTwilioChat(data.token);
                }
                this.twilioLoading = true;
                return this.resolveTwilioClient(this.client);
            }));
        });
    }
    initTwilioChat(twilioToken, fbToken = null) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.client = yield Twilio.Conversations.Client.create(twilioToken);
            this.client.on('conversationAdded', this.conversationAdded);
            this.client.on('tokenAboutToExpire', this.updateToken);
            this.client.on('tokenExpired', this.updateToken);
            if (fbToken) {
                this.client.setPushRegistrationId('fcm', fbToken);
            }
            const { items } = yield this.client.getSubscribedConversations();
            this.conversationArr = items;
            yield Promise.all(items.map((item) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    if (item.lastMessage
                        && item.lastMessage.index
                        && item.lastReadMessageIndex
                        && item.lastReadMessageIndex !== item.lastMessage.index) {
                        return this.getLastMessage(item);
                    }
                    else if (!item.lastReadMessageIndex && item.lastMessage && item.lastMessage.index) {
                        return item.lastMessage.index;
                    }
                }
                catch (e) {
                    console.error('Push error: ', e.message);
                }
                return Promise.resolve();
            })));
        });
    }
    initConversation(sid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.initTwilioClient();
            if (!this.client) {
                yield this.initClient();
            }
            if (this.selectedConversationMap.has(sid)) {
                return this.selectedConversationMap.get(sid);
            }
            const currentConversation = yield this.client.getConversationBySid(sid);
            currentConversation.on('messageAdded', this.messageAddedListener);
            currentConversation.on('updated', this.updateListener);
            currentConversation.on('typingStarted', this.typingStarted);
            currentConversation.on('typingEnded', this.typingEnded);
            currentConversation.on('participantJoined', this.participantJoinListener);
            currentConversation.on('participantLeft', this.participantLeft);
            return currentConversation;
        });
    }
    conversationAdded() {
        console.log('Conversation added');
    }
    typingStarted() {
        console.log('Typing started');
    }
    typingEnded() {
        console.log('Typing Ended');
    }
    participantJoined(participant) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const conversation = this.selectedConversationMap.get(participant.conversation.sid);
            const getParticipant = this.conversationParticipantsMap.get(conversation.sid);
            getParticipant.push(participant);
            this.conversationParticipantsMap.set(conversation.sid, getParticipant);
            console.log('Participant Joined');
        });
    }
    participantLeft() {
        console.log('Participant Left');
    }
    messageAdded(m) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const conversation = this.selectedConversationMap.get(m.conversation.sid);
            if (!conversation) {
                return;
            }
            if (this.conversationMessagesMap.get(conversation.sid).findIndex(({ state }) => state.sid === m.state.sid) === -1) {
                this.conversationMessagesMap.get(conversation.sid).push(this.mess(m, this.conversationParticipantsMap.get(conversation.sid)));
            }
            console.log('Message added');
        });
    }
    mess(m, participants) {
        m.user = participants.find((u) => u.identity.localeCompare(m.author) === 0);
        if (m.state.author === 'walk.in') {
            const checkInMessage = "Welcome to +SelfTour. Use this chat to contact agent if you have any questions.";
            if (m.state.body === checkInMessage) {
                m.state.body = `${m.conversation.state.createdBy} checked in ${m.conversation.state.attributes.unitId}.`;
            }
        }
        return m;
    }
    getAllMess(sid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const conversation = this.selectedConversationMap.get(sid);
            const [messages, participants] = yield Promise.all([conversation.getMessages(300), conversation.getParticipants()]);
            this.conversationMessagesMap.set(sid, messages.items);
            this.conversationParticipantsMap.set(sid, participants);
            this.conversationMessagesMap.get(sid).map((m) => this.mess(m, participants));
        });
    }
    getCurrentConversationMessages(sid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.conversationMessagesMap.has(sid)) {
                yield this.getAllMess(sid);
            }
            return this.conversationMessagesMap.get(sid);
        });
    }
    getLastMessage(conversation) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let message;
            try {
                const { items: [{ conversation: c, body, author }] } = yield conversation.getMessages(1);
                const [, visId, apId] = conversation.channelState.uniqueName.split('=');
                const visitorId = visId.replace(/[^\d]/g, '');
                let apartmentId;
                if (apId) {
                    apartmentId = apId.replace(/[^\d]/g, '');
                }
                else {
                    apartmentId = null;
                }
                let unreadMess;
                if (!c.lastReadMessageIndex) {
                    unreadMess = c.lastMessage.index;
                }
                else {
                    unreadMess = c.lastMessage.index - c.lastReadMessageIndex;
                }
                const conversationTitle = c.channelState.uniqueName;
                const { attributes: { name: authorName } } = yield this.client.getUser(author);
                const address = c.channelState.friendlyName;
                message = {
                    author: authorName, address, mess: body, sid: c.sid, visitorId, apartmentId, conversationTitle, unreadMess
                };
            }
            catch (e) {
                console.error('Get Last Message Error:', e.message);
            }
            if (message) {
                this.messagesBox.unshift(message);
                this.countMess[0] = this.messagesBox.length;
            }
            this.store.dispatch(new UpdateMessagesAction(this.messagesBox));
            return [this.messagesBox, this.countMess];
        });
    }
    updatedToken() {
        this.service.getToken()
            .pipe(first())
            .subscribe((data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.client.updateToken(data.token);
        }));
    }
    ;
    updatedAttr(conversation) {
    }
    getPush() {
        if (!this.firebaseApp) {
            console.error('Firebase not initialise');
        }
        this.store.dispatch(new UpdateMessagesAction(this.messagesBox));
        return [this.messagesBox, this.countMess];
    }
    handlePush(payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.pushMute = localStorage.getItem('Sound push') !== 'false';
                if (this.interval) {
                    clearInterval(this.interval);
                }
                this.setHealth(true);
                if (payload.data.title === 'WEB_PUSH_HEALTH_CHECK') {
                    return;
                }
                if (this.pushMute) {
                    let audio = new Audio();
                    if (payload.data && payload.data.twi_message_type === 'twilio.conversations.new_message') {
                        audio.src = '../assets/sounds/message.mp3';
                    }
                    else {
                        audio.src = '../assets/sounds/checkin.mp3';
                    }
                    try {
                        audio.load();
                        yield audio.play();
                    }
                    catch (e) { }
                }
                if (payload.data && payload.data.twi_message_type === 'twilio.conversations.new_message') {
                    yield this.handleTwilioPush(payload);
                }
                else {
                    yield this.handleWebPush(payload);
                }
            }
            catch (e) {
                console.error('Push Notifications error: ', e.message);
            }
        });
    }
    getHealth() {
        return this.healthCheck;
    }
    ;
    setHealth(state) {
        this.healthCheck.next(state);
    }
    ;
    handleTwilioPush(payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.client) {
                this.client.handlePushNotification(payload);
            }
            if (payload && payload.data && payload.data.conversation_sid) {
                const data = payload.data;
                const { conversation_sid: sid, author } = data;
                if (data.twi_message_type === 'twilio.conversations.new_message' && data.twi_body && data.conversation_title) {
                    const conversationTitle = data.conversation_title;
                    const messageBody = data.twi_body.split(';');
                    const [, visId, apId] = data.conversation_title.split('=');
                    const visitorId = visId.replace(/[^\d]/g, '');
                    let apartmentId;
                    if (apId.replace(/[^\d]/g, '')) {
                        apartmentId = apId.replace(/[^\d]/g, '');
                    }
                    else {
                        apartmentId = null;
                    }
                    const date = this.dateNow;
                    if (author === 'walk.in') {
                        const currentConversation = this.conversationArr.find(item => item.sid === sid) || (yield this.client.getConversationBySid(sid));
                        const mess = `${currentConversation.state.createdBy} checked in ${currentConversation.state.attributes.unitId}`;
                        this.messagesBox.unshift({ mess, visitorId, apartmentId, sid, date });
                    }
                    else {
                        const [address, mess] = messageBody;
                        const index = this.messagesBox.findIndex(item => item.sid === sid);
                        const unreadMess = (index !== -1) ? this.messagesBox[index].unreadMess + 1 : 1;
                        if (index !== -1) {
                            this.messagesBox.splice(index, 1);
                        }
                        this.messagesBox.unshift({
                            author,
                            address,
                            mess,
                            sid,
                            visitorId,
                            apartmentId,
                            conversationTitle,
                            unreadMess,
                            date
                        });
                    }
                    this.setLocaleStorage(this.messagesBox);
                    this.countMess[0] = this.messagesBox.length;
                    this.store.dispatch(new UpdateMessagesAction(this.messagesBox));
                    return [this.messagesBox, this.countMess];
                }
            }
        });
    }
    handleWebPush(payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (payload && payload.data && payload.data.conversationSid) {
                const data = payload;
                const sid = data.data.conversationSid;
                const mess = data.data.body;
                const title = data.data.title;
                const visitorId = data.data.visitorId;
                const apartmentId = data.data.apartmentId;
                const date = this.dateNow;
                this.messagesBox.unshift({ sid, mess, title, visitorId, apartmentId, date });
                this.countMess[0] = this.messagesBox.length;
                this.setLocaleStorage(this.messagesBox);
                this.store.dispatch(new UpdateMessagesAction(this.messagesBox));
                return [this.messagesBox, this.countMess];
            }
        });
    }
    setLocaleStorage(messagesBox) {
        localStorage.setItem('messagesBox', JSON.stringify(messagesBox));
    }
    pushMuted() {
        this.pushMute = !this.pushMute;
        return this.pushMute;
    }
    get messageBox() {
        return this.messagesBox;
    }
    get target() {
        return this._target;
    }
    set target(message) {
        this._target = message;
    }
    toggle() {
        this.change.emit(this._target);
    }
    initTwilioClient() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.twilioClient;
        });
    }
    getUnreadMess(sid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let conversation = yield this.client.getConversationBySid(sid);
                let unreadMessages = 0;
                if (conversation.lastMessage.index !== conversation.lastReadMessageIndex) {
                    unreadMessages = conversation.lastMessage.index - conversation.lastReadMessageIndex;
                }
                conversation = null;
                return unreadMessages;
            }
            catch (e) {
                return 0;
            }
        });
    }
    deleteCurrentPush(sid) {
        this.messagesBox = this.messageBox.filter(item => item.conversationTitle !== sid);
        this.countMess[0] = this.messagesBox.length;
        this.setLocaleStorage(this.messagesBox);
        this.store.dispatch(new UpdateMessagesAction(this.messagesBox));
        return [this.messagesBox, this.countMess];
    }
    unsubscribePush() {
        try {
            this.client.unsetPushRegistrationId('fcm');
        }
        catch (e) {
            console.error('error: ', e.message);
        }
    }
    selectConversation(conversationSid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.selectedConversationMap.has(conversationSid)) {
                this.selectedConversationMap.set(conversationSid, yield this.initConversation(conversationSid));
            }
            return this.selectedConversationMap.get(conversationSid);
        });
    }
    ;
    leaveCurrentConversation(conversationSid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const leftConversation = this.selectedConversationMap.get(conversationSid);
            if (!leftConversation) {
                return;
            }
            console.log('left ' + leftConversation.friendlyName);
            leftConversation.removeListener('messageAdded', this.messageAddedListener);
            leftConversation.removeListener('updated', this.updateListener);
            leftConversation.removeListener('typingStarted', this.typingStarted);
            leftConversation.removeListener('typingEnded', this.typingEnded);
            leftConversation.removeListener('participantJoined', this.participantJoinListener);
            leftConversation.removeListener('participantLeft', this.participantLeft);
            this.selectedConversationMap.delete(conversationSid);
            this.conversationMessagesMap.delete(conversationSid);
            this.conversationParticipantsMap.delete(conversationSid);
        });
    }
    sendMess(msg, sid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let conversation = this.selectedConversationMap.get(sid);
            if (!this.client) {
                this.client = yield this.initTwilioClient();
            }
            try {
                yield conversation.sendMessage(msg);
            }
            catch (e) {
                console.error(e.message);
                try {
                    yield this.reconnectClient();
                    conversation = this.selectedConversationMap.get(sid);
                    yield conversation.sendMessage(msg);
                }
                catch (e) {
                    console.error('Please check your internet connection and refresh the page.', e.message);
                }
            }
            conversation.advanceLastReadMessageIndex(conversation.lastMessage.index);
            conversation.setAllMessagesRead();
        });
    }
    reconnectClient() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.client.removeListener('conversationAdded', this.conversationAdded);
            this.client.removeListener('tokenAboutToExpire', this.updateToken);
            this.client.removeListener('tokenExpired', this.updateToken);
            for (let sid of this.selectedConversationMap.keys()) {
                yield this.leaveCurrentConversation(sid);
            }
            this.conversationArr = [];
            this.client = yield this.initTwilioClient();
        });
    }
    deletePushToken() {
        if (!this.pushToken) {
            return;
        }
        this.service.deletePushToken(this.pushToken).subscribe();
    }
}
