import * as i0 from "./doors-modal.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./doors-modal.component";
import * as i3 from "@angular/router";
var styles_DoorsModalComponent = [i0.styles];
var RenderType_DoorsModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DoorsModalComponent, data: {} });
export { RenderType_DoorsModalComponent as RenderType_DoorsModalComponent };
export function View_DoorsModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "confirmation-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "btn-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["class", "btn btn-sm btn-grey"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "btn btn-sm btn-green"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirm"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.options == null) ? null : _co.options.message) || _co.defaultMessage); _ck(_v, 3, 0, currVal_0); }); }
export function View_DoorsModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-doors-modal", [], null, null, null, View_DoorsModalComponent_0, RenderType_DoorsModalComponent)), i1.ɵdid(1, 245760, null, 0, i2.DoorsModalComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DoorsModalComponentNgFactory = i1.ɵccf("app-doors-modal", i2.DoorsModalComponent, View_DoorsModalComponent_Host_0, {}, {}, []);
export { DoorsModalComponentNgFactory as DoorsModalComponentNgFactory };
