import * as i0 from "./ldap.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./ldap.component";
import * as i8 from "@angular/router";
import * as i9 from "../../services/auth/auth.service";
var styles_LdapComponent = [i0.styles];
var RenderType_LdapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LdapComponent, data: {} });
export { RenderType_LdapComponent as RenderType_LdapComponent };
function View_LdapComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "ldap-spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-progress-spinner", [["class", "green mat-progress-spinner"], ["diameter", "60"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"], [1, "aria-valuemin", 0], [1, "aria-valuemax", 0], [1, "aria-valuenow", 0], [1, "mode", 0]], null, null, i2.View_MatProgressSpinner_0, i2.RenderType_MatProgressSpinner)), i1.ɵdid(2, 114688, null, 0, i3.MatProgressSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"], mode: [1, "mode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "60"; var currVal_8 = _co.mode; _ck(_v, 2, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; var currVal_3 = ((i1.ɵnov(_v, 2).mode === "determinate") ? 0 : null); var currVal_4 = ((i1.ɵnov(_v, 2).mode === "determinate") ? 100 : null); var currVal_5 = ((i1.ɵnov(_v, 2).mode === "determinate") ? i1.ɵnov(_v, 2).value : null); var currVal_6 = i1.ɵnov(_v, 2).mode; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_LdapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "auth"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "r-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "strong", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/images/icons/new_logo_login.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "l-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "form-group bg-white"], ["style", "padding: 0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LdapComponent_1)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.spinner; _ck(_v, 9, 0, currVal_0); }, null); }
export function View_LdapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ldap", [], null, null, null, View_LdapComponent_0, RenderType_LdapComponent)), i1.ɵdid(1, 114688, null, 0, i7.LdapComponent, [i8.ActivatedRoute, i8.Router, i9.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LdapComponentNgFactory = i1.ɵccf("app-ldap", i7.LdapComponent, View_LdapComponent_Host_0, {}, {}, []);
export { LdapComponentNgFactory as LdapComponentNgFactory };
