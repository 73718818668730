import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {QueryParamsInterface} from '../../../interfaces/query-params.interface';
import {
  AccessAutomationExpressionInterface,
  AccessAutomationsTableInterface,
  SaveAccessAutomationDataInterface
} from '../interfaces/access-automations.interface';

@Injectable({
  providedIn: 'root'
})
export class AccessAutomationsService {
  constructor(private api: ApiService) {}

  public getAccessAutomations = (query?: QueryParamsInterface) =>
    this.api.callAutomation('GET', 'automations', null, query);
  public getAccessAutomation = (id: number) =>
    this.api.callAutomation('GET', `automations/${id}`);
  public createAccessAutomation = (
    accessAutomation: SaveAccessAutomationDataInterface
  ) => this.api.callAutomation('POST', 'automations', accessAutomation);
  public updateAccessAutomation = (
    accessAutomation: SaveAccessAutomationDataInterface,
    id: number
  ) => this.api.callAutomation('PATCH', `automations/${id}`, accessAutomation);
  public deleteAccessAutomation = (id: number) =>
    this.api.callAutomation('DELETE', `automations/${id}`);
  public enableAccessAutomation = (id: number, data) =>
    this.api.callAutomation('PUT', `automations/${id}/status`, {
      status: data
    });

  public enableDisableAccessAutomation = (id: number, data) =>
    this.api.callAutomation('PUT', `automations/${id}/status`, {
      status: data
    });
  public secondEnableAutomation = (data) =>
    this.api.callAutomation('POST', 'automations/default ', data);
  public disableAccessAutomation = (id: number) =>
    this.api.callAutomation('PUT', `automations/${id}/disable`);

  public getOperandValues = (operandId: number, companyId: number) =>
    this.api.callAutomation('GET', 'operand_values', null, {
      operandId,
      companyId
    });

  public getAllOperands = (companyId: number) =>
    this.api.callAutomation('GET', 'operands', null, {
      companyId
    });

  public getAllActions = () => this.api.callAutomation('GET', 'actions');
  public getAction = (id: number) =>
    this.api.callAutomation('GET', `actions/${id}`);

  public expressionsValueConnect = (expressionId: number, valueId: number) =>
    this.api.callAutomation(
      'POST',
      `expressions/${expressionId}/value/${valueId}/connect`
    );
  public expressionsValueDisconnect = (expressionId: number, valueId: number) =>
    this.api.callAutomation(
      'DELETE',
      `expressions/${expressionId}/value/${valueId}/disconnect`
    );

  public updateExpressions = (expressionId: number, body: any) =>
    this.api.callAutomation('PATCH', `expressions/${expressionId}`, body);

  public deleteExpression = (id: number) =>
    this.api.callAutomation('DELETE', `expressions/${id}`);
  public createExpression = (body: AccessAutomationExpressionInterface) =>
    this.api.callAutomation('POST', 'expressions', body);

  public getCommonAreaAccessDoors = ({buildingId, query}) =>
    this.api.call(
      'GET',
      `building/${buildingId}/common-area-access/doors`,
      null,
      query
    );
  public getCommonAreaAccessUals = ({buildingId, query}) =>
    this.api.call(
      'GET',
      `building/${buildingId}/common-area-access/uals`,
      null,
      query
    );
}
