<div class="auth">
  <div class="r-block">
    <div class="logo">
      <a><img src="assets/images/icons/new_logo_login.svg" alt=""/></a>
    </div>
  </div>
  <div class="l-block">
    <div class="content">

      <form [formGroup]="form">
        <div class="form-group bg-white">
          <h1>Change password</h1>
          <div class="field-box with-icon"
               [ngClass]="{
              'field-error': form.controls['oldPass'].invalid && form.controls['oldPass'].touched,
              'field-success': form.controls['oldPass'].valid && form.controls['oldPass'].touched
            }"
          >
            <p class="field-title">Old password</p>
            <input type="password" placeholder="Old password" formControlName="oldPass">
            <i class="walkIn-i top10 walkIn-i-lock clr-green"></i>
            <p *ngIf="form.controls['oldPass'].invalid && form.controls['oldPass'].touched" class="field-state">
              Enter your password!</p>
          </div>
          <div class="field-box with-icon"
               [ngClass]="{
              'field-error': form.controls['newPass'].invalid && form.controls['newPass'].touched,
              'field-success': form.controls['newPass'].valid && form.controls['newPass'].touched
            }"
          >
            <p class="field-title">New password</p>
            <input type="password" placeholder="New password" formControlName="newPass">
            <i class="walkIn-i walkIn-i-lock top10 clr-green"></i>
            <p *ngIf="form.controls['newPass'].invalid && form.controls['newPass'].touched" class="field-state">
              Password should contain at least 6 characters!</p>
          </div>
          <div class="field-box with-icon"
               [ngClass]="{
              'field-error': form.controls['confirmPass'].invalid && form.controls['confirmPass'].touched,
              'field-success': form.controls['confirmPass'].valid && form.controls['confirmPass'].touched
            }"
          >
            <p class="field-title">Confirm password</p>
            <input type="password" placeholder="Confirm password" formControlName="confirmPass">
            <i class="walkIn-i top10 walkIn-i-lock clr-green"></i>
            <p *ngIf="form.controls['confirmPass'].invalid && form.controls['confirmPass'].touched" class="field-state">
              Confirm your new password!</p>
          </div>

        </div>
        <div class="form-group bg-transparent">
          <button type="submit" class="btn btn-green" (click)="changePass()">
            <span>Change password</span>
          </button>
        </div>
      </form>
    </div>
  </div>

</div>
